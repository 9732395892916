import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { AgeConst, Height, PATH_HOME, PATH_LOGIN } from "@configs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openToast, RootState, setDataDefault, setInfo, setLoading, setIsProfile } from "@redux";
import { authApi } from "@api";
import _ from "lodash";
import { ModalMessageNotApproved, UpdateProfileComponent, UpdateProfileS2Component } from "@components";

export const PageUpdateProfile = () => {
    //page Hooks
    const { t } = useTranslation();
    const history = useHistory();
    const [step, setStep] = useState(1);
    const [listAvtUrl, setListAvtUrl] = useState<any>([]);
    const [dataCombo, setDataCombo] = useState<any>();

    const dispatch = useDispatch();
    //page state
  const [isShowModal, setIsShowModal] = useState(false);
  const gender: any = useSelector((state: RootState) => state.auth.userInfo?.sexuality);

    const updateProfileSchemaStep1 = Yup.object().shape({
        age: Yup.string()
            .nullable()
            .required(
                t(`validation.field_required2`, {
                    returnObjects: true,
                    name: t("page.sign_up_step_2.age"),
                })
            ),
        location: Yup.string()
            .nullable()
            .required(
                t(`validation.field_required2`, {
                    returnObjects: true,
                    name: t("page.sign_up_step_2.location"),
                })
            ),
        profile: Yup.string()
            .nullable()
            .required(
                t(`validation.field_required2`, {
                    returnObjects: true,
                    name: t("page.sign_up_step_2.sexuality"),
                })
            ),
        height: Yup.string()
            .nullable()
            .required(
                t(`validation.field_required2`, {
                    returnObjects: true,
                    name: t("page.sign_up_step_2.height"),
                })
            ),
        marriage: Yup.string()
            .nullable()
            .required(
                t(`validation.field_required2`, {
                    returnObjects: true,
                    name: t("page.sign_up_step_2.mariage"),
                })
            ),
        typeBody: Yup.string()
            .nullable()
            .required(
                t(`validation.field_required2`, {
                    returnObjects: true,
                    name: t("page.sign_up_step_2.appearance"),
                })
            ),
    });

    useEffect(() => {
        dispatch(setLoading(false));
        fetchData();
    }, []);

    const fetchData = async () => {
        const res = await authApi.getComboUser();
        setDataCombo(res.data.data.default_data);
        dispatch(setDataDefault(res.data.data.default_data));
    };

    const convertList = (dataObject: any) => {
        const listOptions: any = [];
        _.forOwn(dataObject, (value, key) =>
            listOptions.push({
                label: value,
                value: key,
            })
        );
        return listOptions;
    };

    const handleImageChange = (event: any) => {
        if (event.target.files) {
            const files = event.target.files;
            setListAvtUrl([...listAvtUrl, ...files]);
        }
        event.target.value = [];
    };

    const handleDeleteAvt = (item: any) => {
        const _listAvtUrl = _.filter(listAvtUrl, (element) => element !== item);
        setListAvtUrl(_listAvtUrl);
    };

    const handleSubmit1 = async (values: any) => {
        try {
            //WHAT: CALL API LOGIN
            dispatch(setLoading(true));
            const formData = new FormData();
            !!values.location && formData.append("prefecture_id", values.location);
            formData.append("sexuality", gender);
            !!values.age && formData.append("age", values.age);
            formData.append("profile_text", values.profile);
            !!values.height && formData.append("height", values.height);
            !!values.typeBody && formData.append("appearance", values.typeBody);
            !!values.marriage && formData.append("mariage", values.marriage);
            listAvtUrl.length > 0 &&
                listAvtUrl.forEach((item: any) => {
                    formData.append("avatar[]", item);
                });

            const res: any = await authApi.updateProfile(formData);

            const { success, data } = res?.data;

            if (success) {
                dispatch(setInfo(data?.profile));
                dispatch(setIsProfile(true));
                dispatch(
                    openToast({
                        //@ts-ignore
                        message: data.message,
                        type: "success",
                        autoHideDuration: 1000,
                    })
                );
                setStep(2);
            } else {
                dispatch(
                    openToast({
                        message: data.message,
                        type: "error",
                        autoHideDuration: 1000,
                    })
                );
            }
        } catch (error: any) {
            dispatch(
                openToast({
                    message: error.message,
                    type: "error",
                    autoHideDuration: 1000,
                })
            );
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleSubmit2 = async (values: any) => {
        try {
            //WHAT: CALL API LOGIN
            dispatch(setLoading(true));
            const formData = new FormData();
            !!values?.bloodgroup && formData.append("bloodgroup", values?.bloodgroup);
            !!values?.language && formData.append("language", values?.language);
            !!values?.job && formData.append("job", values?.job);
            !!values?.lookingFor && formData.append("looking_for", values?.lookingFor);
            formData.append("hobby", values?.hobby);
            !!values?.salary && formData.append("salary", values?.salary);
            !!values?.drinker && formData.append("drinker", values?.drinker);
            !!values?.smoker && formData.append("smoker", values?.smoker);
            !!values?.willSee && formData.append("hope_meet", values?.willSee);
            !!values?.firstFee && formData.append("date_cost", values?.firstFee);
            !!values?.hasChildren && formData.append("with_child", values?.hasChildren);
            !!values?.readyMarried && formData.append("willing_marriage", values?.readyMarried);

            const res: any = await authApi.updateProfileS2(formData);

            const { success, data } = res?.data;

            if (success) {
                dispatch(setInfo(data?.profile));
                dispatch(
                    openToast({
                        //@ts-ignore
                        message: data.message,
                        type: "success",
                        autoHideDuration: 1000,
                    })
                );
                if(data && data.profile.is_verify === 1){
                  history.push(PATH_HOME);
                }else {
                  setIsShowModal(true);
                }
            } else {
                dispatch(
                    openToast({
                        message: data.message,
                        type: "error",
                        autoHideDuration: 1000,
                    })
                );
            }
        } catch (error: any) {
            dispatch(
                openToast({
                    message: error?.response?.data?.message,
                    type: "error",
                    autoHideDuration: 1000,
                })
            );
        } finally {
            dispatch(setLoading(false));
        }
    };

    const initialValueStep1 = {
        location: null,
        profile: "",
        age: null,
        height: null,
        typeBody: null,
        marriage: null,
    };

    const initialValueStep2 = {
        bloodgroup: null,
        language: null,
        job: null,
        salary: null,
        lookingFor: null,
        hobby: "",
        willSee: null,
        firstFee: null,
        hasChildren: null,
        readyMarried: null,
        drinker: 0,
        smoker: 0,
    };

    const handleOnSkip = () => {
      setIsShowModal(true);
    }

    const handleConfirmModal = () => {
      setIsShowModal(false);
      history.replace(PATH_LOGIN);
    }
    return (
        <>
            {step === 1 ? (
                <UpdateProfileComponent
                    data={initialValueStep1}
                    updateProfileSchema={updateProfileSchemaStep1}
                    onSubmit={handleSubmit1}
                    address={convertList(dataCombo?.prefectures || []).reverse()}
                    listAvtUrl={listAvtUrl}
                    handleImageChange={handleImageChange}
                    marriages={convertList(dataCombo?.mariages || [])}
                    appearances={convertList(dataCombo?.appearances || [])}
                    ages={AgeConst}
                    heights={Height}
                    handleDeleteAvt={handleDeleteAvt}
                    handleOnBack={() => history.goBack()}
                />
            ) : (
                <UpdateProfileS2Component
                    data={initialValueStep2}
                    onSubmit={handleSubmit2}
                    bloodgroups={convertList(dataCombo?.bloodgroups || [])}
                    languages={convertList(dataCombo?.languages || [])}
                    jobs={convertList(dataCombo?.jobs || [])}
                    salaries={convertList(dataCombo?.salaries || [])}
                    drinkers={convertList(dataCombo?.drinker || [])}
                    smokers={convertList(dataCombo?.smoker || [])}
                    hopeMeets={convertList(dataCombo?.hope_meet || [])}
                    dateCosts={convertList(dataCombo?.date_cost || [])}
                    withChilds={convertList(dataCombo?.with_child || [])}
                    readyMarrieds={convertList(dataCombo?.willing_marriage || [])}
                    lookingFors={convertList(dataCombo?.looking_for || [])}
                    handleOnBack={() => setStep(1)}
                    //handleOnSkip={() => history.push(PATH_HOME)}
                    handleOnSkip={handleOnSkip}
                />
            )}
          {isShowModal && <ModalMessageNotApproved onConfirm={handleConfirmModal}></ModalMessageNotApproved>}
        </>
    );
};
