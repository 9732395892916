import React, { ReactNode, forwardRef } from "react";
import styled from "styled-components";

import { theme } from "@utils";

interface IContentChat {
    children: ReactNode;
}

export const ContentChat = forwardRef((props: IContentChat, ref: any) => {
    const { children } = props;
    return (
        <StyledContentChat id="scrollableDiv" ref={ref}>
            {children}
        </StyledContentChat>
    );
});

const StyledContentChat = styled.div`
    flex: 1;
    padding: 1rem;
    width: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }

    display: flex;
    flex-direction: column-reverse;

    .list_message {
        overflow-y: scroll;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }

        .loader {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    @media (min-width: ${theme.breakPoints.breakIpadPro}) {
        max-width: 66.667rem;
        margin: auto;
    }
`;
