import styled from "styled-components";
import { useParams } from "react-router-dom";
import { generatePath, useHistory } from "react-router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { authApi, ChatService, Favorite } from "@api";
import { openToast, selectApp, selectAuth, setLoading } from "@redux";
import { ComponentTopMenu, IconArrowRight, IconHeart, IconReport, IconSendChat, IconWarning } from "@components";
import { PATH_CHAT, PATH_FAVORITE, PATH_HOME, PATH_MESSAGE } from "@configs";
import { theme } from "@utils";
import { ModalFavorite } from "./ModalFavorite";
import { ModalConfirm } from "./ModalConfirm";
import { ComponentSliderImages } from "./SliderImages";

export const DetailFavorites = () => {
    //@ts-ignore
    const { id } = useParams();
    const dispatch = useDispatch();
    const information = useSelector((state: any) => state?.app?.dataDefault);
    const history = useHistory();
    const [data, setData] = useState<any>(null);
    const [active, setActive] = useState<boolean>(false);
    const [modal, setShowModal] = useState<boolean>(false);
    const [modalConfirm, setShowModalConfirm] = useState<boolean>(false);

    const callApi = async () => {
        try {
            dispatch(setLoading(true));
            const res = await authApi.getUserInfo(id);
            setData(res?.data?.data);
            setActive(res?.data?.data?.is_follow);
        } catch (error: any) {
        } finally {
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        callApi();
    }, [id]);

    const onFavorite = async () => {
        try {
            if(userInfo && userInfo.id !== Number(id)){
                await Favorite.favoriteUser({ id: id });
            setActive(!active);
            }else {
                history.push(PATH_FAVORITE);
            }
            /*await Favorite.favoriteUser({ id: id });
            setActive(!active);*/
        } catch (error: any) {}
    };

    const ViewInfo = React.memo((props: any) => {
        const { title, content } = props;
        return (
            <StyledContainerInfo>
                <div className="viewItemInfo">
                    <p style={{ margin: 0 }}>{title}</p>
                    <p style={{ margin: 0 }}>{content}</p>
                </div>
            </StyledContainerInfo>
        );
    });

    const onReport = async () => {
        try {
            const res = await Favorite.blockUser({ id: id });
            dispatch(
                openToast({
                    //@ts-ignore
                    message: res?.data?.data?.message,
                    type: "success",
                    autoHideDuration: 2000,
                })
            );
            history.replace(generatePath(PATH_HOME));
        } catch (error: any) {}
    };

    const onClickSend = async () => {
        try {
            if(userInfo && userInfo.id !== Number(id)){
                const params = {
                    user_id: id,
                };
                const res = await ChatService.createChat(params);
                history.push(`${PATH_CHAT}/${res?.data?.data?.id}/${res?.data?.data?.user_id}`);
            }else {
                history.push(PATH_MESSAGE);
            }
           /* const params = {
                user_id: id,
            };
            const res = await ChatService.createChat(params);
            history.push(`${PATH_CHAT}/${res?.data?.data?.id}/${res?.data?.data?.user_id}`);*/
        } catch (error: any) {}
    };

    const handleGoBack = () => {
        history.goBack();
    };

    //redux state
    const userInfo = useSelector(selectAuth).userInfo;
    const isMobile = useSelector(selectApp).mobile;
    return (
        <>
            {!isMobile && <ComponentTopMenu />}
            <StyledContainer>
                <div className="viewContainer">
                    {data && (
                        <div className="viewContent">
                            <div className="viewImage">
                                <ComponentSliderImages listImages={data?.avatars} />
                                <div className="viewIconTop">
                                    <div className="iconTop icon_back" onClick={handleGoBack}>
                                        <IconArrowRight />
                                    </div>
                                    {id !== userInfo?.id.toString() && (
                                        <div className="viewIconTop_left">
                                            <div className="iconTop" onClick={() => setShowModal(!modal)}>
                                                <IconWarning />
                                            </div>
                                            <div className="iconTop" onClick={() => setShowModalConfirm(!modalConfirm)}>
                                                <IconReport />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="user_info__view">
                                <div className="viewInfo">
                                    <div className="viewName">
                                        <p style={{ margin: 0, lineHeight: "1.6rem" }}>{data?.username}</p>
                                        {/*{id !== userInfo?.id.toString() && (
                                            <div className="favorite" onClick={onFavorite}>
                                                <IconHeart active={active} />
                                            </div>
                                        )}*/}
                                          <div className="favorite" onClick={onFavorite}>
                                              <IconHeart active={active} />
                                          </div>
                                    </div>
                                    <p
                                        style={{
                                            margin: 0,
                                            fontSize: "0.875rem",
                                            lineHeight: "1rem",
                                        }}
                                    >
                                        {" "}
                                        {data?.age} ・ {information?.prefectures["37"]}
                                    </p>
                                </div>
                                <div className="viewChat__wrapper">
                                   {/* {id !== userInfo?.id.toString() && (
                                        <div className="viewChat" onClick={onClickSend}>
                                            <IconSendChat />
                                        </div>
                                    )}*/}
                                      <div className="viewChat" onClick={onClickSend}>
                                          <IconSendChat />
                                      </div>
                                </div>
                                <div className="viewBottom">
                                    <pre className="txtTitle">{data?.profile_text}</pre>
                                </div>
                                {data?.sexuality !== 0 && <ViewInfo title="性別:" content={information?.sexualities[data?.sexuality]} />}
                                {data?.height && <ViewInfo title="身長:" content={`${data?.height}cm`} />}
                                {data?.appearance && <ViewInfo title="ボディタイプ:" content={information?.appearances[data?.appearance]} />}
                                {data?.mariage !== 0 && <ViewInfo title="婚姻歴:" content={information?.mariages[data?.mariage]} />}
                                {data?.bloodgroup !== 0 && <ViewInfo title="血液型:" content={information?.bloodgroups[data?.bloodgroup]} />}
                                {data?.language !== 0 && <ViewInfo title="言語:" content={information?.languages[data?.language]} />}
                                {data?.salary !== 0 && <ViewInfo title="給料:" content={information?.salaries[data?.salary]} />}
                                {data?.looking_for !== 0 && <ViewInfo title="探している:" content={information?.looking_for[data?.looking_for]} />}
                                {data?.hobby && <ViewInfo title="趣味:" content={data?.hobby} />}
                                <ViewInfo title="アルコール:" content={information?.drinker[data?.drinker]} />
                                <ViewInfo title="タバコ:" content={information?.smoker[data?.smoker]} />
                            </div>
                        </div>
                    )}
                </div>
            </StyledContainer>
            {modal && (
                <ModalFavorite
                    onClose={() => {
                        setShowModal(!modal);
                    }}
                    data={information?.reports}
                    onPressReport={async (value: any) => {
                        try {
                            const res = await Favorite.reportUser({
                                user_id_reported: id,
                                content: value,
                            });
                            dispatch(
                                openToast({
                                    //@ts-ignore
                                    message: res?.data?.data?.message,
                                    type: "success",
                                    autoHideDuration: 2000,
                                })
                            );
                            history.replace(generatePath(PATH_HOME));
                        } catch (error: any) {}
                    }}
                />
            )}
            {modalConfirm && (
                <ModalConfirm
                    onClose={() => {
                        setShowModalConfirm(!modalConfirm);
                    }}
                    onReport={() => onReport()}
                />
            )}
        </>
    );
};

const StyledContainer = styled.div`
    background-image: url("/images/splash/choose_gender_background.svg");
    height: 100%;
    .viewContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }
    .user_info__view {
        padding: 0 1.25rem;
    }
    .viewHeader {
        display: flex;
        padding: 20px;
        background: #3bb3c1;
        width: 100%;
        justify-content: center;
    }
    .viewContent {
        flex: 1;
        overflow: auto;
        display: flex;
        flex-direction: column;
        width: 100%;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }
    }
    .viewImage {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        .swiper-container {
            width: 100%;
        }
    }
    .avatar_image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
    }
    .swiper-pagination-bullet {
        background: white;
        opacity: 0.6;
        width: 0.75rem;
        height: 0.75rem;
        margin: 0.35rem;
    }
    .swiper-pagination-bullet-active {
        opacity: 1;
    }
    .viewInfo {
        margin-top: 1.688rem;
    }
    .viewChat__wrapper {
        min-height: 1.75rem;
    }
    .viewChat {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
    }
    .viewBottom {
        border-bottom: 1px solid #dddddd;
        margin-bottom: 40;
        .txtTitle {
            font-weight: 400;
            font-size: 0.813rem;
            line-height: 1.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #000000;
            max-width: 80%;
        }
    }
    .viewName {
        display: flex;
        justify-content: space-between;
        p {
            font-weight: 600;
            font-size: 1rem;
            line-height: 1rem;
        }
        .favorite {
            cursor: pointer;
        }
    }
    .viewIconTop {
        display: flex;
        width: 100%;
        position: absolute;
        margin-top: 20px;
        top: 80;
        justify-content: space-between;
        align-items: center;
        z-index: 10;
        .viewIconTop_left {
            display: flex;
        }
    }
    .iconTop {
        display: flex;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        background: rgba(0, 0, 0, 0.5);
        margin-right: 12px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &.icon_back {
            margin-left: 1rem;
        }
    }

    @media (min-width: ${theme.breakPoints.breakIpadPro}) {
        margin-top: 3.375rem;
        display: flex;
        justify-content: center;
        .viewContainer {
            height: calc(100vh - 3.375rem);
            padding: 1rem 0;
        }
        .viewInfo {
            margin-top: 0;
        }
        .viewContent {
            flex-direction: row;
            overflow: hidden;
            border-radius: 0.5rem;
            background-color: white;
            width: 100%;
            .viewImage {
                width: calc(100vh - 3.375rem);
                min-width: calc(100vh - 3.375rem);
                .swiper-container {
                    height: 100%;
                }
            }
            .user_info__view {
                width: 100%;
                min-width: 21.875rem;
                padding-top: 1.25rem;
                padding-bottom: 1.25rem;
                overflow-y: auto;
                -ms-overflow-style: none; /* Internet Explorer 10+ */
                scrollbar-width: none; /* Firefox */
                &::-webkit-scrollbar {
                    display: none; /* Safari and Chrome */
                }
            }
        }
    }
`;

const StyledContainerInfo = styled.div`
    .viewItemInfo {
        display: flex;
        justify-content: space-between;
        margin-top: 14px;
        margin-bottom: 14px;
    }
`;
