import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { PATH_LOGIN, PATH_VERIFY_PASSPORT } from "@configs";
import { ISignUp } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { openToast, register, RootState, setInfo, setIsProfile, setTokenAuth } from "@redux";
import { useState } from "react";
import { SignUpComponent } from "src/Components/SignUp";

export const PageSignUp = () => {
    //page Hooks
    const { t } = useTranslation();
    const history = useHistory();

    const dispatch = useDispatch();
    //page state
    const [toggleLoading, setToggleLoading] = useState<boolean>(false);
    const gender: any = useSelector((state: RootState) => state.auth.gender);

    // validation
    const data = {
        username: "",
        email: "",
        password: "",
        password_confirmation: "",
    };
    const registerSchema = Yup.object().shape({
        username: Yup.string()
            .required(t("validation.sign_up_field_required"))
            .max(
                255,
                t("validation.max_length", {
                    returnObjects: true,
                    name: t("page.auth.username"),
                })
            ),
        email: Yup.string()
            .required(t("validation.sign_up_field_required"))
            .email(t("validation.email_invalid"))
            .max(
                255,
                t("validation.max_length", {
                    returnObjects: true,
                    name: t("page.auth.email"),
                })
            ),
        password: Yup.string()
            .required(t("validation.sign_up_field_required"))
            .min(6, t("validation.sign_up_min_length_password"))
            .max(32, t("validation.max_length_password")),
        password_confirmation: Yup.string()
            .required(t("validation.sign_up_field_required"))
            .oneOf([Yup.ref("password"), null], t("page.auth.password_validate")),
    });

    const handleSubmit = async (values: any) => {
        try {
            //WHAT: CALL API LOGIN
            setToggleLoading(true);

            const signUpParams: ISignUp = {
                username: values.username,
                email: values.email,
                password: values.password,
                password_confirmation: values.password_confirmation,
                sexuality: gender,
            };

            const res: any = await dispatch(register(signUpParams));

            const { data, success } = res?.payload;
            if (success) {
                dispatch(setTokenAuth(data.access_token));
                dispatch(setInfo(data.profile));
                dispatch(setIsProfile(data?.isProfile));
                dispatch(
                    openToast({
                        //@ts-ignore
                        message: res.payload.data.message,
                        type: "success",
                        autoHideDuration: 2000,
                    })
                );
                history.push(PATH_VERIFY_PASSPORT);
            } else {
                if (data?.errors?.username) {
                    dispatch(
                        openToast({
                            //@ts-ignore
                            message: data?.errors?.username,
                            type: "error",
                            autoHideDuration: 2000,
                        })
                    );
                }
                if (data?.errors?.email) {
                    dispatch(
                        openToast({
                            //@ts-ignore
                            message: data?.errors?.email,
                            type: "error",
                            autoHideDuration: 2000,
                        })
                    );
                }
            }
        } catch (error: any) {
            dispatch(
                openToast({
                    message: error.message,
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
        } finally {
            setToggleLoading(false);
        }
    };

    const handleMoveLogin = () => {
        history.push(PATH_LOGIN);
    };

    const handleOnBack = () => {
        history.goBack();
    };

    return (
        <SignUpComponent
            toggleLoading={toggleLoading}
            registerSchema={registerSchema}
            innitValue={data}
            onSubmit={handleSubmit}
            onLogin={handleMoveLogin}
            handleOnBack={handleOnBack}
        />
    );
};
