import React from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import { ShareButton, ShareInput, StyledSignUp, ComponentLoading } from "@components";
import { HeaderSignUp } from ".";

interface ISignUpComponent {
    toggleLoading?: boolean;
    registerSchema?: any;
    innitValue?: any;
    onSubmit: (values: any) => void;
    onLogin: () => void;
    handleOnBack: () => void;
}

export const SignUpComponent = (props: ISignUpComponent) => {
    const { toggleLoading, registerSchema, innitValue, onSubmit, onLogin, handleOnBack } = props;

    const { t } = useTranslation();

    return (
        <>
            {toggleLoading && <ComponentLoading />}
            <StyledSignUp>
                <HeaderSignUp title="会員登録" showIcon onBack={handleOnBack} />
                <div className="form_container">
                    <div className="form">
                        <Formik initialValues={innitValue} validationSchema={registerSchema} onSubmit={(values: any) => onSubmit(values)}>
                            {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
                                return (
                                    <form onSubmit={handleSubmit} autoComplete="off">
                                        <div>
                                            <ShareInput name="username" style={{ width: "100%" }} onChange={handleChange} value={values.username} onBlur={handleBlur} label={t("page.auth.username")} />
                                            <ShareInput name="email" style={{ width: "100%" }} label={t("page.auth.email")} onChange={handleChange} value={values.email} onBlur={handleBlur} />
                                            <ShareInput
                                                name="password"
                                                style={{ width: "100%" }}
                                                type="password"
                                                label={t("page.auth.password")}
                                                onChange={handleChange}
                                                value={values.password}
                                                onBlur={handleBlur}
                                            />
                                            <ShareInput
                                                name="password_confirmation"
                                                style={{ width: "100%" }}
                                                type="password"
                                                onChange={handleChange}
                                                value={values.password_confirmation}
                                                onBlur={handleBlur}
                                                label={t("page.auth.password_confirmation")}
                                            />
                                        </div>

                                        <ShareButton className="submit" type="primary" size="large" text={t("page.auth.sign_up")} disable={isSubmitting} htmlType="submit" />
                                        <div className="text already  text-dark">{t("page.auth.already_account")}</div>

                                        <div className="text move-to-login " onClick={onLogin}>
                                            {t("page.auth.login")}
                                        </div>
                                    </form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </StyledSignUp>
        </>
    );
};
