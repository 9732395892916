export const DEFAULT_LANGUAGE = "ja";

export const NO_IMAGE_URL = "/images/no_image.jpg";
export const NOT_FOUND_IMAGE_URL = "/images/404.jpg";

export enum ActionSetting {
    LOGOUT = "logout",
    SUPPORT = "support",
}

export enum SplashStepsEnum {
    OPENING = 1,
    SERVICE_EXPLANATION,
    CHOOSE_AUTHENTICATION_ACTION,
}

export enum VerifyPassportEnum {
    FORM = "form",
    SUCCESS = "success",
    ERROR = "error",
}

export const AgeConst = [
    {
        label: "18",
        value: 18,
    },
    {
        label: "19",
        value: 19,
    },
    {
        label: "20",
        value: 20,
    },
    {
        label: "21",
        value: 21,
    },
    {
        label: "22",
        value: 22,
    },
    {
        label: "23",
        value: 23,
    },
    {
        label: "24",
        value: 24,
    },
    {
        label: "25",
        value: 25,
    },
    {
        label: "26",
        value: 26,
    },
    {
        label: "27",
        value: 27,
    },
    {
        label: "28",
        value: 28,
    },
    {
        label: "29",
        value: 29,
    },
    {
        label: "30",
        value: 30,
    },
    {
        label: "31",
        value: 31,
    },
    {
        label: "32",
        value: 32,
    },
    {
        label: "33",
        value: 33,
    },
    {
        label: "34",
        value: 34,
    },
    {
        label: "35",
        value: 35,
    },
    {
        label: "36",
        value: 36,
    },
    {
        label: "37",
        value: 37,
    },
    {
        label: "38",
        value: 38,
    },
    {
        label: "39",
        value: 39,
    },
    {
        label: "40",
        value: 40,
    },
    {
        label: "41",
        value: 41,
    },
    {
        label: "42",
        value: 42,
    },
    {
        label: "43",
        value: 43,
    },
    {
        label: "44",
        value: 44,
    },
    {
        label: "45",
        value: 45,
    },
    {
        label: "46",
        value: 46,
    },
    {
        label: "47",
        value: 47,
    },
    {
        label: "48",
        value: 48,
    },
    {
        label: "49",
        value: 49,
    },
    {
        label: "50",
        value: 50,
    },
    {
        label: "51",
        value: 51,
    },
    {
        label: "52",
        value: 52,
    },
    {
        label: "53",
        value: 53,
    },
    {
        label: "54",
        value: 54,
    },
    {
        label: "55",
        value: 55,
    },
    {
        label: "56",
        value: 56,
    },
    {
        label: "57",
        value: 57,
    },
    {
        label: "58",
        value: 58,
    },
    {
        label: "59",
        value: 59,
    },
    {
        label: "60",
        value: 60,
    },
    {
        label: "61",
        value: 61,
    },
    {
        label: "62",
        value: 62,
    },
    {
        label: "63",
        value: 63,
    },
    {
        label: "64",
        value: 64,
    },
    {
        label: "65",
        value: 65,
    },
    {
        label: "66",
        value: 66,
    },
    {
        label: "67",
        value: 67,
    },
    {
        label: "68",
        value: 68,
    },
    {
        label: "69",
        value: 69,
    },
];
export const Height = [
    {
        label: "150cm",
        value: 150,
    },
    {
        label: "151cm",
        value: 151,
    },
    {
        label: "152cm",
        value: 152,
    },
    {
        label: "153cm",
        value: 153,
    },
    {
        label: "154cm",
        value: 154,
    },
    {
        label: "155cm",
        value: 155,
    },
    {
        label: "156cm",
        value: 156,
    },
    {
        label: "157cm",
        value: 157,
    },
    {
        label: "158cm",
        value: 158,
    },
    {
        label: "159cm",
        value: 159,
    },
    {
        label: "160cm",
        value: 160,
    },
    {
        label: "161cm",
        value: 161,
    },
    {
        label: "162cm",
        value: 162,
    },
    {
        label: "163cm",
        value: 163,
    },
    {
        label: "164cm",
        value: 164,
    },
    {
        label: "165cm",
        value: 165,
    },
    {
        label: "166cm",
        value: 166,
    },
    {
        label: "167cm",
        value: 167,
    },
    {
        label: "168cm",
        value: 168,
    },
    {
        label: "169cm",
        value: 169,
    },
    {
        label: "170cm",
        value: 170,
    },
    {
        label: "171cm",
        value: 171,
    },
    {
        label: "172cm",
        value: 172,
    },
    {
        label: "173cm",
        value: 173,
    },
    {
        label: "174cm",
        value: 174,
    },
    {
        label: "175cm",
        value: 175,
    },
    {
        label: "176cm",
        value: 176,
    },
    {
        label: "177cm",
        value: 177,
    },
    {
        label: "178cm",
        value: 178,
    },
    {
        label: "179cm",
        value: 179,
    },
    {
        label: "180cm",
        value: 180,
    },
    {
        label: "181cm",
        value: 181,
    },
    {
        label: "182cm",
        value: 182,
    },
    {
        label: "183cm",
        value: 183,
    },
    {
        label: "184cm",
        value: 184,
    },
    {
        label: "185cm",
        value: 185,
    },
    {
        label: "186cm",
        value: 186,
    },
    {
        label: "187cm",
        value: 187,
    },
    {
        label: "188cm",
        value: 188,
    },
    {
        label: "189cm",
        value: 189,
    },
    {
        label: "190cm",
        value: 190,
    },
    {
        label: "191cm",
        value: 191,
    },
    {
        label: "192cm",
        value: 192,
    },
    {
        label: "193cm",
        value: 193,
    },
    {
        label: "194cm",
        value: 194,
    },
    {
        label: "195cm",
        value: 195,
    },
    {
        label: "196cm",
        value: 196,
    },
    {
        label: "197cm",
        value: 197,
    },
    {
        label: "198cm",
        value: 198,
    },
    {
        label: "199cm",
        value: 199,
    },
];
