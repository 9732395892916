import { useTranslation } from "react-i18next";

import { StyledFAQ } from ".";
import { IFaq } from "@interfaces";

interface IProps {
    listFaq: Array<IFaq>;
    renderQuestion(question: string): string;
}

export const ComponentFAQ = (props: IProps) => {
    //page hooks
    const { t } = useTranslation();

    //props
    const { listFaq, renderQuestion } = props;

    return (
        <StyledFAQ>
            <div className="title">{t("page.setting.FAQ")}</div>
            <div className="list_faq">
                {(typeof listFaq === "undefined" || listFaq?.length === 0) && <div className="empty_list">{t("page.FAQ.noResult")}</div>}
                {listFaq?.map((faq: IFaq, index: number) => (
                    <div className="faq__wrapper" key={index}>
                        <div className="question">{renderQuestion(faq.question)}</div>
                        <div className="answer">{faq.answer}。</div>
                    </div>
                ))}
            </div>
        </StyledFAQ>
    );
};
