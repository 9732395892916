import { theme } from "@utils";
import styled from "styled-components";

export const StyledChangePassWord = styled.div`
    width: 100vw;
    height: 100%;
    background: linear-gradient(360deg, #3bb3c1 66.07%, #fac9e1 110.71%);
    .form {
        height: 100%;
        margin-top: 2.8rem !important;
        padding: 2rem 1rem;
        background-image: url("/images/splash/choose_gender_background.svg");
        background-size: contain;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        max-width: 66.667rem;
        background-color: white;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 4.8rem;
        overflow-y: auto;
        .title {
            font-size: 1.2rem;
            color: #000;
            font-weight: 500;
            padding-bottom: 1.25rem;
            margin-bottom: 2.5rem;
            border-bottom: 0.2px solid ${theme.colors.text};
        }
        .submit {
            background-color: ${theme.colors.primary} !important;
            border-radius: 0.25rem;
            border: none;
        }
    }
`;
