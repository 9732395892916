import { useSelector } from "react-redux";
import { EllipsisOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import moment from "moment";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { StyledBlogSlideCard, StyledBlogCard, ComponentImage, StyledBlogCardWrapper, useStylesSubMenu } from "@components";
import { IBlogData } from "@interfaces";
import { PATH_ARTICLE_DETAIL } from "@configs";
import { selectAuth } from "@redux";

interface IProps {
    blogCard: IBlogData;
    handleDelete?(id: number): void;
}

export const ComponentBlogSlideCard = (props: IProps) => {
    //Props
    const { blogCard } = props;

    return (
        <StyledBlogSlideCard to={`${PATH_ARTICLE_DETAIL}/${blogCard.id}`}>
            <div className="card_slide_top">
                <ComponentImage src={blogCard.image} alt="Blog" className="card_top_image" />
                <div className="author_name_slide">{blogCard.user.username}</div>
            </div>
            <div className="card_slide_bottom">
                <div className="card_bottom__top">
                    <div className="category_name">{blogCard.category_name}</div>
                    <div className="create_at">{moment(blogCard.created_at).format("YYYY/MM/DD")}</div>
                </div>
                <div className="card_bottom__bottom">
                    <div className="blog_title">{blogCard.title}</div>
                    <div className="blog_summary">{blogCard.content}</div>
                </div>
            </div>
        </StyledBlogSlideCard>
    );
};

export const ComponentBlogCard = (props: IProps) => {
    //Props
    const { blogCard, handleDelete } = props;

    //Redux state
    const userInfo = useSelector(selectAuth).userInfo;

    //State
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const showSubMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onDelete = (id: number) => {
        if (handleDelete) {
            handleDelete(id);
            handleClose();
        }
    };

    //page hooks
    const { t } = useTranslation();

    const classes = useStylesSubMenu();

    return (
        <StyledBlogCardWrapper>
            {blogCard?.user?.id === userInfo?.id && (
                <div className="icon_expand__wrapper">
                    <EllipsisOutlined className="icon_expand" rotate={90} style={{ fontSize: "1rem", color: "black" }} onClick={showSubMenu} />
                    {handleDelete && (
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                        >
                            <MenuItem className={classes["MuiMenuItem-root"]} onClick={() => onDelete(blogCard.id)}>
                                {t("page.list_blog.delete")}
                            </MenuItem>
                        </Menu>
                    )}
                </div>
            )}
            <StyledBlogCard menu={(blogCard?.user?.id === userInfo?.id).toString()} to={`${PATH_ARTICLE_DETAIL}/${blogCard.id}`}>
                <div className="blog_title">{blogCard.title}</div>
                <div className="card_content__wrapper">
                    <div className="card_top">
                        <div className="author_name">{blogCard.user.username}</div>
                        <div className="create_at">{moment(blogCard.created_at).format("YYYY/MM/DD")}</div>
                    </div>
                    <div className="card_bottom">
                        <ComponentImage src={blogCard.image} alt="Blog" className="card_bottom_image" />
                        <div className="blog_summary">{blogCard.content}</div>
                    </div>
                </div>
            </StyledBlogCard>
        </StyledBlogCardWrapper>
    );
};
