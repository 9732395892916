import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ComponentImage, ShareButton, StyledPageSplash } from "@components";
import { PATH_LOGIN, PATH_FORGOT_PASSWORD, SplashStepsEnum, PATH_SELECT_GENDER } from "@configs";
import { selectSplash, goToNextStep, goToPrevStep } from "@redux";
import { useWindowDimensions } from "@utils";

export const PageSplash = () => {
    //page hooks
    const dispatch = useDispatch();
    const history = useHistory();
    const currentStep = useSelector(selectSplash).currentStep;

    const handleToLogin = () => {
      history.push(PATH_LOGIN);
    }

    const renderByStep = () => {
        switch (currentStep) {
            case SplashStepsEnum.CHOOSE_AUTHENTICATION_ACTION:
                return <ComponentChooseAuthenticationAction />;
            case SplashStepsEnum.OPENING:
                return <ComponentOpening handleToNextStep={() => dispatch(goToNextStep())} handleToLogin={handleToLogin}/>;
            case SplashStepsEnum.SERVICE_EXPLANATION:
                return (
                    <ComponentServiceExplanation
                        handleToNextStep={() => dispatch(goToNextStep())}
                        handleToPrevStep={() => dispatch(goToPrevStep())}
                    />
                );
        }
    };

    return <StyledPageSplash>{renderByStep()}</StyledPageSplash>;
};

const ComponentChooseAuthenticationAction = () => {
    //page Hooks
    const { t } = useTranslation();
    const history = useHistory();

    const handleClickSignUp = () => {
        history.push(PATH_SELECT_GENDER);
    };
    const handleClickSignIn = () => {
        history.push(PATH_LOGIN);
    };
    const handleClickForgotPassword = () => {
        history.push(PATH_FORGOT_PASSWORD);
    };
    return (
        <div className="authentication_action_step">
            <div className="logo__wrapper">
                <img src="/images/splash/logojojo.svg" alt="Logo" />
            </div>
            <div className="button_group">
                <ShareButton
                    className="btn__sign_up"
                    type="primary"
                    size="large"
                    text={t("page.splash.sign_up")}
                    htmlType="button"
                    onClick={handleClickSignUp}
                />
                <div className="button_group__sub">
                    <ShareButton
                        className="btn__sign_in"
                        type="primary"
                        size="large"
                        text={t("page.auth.sign_in")}
                        htmlType="button"
                        onClick={handleClickSignIn}
                    />
                    <div className="button_group__separator" />
                    <ShareButton
                        className="btn__forgot"
                        type="primary"
                        size="large"
                        text={t("page.auth.forgot")}
                        htmlType="button"
                        onClick={handleClickForgotPassword}
                    />
                </div>
            </div>
        </div>
    );
};

interface IOpeningProps {
    handleToNextStep(): void;
    handleToLogin(): void;
}

const ComponentOpening = (props: IOpeningProps) => {
    //page Hooks
    const { t } = useTranslation();

    //Props
    const { handleToNextStep,handleToLogin } = props;

    return (
        <div className="opening_step">
            <img
                className="opening_image"
                src="/images/splash/openingpicture_1.png"
                alt="Opening Picture"
            />
          <div className="btn__next_login" onClick={handleToLogin}>
            {t("page.auth.login")}
          </div>
            <div className="btn__next_step" onClick={handleToNextStep}>
                {t("page.splash.next_step")}
            </div>
        </div>
    );
};

interface IServiceExplanationProps {
    handleToNextStep(): void;
    handleToPrevStep(): void;
}

const ComponentServiceExplanation = (props: IServiceExplanationProps) => {
    //page Hooks
    const { width } = useWindowDimensions();

    //Props
    const { handleToNextStep, handleToPrevStep } = props;

    return (
        <div className="service_explanation_step">
            <ComponentImage
                src={`/images/service_explanation/${width < 600 ? "mobile" : "desktop"}/1.jpg`}
            />
            <ComponentImage
                src={`/images/service_explanation/${width < 600 ? "mobile" : "desktop"}/2.jpg`}
            />
            <ComponentImage
                src={`/images/service_explanation/${width < 600 ? "mobile" : "desktop"}/3.jpg`}
            />
            <ComponentImage
                src={`/images/service_explanation/${width < 600 ? "mobile" : "desktop"}/4.jpg`}
            />
            <ComponentImage
                src={`/images/service_explanation/${width < 600 ? "mobile" : "desktop"}/5.jpg`}
            />
            <ComponentImage
                src={`/images/service_explanation/${width < 600 ? "mobile" : "desktop"}/6.jpg`}
            />
            <div className="service_explanation_footer">
                <div className="btn_next" onClick={handleToNextStep}></div>
                <div className="btn_prev" onClick={handleToPrevStep}></div>
                <ComponentImage
                    src={`/images/service_explanation/${width < 600 ? "mobile" : "desktop"}/7.jpg`}
                />
            </div>
        </div>
    );
};
