export const TRANSLATIONS_EN_PAGE = {
    search: "Search name, phone...",
    tip_loading: "Loading...",
    welcome_to_Admin: "Welcome to Rocket CRM!",
    you_are_signing: "You're signing",
    instead: "instead",
    total: "total",
    theme_color: "Theme color",
    cover_image: "Cover image",
    plan_settings: "Plan settings",
    you_are_on: "You are on",
    free_plan: "Free plan",
    or: "or",
    action: {
        edit: "Edit",
        add: "Add",
        save: "Save",
        cancel: "Cancel",
        upgrade: "Upgrade",
        reset_password: "Reset password",
        use: "Use",
        close: "Close",
        upload_text: "Upload {{name}}",
        delete: "Delete",
    },
    layout: {
        home: "Home",
        LINE: "LINE",
        connections: "Connections",
        inbox: "Inbox",
        workflow: "Workflow",
        report: "Report",
        help: "Help",
        current_plan: "Current plan",
        setting: "Setting",
    },
    auth: {
        sign_in: "Sign In ",
        log_with: "Log with",
        or: "or",
        username: "Username",
        email: "Email",
        password: "Password",
        password_confirmation: "Password Confirmation",
        change_password: "Change password",
        login: "Login",
        forgot: "Forgot Your Password?",
        remember_me: "Remember Me",
        sign_up_with: "Sign up with",
        sign_up: "Sign up",
        step2: "Step 2",
        profile_editing: "Profile editing",
        add_avatar: "Add avatar",
        step3: "Step 3",
        optional_fields: "Optional fields",
        text_verify_email: "Please verify your email address",
        desc_verify_email: "Check your mailbox for a verification email. Didn't receive an email?",
        resend_email: "Resend email",
        what_is_your_name: "What is your name",
        confirm_password: "Confirm password",
        continue: "Continue",
        login_successfully: "Login Successfully",
        login_failed: "Login Failed",
        already_account: "Do you already have an account?",
        password_validate: "Passwords must match",
    },
    account: {
        address: "Address",
        profile: "profile",
        avatar: "avatar",
        gender: "gender",
        age: "age",
        height: "height",
        type: "type",
        mariage: "mariage",
        bloodgroup: "bloodgroup",
        language: "language",
        job: "job",
        salary: "salary",
        looking_for: "looking_for",
        hobby: "hobby",
        drinker: "drinker",
        smoker: "smoker",
    },
    password: {
        current_password: "Current password",
        new_password: "New password",
        confirm_new_password: "Confirm new password",
    },
    choose_plan: {
        choose_your_plan: "Choose your plan",
        crm_loyalty_hub: "CRM + Loyalty hub",
        crm_standalone: "CRM standalone",
        loyalty_standalone: "Loyalty standalone",
        year_save_20: "Yearly SAVE 20%",
        monthly: "Monthly",
    },
    merchant_account_setting: {
        merchant_account_settings: "Merchant account settings",
        basic_settings: "Basic settings",
        account_name: "Account name",
        gozzy_fruit_shop: "Gozzy fruit shop",
        free_plan: "Free plan",
        notification_settings: "Notification settings",
        channels: "Channels",
        events: "Events",
    },
    dashboard: {
        overall_stats: "Overall Stats",
    },
    contact: {
        contacts: "Contacts",
        add_contact: "Add contact",
        name: "Name",
        role: "Role",
        tel: "Tel",
        member_id: "Member Id",
        points: "Points",
        sales_amount: "Sales Amount",
        tier: "Tier",
        create_date: "Create date",
        basic_info: "Basic info",
        date_of_birth: "Date of birth",
        email: "Email",
        contact_add_date: "Contact add date",
        member_registration_date: "Member registration date",
        traffic_source: "Traffic source",
        last_activity_date: "Last activity date",
        _points: "points",
        points_to_next_tier: "Points to next tier",
        membership: "Membership",
        membership_tier: "Membership tier",
        LINE: "LINE",
        LINE_ID: "LINE ID",
        LINE_User_ID: "LINE User ID",
        LINE_chat_tags: "LINE chat tags",
        points_balance: "Points balance",
        points_to_expire_in_1_month: "Points to expire in 1 month",
        last_points_collection_date: "Last points collection date",
        last_points_redemption_date: "Last points redemption date",
        coupon_balance: "Coupon balance",
        coupon_used: "Coupon used",
        coupons_to_expire_in_1_month: "Coupons to expire in 1 month",
        last_coupon_collection_date: "Last coupon collection date",
        last_coupon_used_date: "Last coupon used date",
        coupon: "Coupon",
        sales: "Sales",
        sales_last_date: "Sales last date",
        form: "Form",
        field_tags: "Field {{name}} - tags",
    },
    preview: {
        preview: "Preview",
        points_edit: "Points edit",
        change_points_to: "Change points to",
        current_points: "Current points ",
        edit_name: "Edit name",
        edit_membership_tier: "Edit membership tier",
        customer_is_on: "Customer is on",
        change_membership_tier: "Change membership tier",
        good: "Good",
        points: "Points",
        coupons: "Coupons",
        member_details: "รายละเอียดสมาชิก &gt;",
        Expiration_month: "หมดอายุอีก 1 เดือน : 500",
        1820: "1,820",
        coupon_5: "5 คูปอง",
        use_point: "ใช้ Point",
        privilege: "สิทธิพิเศษ",
        coupon: "คูปอง",
        record: "ประวัติ",
    },
    permission: {
        manage_permissions: "Manager permissions",
        text_permission_sub_title: "Add new account members and manage their permissions here.",
        permission_types: "Permission types",
        add_member: "Add member",
        choose_permission: "Choose permission",
        edit_permissions_granted_to_this_account_member:
            "Edit permissions granted to this account member.",
        delete_this_account_member: "Delete this account member",
        grant_members_permissions_via_direct_URL_links:
            "Grant members permissions via direct URL links.",
        choose_permission_and_copy_link: "Choose permission and copy link",
    },
    loyalty: {
        membership_name: "Membership name",
        points: "Points",
        bath_spent: "Bath spent",
        benefits: "Benefits",
        loyalty_rules: "Loyalty rules",
        loyalty_settings: "Loyalty settings",
        set_point_expiry: "Set point expiry",
        automatically_assign_starting_points_to_new_members:
            "Automatically assign starting points to new members?",
        set_membership_tier: "Set membership tier",
        images: "Images",
        max_images: "(max {{name}} images)",
        image_ratio_support_only_1_1: "Image ratio support only 1: 1 (square square)",
        the_proposed_size_is_640_x_640: "The proposed size is 640 x 640",
        maximum_image_size_10_MB: "Maximum image size is 10 MB",
    },
    splash: {
        next_step: "次へ",
        choose_gender__welcome: "会えて嬉しい ♪",
        choose_gender__title: "まず、性別を教えてください",
        male: "男性",
        female: "女性",
        term_of_transaction: "取引条件",
    },
};
