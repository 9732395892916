import axiosClient from "./axiosClient";

export const blogApi = {
    getListCategoryForm() {
        const url = "forum/cateform";
        return axiosClient.get(url);
    },
    getListHighlightBlog() {
        const url = "forum/threads/acticle";
        return axiosClient.get(url);
    },
    getListBlog(page: number) {
        const url = "forum/threads";
        return axiosClient.get(url, { params: { page } });
    },
    getArticleDetail(id: string) {
        const url = `forum/thread/${id}`;
        return axiosClient.get(url);
    },
    createArticle(newArticle: FormData) {
        const url = "forum/create";
        return axiosClient.post(url, newArticle);
    },
    updateArticle(id: string, newArticle: FormData) {
        const url = `forum/thread/${id}/update`;
        return axiosClient.post(url, newArticle);
    },
    deleteArticle(id: number) {
        const url = `/forum/thread/${id}`;
        return axiosClient.delete(url);
    },
    getListBlogCategory(idCategory: string, page: number) {
        const url = `forum/category/${idCategory}`;
        return axiosClient.get(url, { params: { page } });
    },
    getListUserBlog(userId: string, page: number) {
        const url = `forum/threads/user-blogs/${userId}`;
        return axiosClient.get(url, { params: { page } });
    },
    uploadImage(image: FormData) {
        const url = "upload/image";
        return axiosClient.post(url, image);
    },
};
