import styled from "styled-components";

import { theme } from "@utils";

export const StyledFAQ = styled.div`
    width: 100%;
    height: 100%;
    font-family: ${theme.family.fontKozuka};
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    .title {
        font-weight: 500;
        font-size: 1.063rem;
        line-height: 1rem;
        color: ${theme.colors.black};
        padding: 1.688rem 1rem 1.188rem 1rem;
        border-bottom: 0.3px solid ${theme.colors.text};
    }
    .list_faq {
        text-align: center;
        padding-right: 1.563rem;
        padding-left: 1.563rem;
        padding-top: 1.25rem;
        .empty_list {
            font-weight: 500;
            font-size: 1.063rem;
            line-height: 1rem;
            color: ${theme.colors.black};
            margin-top: 1.5rem;
        }
        .faq__wrapper {
            margin-bottom: 2rem;
            .question {
                font-style: normal;
                font-weight: 500;
                font-size: 1rem;
                line-height: 1.5rem;
                color: ${theme.colors.primary};
                margin-bottom: 1rem;
            }
            .answer {
                font-weight: 400;
                font-size: 0.75rem;
                line-height: 1.5rem;
                color: ${theme.colors.black};
                background-color: #f1d3e2;
                border-radius: 0.25rem;
                padding: 0.688rem 1.125rem 1rem 1.125rem;
            }
        }
    }

    @media (min-width: ${theme.breakPoints.breakIpadPro}) {
        max-width: 66.667rem;
    }
`;
