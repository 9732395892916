import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    ComponentTopMenu,
    StyledContainer,
    IconArrowRight,
    ComponentWithdrawal,
    ComponentWithdrawalModal,
} from "@components";
import { settingApi } from "@api";
import { setLoading, openToast, selectAuth, logout } from "@redux";

export const PageWithdrawal = () => {
    //page hooks
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //State
    const [isShowModal, setIsShowModal] = useState<boolean>(false);

    const handleGoBack = () => {
        history.goBack();
    };

    const openModal = () => {
        setIsShowModal(true);
    };

    const closeModal = () => {
        setIsShowModal(false);
    };

    //redux state
    const userInfo = useSelector(selectAuth).userInfo;

    const handleDelete = async () => {
        try {
            dispatch(setLoading(true));
            await settingApi.deleteAccount(userInfo?.id);
            dispatch(logout());
        } catch (error: any) {
            dispatch(
                openToast({
                    message:
                        error?.response?.data?.message ||
                        error?.response?.message ||
                        error?.message,
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <>
            <ComponentTopMenu
                prefixIcon={<IconArrowRight />}
                onClickPrefixIcon={handleGoBack}
                title={t("page.setting.withdrawal")}
            />
            <StyledContainer hasNavbar={false}>
                <ComponentWithdrawal handleGoBack={handleGoBack} openModal={openModal} />
                {isShowModal && (
                    <ComponentWithdrawalModal
                        onClose={closeModal}
                        question={t("page.deleteAccount.questionDelete")}
                        onDelete={handleDelete}
                    />
                )}
            </StyledContainer>
        </>
    );
};
