const regex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/;
export const coverString = (string: string) => {
    const newArray: string[] = [];
    const arr = string?.split("\r\n");
    arr?.forEach((item) => {
        if (item.match(regex)) {
            newArray.push(`<a href="${item}" target='_blank' 
          style="display: block; font-size: 14px; color: #1da1f2"
        >${item}</a>`);
        } else if (!item) {
            newArray.push("</br>");
        } else {
            newArray.push(`<div style="font-size: 14px">${item}</div>`);
        }
    });
    return newArray.join(" ");
};

export const coverString2 = (string: string) => {
    const newArray: string[] = [];
    const arr = string?.split("\n");
    arr?.forEach((item) => {
        if (item.match(regex)) {
            newArray.push(`<a href="${item}" target='_blank' 
          style="display: block; font-size: 14px; color: #1da1f2"
        >${item}</a>`);
        } else if (!item) {
            newArray.push("</br>");
        } else {
            newArray.push(`<div style="font-size: 14px">${item}</div>`);
        }
    });
    return newArray.join(" ");
};

export const coverStringComment = (string: string) => {
    const newArray: string[] = [];
    const arr = string?.split(" ");
    arr?.forEach((item) => {
        if (item.match(regex)) {
            newArray.push(`<a href="${item}" target='_blank' 
        style="font-size: 14px; color: #1da1f2"
      >${item}</a>`);
        } else {
            newArray.push(`<span style="font-size: 14px">${item}</span>`);
        }
    });
    return newArray.join(" ");
};
