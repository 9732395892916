import styled from "styled-components";
import { ComponentCircularProgress } from "@components";

interface IProps {
    className?: string;
}

export const ComponentInfiniteScrollLoader = ({ className }: IProps) => {
    return (
        <StyledInfiniteLoader className={className}>
            <ComponentCircularProgress />
        </StyledInfiniteLoader>
    );
};

const StyledInfiniteLoader = styled.div`
    display: flex;
    justify-content: center;
    padding: 0.5rem 0;
`;
