import styled from "styled-components";
import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import socketio from "socket.io-client";
import Echo from "laravel-echo";
import { EllipsisOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";

import "moment/locale/ja";
import "moment/locale/en-au";

import { ComponentNavigationBar, ComponentTopMenu, ComponentImage, ComponentMessageModal, useStylesSubMenu } from "@components";
import { ChatService } from "@api";
import moment from "moment";
import { PATH_CHAT } from "@configs";
import { selectAuth, setLoading, openToast, selectApp } from "@redux";
import { theme } from "@utils";

let echo: any;

export const ListChat = () => {
    const [data, setData] = useState<any>([]);
    const [newMessage, setNewMessage] = useState<any>(null);
    const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
    const [focusTopic, setFocusTopic] = useState<number>();

    const dispatch = useDispatch();

    const token = useSelector(selectAuth).tokenInfoAuth;
    const userInfo = useSelector(selectAuth).userInfo;

    const callApi = async () => {
        try {
            dispatch(setLoading(true));

            const res = await ChatService.listChat();
            setData(res?.data?.data);
        } catch (error: any) {
        } finally {
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        if (!newMessage) return;
        const tempData = Array.from(data);
        const index = tempData?.findIndex((item: any) => item?.chat_topic_id === Number(newMessage?.chat_topic_id));
        if (index === -1) {
            setData([newMessage, ...data]);
        } else {
            tempData.splice(index, 1);
            setData([newMessage, ...tempData]);
        }
        setNewMessage(null);
    }, [newMessage]);

    useEffect(() => {
        const options = {
            broadcaster: "socket.io",
            host: process.env.REACT_APP_SOCKET_URL,
            client: socketio,
            auth: {
                headers: {
                    Authorization: token,
                },
            },
        };
        echo = new Echo(options);
        startListener();
        return () => {
            echo.private("chat.topic." + userInfo?.id).stopListening("SendPrivateChat");
        };
    }, []);

    const startListener = () => {
        echo.channel("messages." + userInfo?.id).listen("LatestMessage", (ev: any) => {
            setNewMessage(ev.message);
        });
    };

    useEffect(() => {
        callApi();
    }, []);

    const handleDeleteMessageChat = async () => {
        if (focusTopic) {
            try {
                dispatch(setLoading(true));
                const res = await ChatService.deleteMessageChat(focusTopic);
                dispatch(
                    openToast({
                        //@ts-ignore
                        message: res?.data?.data?.message,
                        type: "success",
                        autoHideDuration: 2000,
                    })
                );
                const newData = data.filter((item: any) => item?.chat_topic_id !== focusTopic);
                setData(newData);
                setShowModalDelete(false);
            } catch (error: any) {
                dispatch(
                    openToast({
                        //@ts-ignore
                        message: error?.response?.data?.message || error?.response?.message,
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    const handleOpenModalDelete = (idTopic: number) => {
        setShowModalDelete(true);
        setFocusTopic(idTopic);
    };

    const handleCloseModalDelete = () => {
        setShowModalDelete(false);
    };

    const isLoading = useSelector(selectApp).loading;

    return (
        <StyledContainer>
            <div className="viewContainer">
                <ComponentTopMenu />
                {data?.length > 0 ? (
                    <div className="viewContent">
                        {data?.map((item: any, index: any) => (
                            <ComponentChatCard key={index} item={item} handleOpenModalDelete={handleOpenModalDelete} />
                        ))}
                    </div>
                ) : isLoading ? (
                    <h4 className="empty-txt">ローディング中</h4>
                ) : (
                    <h4 className="empty-txt">リストは空です</h4>
                )}
                <ComponentNavigationBar />
            </div>
            {showModalDelete && <ComponentMessageModal onClose={handleCloseModalDelete} onDelete={handleDeleteMessageChat} />}
        </StyledContainer>
    );
};

const ComponentChatCard = (props: any) => {
    //Props
    const { item, handleOpenModalDelete } = props;

    //page hooks
    const { t } = useTranslation();
    const history = useHistory();

    const renderCurrentDate = (updateAt: any): string => {
        moment.locale("ja");
        return moment(updateAt).format("MMM Do");
    };

    const renderCurrentSession = (updateAt: any): string => {
        moment.locale("en-au");
        return moment(updateAt).format("h:mm A");
    };

    const handleClickItem = (item: any) => {
        history.push(`${PATH_CHAT}/${item?.chat_topic_id}/${item?.friend?.id}`);
    };

    //State
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const showSubMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onOpenModalDelete = (chat_topic_id: any) => {
        handleOpenModalDelete(chat_topic_id);
        handleClose();
    };

    const classes = useStylesSubMenu();

    return (
        <div className="item__wrapper">
            <div className="icon_expand__wrapper">
                <EllipsisOutlined className="icon_expand" rotate={90} style={{ fontSize: "1rem", color: "black" }} onClick={showSubMenu} />
                {
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >
                        <MenuItem className={classes["MuiMenuItem-root"]} onClick={() => onOpenModalDelete(item?.chat_topic_id)}>
                            {t("page.message.delete")}
                        </MenuItem>
                    </Menu>
                }
            </div>
            <div className="viewitem" onClick={() => handleClickItem(item)}>
                <ComponentImage className="imageItem" src={item?.friend?.avatar} alt="Avatar" />
                <div className="itemContent">
                    <h3 style={{ margin: 0 }}>{item?.friend?.username}</h3>
                    <div className="txtBottom">
                        <p>{item?.message}</p>
                        <div className="time">
                            <p className="txtRight">{renderCurrentSession(item?.updated_at)}</p>
                            <p className="txtRight">{renderCurrentDate(item?.updated_at)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const StyledContainer = styled.div`
    font-family: ${theme.family.fontKozuka};
    width: 100%;
    height: 100%;
    background-image: url("/images/splash/choose_gender_background.svg");
    .viewHeader {
        display: flex;
        padding: 20px;
        background: #3bb3c1;
        width: 100%;
        justify-content: center;
    }
    .viewContainer {
        display: flex;
        height: 100%;
        flex-direction: column;
        align-items: center;
        margin: auto;
    }
    .viewButtonHeader {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 80px;
    }
    .viewButton {
        display: flex;
        flex-direction: row;
        width: 90%;
        justify-content: space-between;
    }
    .viewContent {
        margin-top: 3.375rem;
        flex: 1;
        overflow: scroll;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0.813rem;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }
        // margin-bottom: 5rem;
    }
    .item__wrapper {
        position: relative;
        .icon_expand__wrapper {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            align-items: flex-end;
            flex-direction: column;
        }
        .icon_expand {
            line-height: 1.5rem;
            padding-left: 0.5rem;
        }
    }
    .viewitem {
        margin-bottom: 0.813rem;
        display: flex;
        align-items: center;
    }
    .imageItem {
        width: 4.5rem;
        height: 4.5rem;
        border-radius: 0.25rem;
    }
    .itemContent {
        width: calc(100% - 5.5rem);
        display: flex;
        margin-left: 16px;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
        border-bottom: 0.3px solid #787878;
        h3 {
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.5rem;
            color: #000000;
        }
    }
    .txtBottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > p {
            word-break: break-word;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        p {
            margin: 0;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 1.5rem;
            color: #787878;
        }

        .time {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            flex-direction: column;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 1.5rem;
            color: #747474;
            min-width: 3.125rem;
        }
    }
    .txtRight {
        margin-top: 11px;
    }
    .submit {
        background: #f0d4e2;
    }
    .activeButton {
        background: #cb92ae;
    }
    .empty-txt {
        margin-top: 100px;
    }

    @media (min-width: ${theme.breakPoints.breakIpadPro}) {
        .viewContainer {
            max-width: 66.667rem;
        }
    }
`;
