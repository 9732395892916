import { theme } from "@utils";
import styled from "styled-components";

export const StyledSignUp = styled.div`
    background: linear-gradient(360deg, #3bb3c1 66.07%, #fac9e1 110.71%);
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .form_container {
        background-color: white;
        width: 100%;
        height: 100%;
        padding: 0;
        overflow-y: auto;
        margin-top: 9rem;
        &::-webkit-scrollbar {
            width: 0.25rem;
        }
        &::-webkit-scrollbar-track {
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
        }
        @media (min-width: ${theme.breakPoints.breakIpadPro}) {
            width: 600px;
        }
    }
    .form {
        padding: 0 1.6rem;
        padding-top: 1.5rem;
        flex: 1;
        .submit {
            background-color: ${theme.colors.primary} !important;
        }
    }
    .signup-header {
        position: relative;
        display: flex;
        justify-content: center;
        max-height: 130px;
        margin-top: 20px;
        .back {
            position: absolute;
            cursor: pointer;
            left: 0;
        }
        .done {
            position: absolute;
            cursor: pointer;
            right: 0;
        }
    }
    .text {
        text-align: center;
        white-space: nowrap;
        font-weight: 800;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #3bb3c1;
        font-size: 1rem;
        @media (max-width: ${theme.breakPoints.breakTablet}) {
            margin: 25px;
            font-size: 18px;
        }
        @media (max-width: ${theme.breakPoints.breakMobilePhone}) {
            font-size: 16px;
        }
        @media (max-width: ${theme.breakPoints.breakMobile}) {
            margin: 12px 0;
        }
    }

    .ant-input {
        height: 45px !important;
        font-size: 20px;
        font-weight: normal !important;
        color: #4f5467 !important;
        background-color: #fff;
        @media (max-width: ${theme.breakPoints.breakTablet}) {
            font-size: 18px;
            height: 45px;
            padding: 10px;
        }
        @media (max-width: ${theme.breakPoints.breakMobilePhone}) {
            font-size: 16px;
        }
    }

    .submit {
        margin-top: 1rem;
        color: #fff;
        background-color: #2962ff;
        border-color: #2962ff;
        margin-bottom: 20px;
        :hover,
        :focus {
            color: #fff !important;
            background-color: #0346ff;
            border-color: #0041f5;
        }
    }
    .already {
        margin-bottom: 0px;
    }
    .move-to-login {
        color: #3bb3c1;
        cursor: pointer;
        margin-bottom: 1rem;
    }
`;

export const StyledVerifyPassport = styled.div`
    background-image: url("/images/splash/choose_gender_background.svg");
    width: 100%;
    height: 100%;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
    .form.verify_passport {
        width: 100%;
        max-width: 900px;
        background: linear-gradient(180deg, #cde7ff 0%, rgba(255, 255, 255, 0) 100%);
        padding-top: 1.75rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        .title {
            font-weight: 700;
            font-size: 1rem;
            line-height: 1.188rem;
            color: #000000;
            text-align: center;
            margin-bottom: 2.125rem;
        }
        .sub_title {
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.188rem;
            color: #000000;
            text-align: center;
            margin: auto;
            margin-bottom: 2.125rem;
        }
        .list_passport_type {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-row-gap: 32px;
            grid-column-gap: 4px;
            .passport_type {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: end;
                font-weight: 500;
                font-size: 1rem;
                line-height: 1.188rem;
                color: #000000;
                img {
                    width: 100%;
                }
                &:last-child {
                    grid-column-start: 1;
                    grid-column-end: 3;
                    img {
                        width: 50%;
                    }
                }
            }
        }
        .verify_rule {
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.188rem;
            text-align: center;
            margin-bottom: 3rem;
            margin-top: 2.25rem;
            padding: 0 1rem;
        }
        .verify_privacy {
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1rem;
            text-align: left;
            color: #676767;
            margin-bottom: 4.375rem;
            padding: 0 1rem;
        }
        .message_group {
            margin-top: calc(calc(var(--window-height) - 19.813rem) / 2);
            margin-bottom: calc(calc(var(--window-height) - 19.813rem) / 2);
            display: flex;
            flex-direction: column;
            align-items: center;
            .message {
                font-size: 1rem;
                line-height: 1.188rem;
                margin-top: 0.875rem;
                font-weight: 500;
            }
        }
    }
    .button_wrapper {
        padding: 0 0.5rem;
        padding-bottom: 0.625rem;
        width: 100%;
        max-width: 900px;
        button {
            color: white;
            font-weight: 700;
            background-color: #c994bd !important;
            border-radius: 0.625rem;
        }
    }

    .backdrop_loading {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
        background-color: rgba(0, 0, 0, 0.65);
        padding: 0 1.125rem;
        .modal_loading {
            width: 100%;
            max-width: 900px;
            padding: 3rem 0;
            background-color: white;
            border-radius: 0.625rem;
            font-size: 1rem;
            line-height: 1.188rem;
            font-weight: 500;
            display: flex;
            flex-direction: column;
            align-items: center;
            .title {
                font-weight: 700;
                margin-bottom: 1.25rem;
            }
        }
    }
    @media (min-width: ${theme.breakPoints.breakMobilePhone}) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;
