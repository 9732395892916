import { Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getToken, getMessaging, deleteToken } from "firebase/messaging";
import { useEffect } from "react";
import socketio from "socket.io-client";
import Echo from "laravel-echo";

import { ComponentAppRoute, ComponentToast, ComponentLoading, ComponentNotification } from "@components";
import { routes, firebaseApp, PATH_CHAT, PATH_DETAIL_USER } from "@configs";
import { IRoute } from "@interfaces";
import { useWindowDimensions } from "@utils";
import { setMobile, selectApp, setTokenDevice, selectAuth, openNotification } from "@redux";
import { settingApi } from "@api";

// const socketId = undefined;
let echo: any;

export const ModuleMain = () => {
    //page hooks
    const { width, height } = useWindowDimensions();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setMobile(width < 1024));
        const doc = document.documentElement;
        doc.style.setProperty("--window-height", `${height}px`);
        doc.style.setProperty("--window-width", `${width}px`);
    }, [width, height]);

    //redux state
    const tokenDevice: string = useSelector(selectAuth).tokenDevice;
    const authToken = useSelector(selectAuth).tokenInfoAuth;
    const isProfile = useSelector(selectAuth).isProfile;
    const userInfo = useSelector(selectAuth).userInfo;
    const isReceiveNotification: boolean | undefined = userInfo?.push_notification;
    const isLoading = useSelector(selectApp).loading;
    const token = useSelector(selectAuth).tokenInfoAuth;

    useEffect(() => {
        if (navigator.serviceWorker && authToken && isProfile) {
            const messaging = getMessaging(firebaseApp);
            if (!tokenDevice && isReceiveNotification) {
                getToken(messaging, {
                    vapidKey: process.env.REACT_APP_VAPID_KEY,
                })
                    .then((currentToken) => {
                        if (currentToken) {
                            // Save the token to redux
                            dispatch(setTokenDevice(currentToken));
                            //Save the token to database
                            updateDeviceToken(currentToken);
                        } else {
                            // Show permission request UI
                            // ...
                        }
                    })
                    .catch(() => {
                        // ...
                    });
            } else {
                if (!isReceiveNotification) {
                    deleteToken(messaging).then(() => {
                        updateDeviceToken("");
                        dispatch(setTokenDevice(""));
                    });
                }
            }
        }
    }, [isReceiveNotification, tokenDevice, authToken, isProfile]);

    useEffect(() => {
        if (isReceiveNotification) {
            const options = {
                broadcaster: "socket.io",
                host: process.env.REACT_APP_SOCKET_URL,
                client: socketio,
                auth: {
                    headers: {
                        Authorization: token,
                    },
                },
            };
            echo = new Echo(options);
            //@ts-ignore
            listenNotification(userInfo?.id);
        }
        return () => {
            if (echo?.channel) {
                echo.channel("jojo.notifications." + userInfo?.id).stopListening("NotificationChat");
            }
        };
    }, [isReceiveNotification]);

    const updateDeviceToken = async (token: string) => {
        try {
            await settingApi.updateDeviceToken(token);
        } catch (error: any) {
            //...
        }
    };

    const listenNotification = (authId: string | number) => {
        echo.channel("jojo.notifications." + authId).listen("NotificationChat", (ev: any) => {
            const { data } = ev;
            let link = "";
            if (data?.screen === "ChatPage") {
                link = `${PATH_CHAT}/${data?.topic_id}/${data?.sender_id}`;
            } else {
                link = `${PATH_DETAIL_USER}/${data?.sender_id}`;
            }
            dispatch(
                openNotification({
                    message: data?.title,
                    autoHideDuration: 3000,
                    link,
                })
            );
        });
    };

    return (
        <BrowserRouter>
            {isLoading && <ComponentLoading />}
            <Switch>
                {routes.map((e: IRoute, key) => (
                    <ComponentAppRoute key={key} {...e} />
                ))}
            </Switch>
            <ComponentToast />
            <ComponentNotification />
        </BrowserRouter>
    );
};
