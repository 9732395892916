import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import { ComponentTopMenu, StyledContainer, IconArrowRight, ComponentFAQ } from "@components";
import { settingApi } from "@api";
import { openToast, setLoading } from "@redux";
import { IFaq } from "@interfaces";

export const PageFAQ = () => {
    //page hooks
    const history = useHistory();
    const dispatch = useDispatch();

    //State
    const [listFaq, setListFaq] = useState<Array<IFaq>>([]);

    //What call api faqs
    useEffect(() => {
        fetchListFaq();
    }, []);

    const fetchListFaq = async () => {
        try {
            dispatch(setLoading(true));
            const res = await settingApi.getListFaq();
            setListFaq(res?.data?.data);
        } catch (error: any) {
            dispatch(
                openToast({
                    message: error?.response?.data?.message || error?.response?.message || error?.message,
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
        } finally {
            dispatch(setLoading(false));
        }
    };

    //format response question
    const renderQuestion = (question: string): string => {
        return "Q. " + question + "？";
    };

    const handleGoBack = () => {
        history.goBack();
    };

    return (
        <>
            <ComponentTopMenu prefixIcon={<IconArrowRight />} onClickPrefixIcon={handleGoBack} />
            <StyledContainer hasNavbar={false}>
                <ComponentFAQ listFaq={listFaq} renderQuestion={renderQuestion} />
            </StyledContainer>
        </>
    );
};
