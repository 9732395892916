import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { selectAuth } from "@redux";
import { authApi } from "@api";
import { StyledPricing, ComponentImage, StyledMalePayment, StyledFemaleView, ComponentModalPaymentMethod } from "@components";
import { PATH_SIGN_UP } from "@configs";

export const ComponentPricing = () => {
    const gender = useSelector(selectAuth)?.gender;

    return <StyledPricing>{gender === 3 ? <ComponentMalePayment /> : <ComponentFemaleView />}</StyledPricing>;
};

const ComponentMalePayment = () => {
    //State
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

    const handleOpenPaymentMethodModal = () => {
        setIsOpenModal(true);
    };

    const handleClosePaymentMethodModal = () => {
        setIsOpenModal(false);
    };

    const payment = async (method: number) => {
        try {
            const res = await authApi.payment(method);
            const { url } = res.data.data;
            location.href = url;
        } catch (error: any) {}
    };

    return (
        <StyledMalePayment>
            <ComponentImage src="/images/payment/header.png" className="header" />
            <ComponentImage src="/images/payment/body.png" className="body" />
            <ComponentImage src="/images/payment/bottom.png" className="bottom" />
            <div className="payment" onClick={handleOpenPaymentMethodModal}>
                <ComponentImage src="/images/payment/button.png" className="button" />
            </div>
            {isOpenModal && <ComponentModalPaymentMethod payment={payment} onCloseModal={handleClosePaymentMethodModal} />}
        </StyledMalePayment>
    );
};

const ComponentFemaleView = () => {
    //page Hooks
    const history = useHistory();

    const goToSignUpPage = () => {
        return history.push(PATH_SIGN_UP);
    };
    return (
        <StyledFemaleView>
            <div className="image_wrapper">
                <ComponentImage src="/images/female_view/header.jpg" className="female__background" />
                <ComponentImage src="/images/female_view/top.jpg" className="female__background" />
                <ComponentImage src="/images/female_view/body.jpg" className="female__background" />
                <ComponentImage src="/images/female_view/bottom.jpg" className="female__background" />
                <p className="button" onClick={goToSignUpPage}>
                    次へ
                </p>
            </div>
        </StyledFemaleView>
    );
};
