import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { INotification } from "@interfaces";
import { RootState } from ".";

interface IInitialState {
    notification: INotification;
}

const initialState: IInitialState = {
    notification: {
        autoHideDuration: 5000,
        vertical: "top",
        horizontal: "right",
        open: false,
        message: "",
        link: "",
    },
};

const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        openNotification: (state, action: PayloadAction<INotification>) => {
            const { vertical, horizontal, open, autoHideDuration, message, link } = action.payload;
            const newState: INotification = {
                vertical: vertical ? vertical : "top",
                horizontal: horizontal ? horizontal : "right",
                open: open ? open : true,
                autoHideDuration,
                message,
                link,
            };
            state.notification = newState;
        },
        closeNotification: (state) => {
            state.notification.open = false;
        },
    },
});

export const selectNotification = (state: RootState) => state.notification;

export const { openNotification, closeNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
