import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";

import { IInfo } from "@interfaces";
import { StyledListUserBlock, ShareButton, ComponentImage, ComponentInfiniteScrollLoader } from "@components";
import { selectApp } from "@redux";

interface IProps {
    listBlock: Array<IInfo>;
    handleUnBlock(userId: number): void;
    handleNextPage(): void;
    hasMore: boolean;
    listUnBlock: Array<number>;
}

export const ComponentListUserBlock = (props: IProps) => {
    //props
    const { listBlock, handleUnBlock, handleNextPage, hasMore, listUnBlock } = props;

    //page hooks
    const { t } = useTranslation();

    const isLoading = useSelector(selectApp).loading;

    return (
        <StyledListUserBlock id="scroll_view">
            <div className="title">{t("page.setting.listBlock")}</div>
            {listBlock?.length > 0 ? (
                <InfiniteScroll
                    dataLength={listBlock?.length}
                    hasMore={hasMore}
                    loader={<ComponentInfiniteScrollLoader className="loader" />}
                    next={handleNextPage}
                    className="list_block"
                    scrollableTarget="scroll_view"
                >
                    {listBlock?.map((user: IInfo) => (
                        <div className="user__wrapper" key={user?.id}>
                            <ComponentImage className="user_avatar" src={user.avatar ? user.avatar.toString() : null} />
                            <div className="user_block_right">
                                <div className="username">{user?.username}</div>
                                {!listUnBlock.includes(user.id) && (
                                    <ShareButton
                                        className="button_un_block"
                                        type="primary"
                                        size="large"
                                        text={t("page.userDetail.unblock")}
                                        htmlType="button"
                                        onClick={() => handleUnBlock(user?.id)}
                                    />
                                )}
                            </div>
                        </div>
                    ))}
                </InfiniteScroll>
            ) : isLoading ? (
                <div className="empty_list">ローディング中</div>
            ) : (
                <div className="empty_list">{t("page.message.emptyList")}</div>
            )}
        </StyledListUserBlock>
    );
};
