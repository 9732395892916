import styled from "styled-components";

export const StyledError = styled.div`
    text-align: left;
    color: ${(props) => props.theme.colors.red};
    font-size: 1rem;
    font-weight: 500;
    line-height: 20px;
    margin-top: 0.3rem;
`;
