import { theme } from "@utils";
import styled from "styled-components";

export const StyledUpdateProfile = styled.div`
    background: linear-gradient(360deg, #3bb3c1 66.07%, #fac9e1 110.71%);
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .form_container {
        background-color: white;
        width: 100%;
        height: 100%;
        padding: 0;
        overflow-y: auto;
        margin-top: 9rem;
        &::-webkit-scrollbar {
            width: 0.25rem;
        }
        &::-webkit-scrollbar-track {
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakIpadPro}) {
            width: 600px;
        }
    }
    .form {
        padding: 0 1.6rem;
        flex: 1;
        margin: 2.5rem 0;
        .submit {
            margin-top: 1rem;
            background-color: ${theme.colors.primary} !important;
        }
    }
    .avatar-label {
        font-size: 1.2rem;
        color: #3bb3c1;
    }
    .avatar-container {
        display: flex;
        width: 100%;
        overflow: auto;
        padding-bottom: 10px;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        .img-container {
            position: relative;
            width: 100px;
            height: 100px;
            margin: 5px;
            overflow: hidden;
            border-radius: 5px;

            .delete {
                position: absolute;
                right: 5px;
                bottom: 5px;
                cursor: pointer;
            }
            .avatar-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .file-upload {
        border: 2px solid $tertiary;
        width: 100%;
        border-radius: 10px;
        margin-top: 3px;
        color: $tertiary;
        font-family: "BarlowBold";
        cursor: pointer;

        input[type="file"] {
            display: none !important;
        }
        .add-avatar {
            background: #fac9e1;
            padding: 10px;
            text-align: center;
            font-size: 1.2rem;
            border-radius: 4px;
            font-weight: bold;
        }
    }
    .bottom-group {
        display: flex;
        justify-content: space-between;
        margin-top: 1.2rem;
    }
`;
