import { theme } from "@utils";
import styled from "styled-components";

export const StyledLogin = styled.div`
    background: linear-gradient(360deg, #3bb3c1 66.07%, #fac9e1 110.71%);
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .form_container {
        background-color: white;
        width: 100%;
        height: 100%;
        padding: 0;
        position: relative;
        margin-top: 9rem;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 1.625rem;
        &::-webkit-scrollbar {
            width: 0.25rem;
        }
        &::-webkit-scrollbar-track {
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
        }
        .form {
            margin-top: 3rem;
            padding: 0 1.6rem;
            flex: 1;
            .submit {
                background-color: ${theme.colors.primary} !important;
            }
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakIpadPro}) {
            width: 600px;
        }
        @media (max-width: ${theme.breakPoints.breakIpadPro}) and (min-width: ${theme.breakPoints.breakMobilePhone}) {
            justify-content: flex-start;
            .form {
                flex: 0;
            }
        }
    }

    .login-header {
        display: flex;
        justify-content: center;
        height: 130px;
    }
    .login-title {
        margin-bottom: 30px;
        text-align: center;
        white-space: nowrap;
        font-weight: 800;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #3e5569;
        font-size: 18px;
        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            margin: 25px;
            font-size: 18px;
        }
        @media (max-width: ${(p) => p.theme.breakPoints.breakMobilePhone}) {
            font-size: 16px;
        }
        @media (max-width: ${(p) => p.theme.breakPoints.breakMobile}) {
            margin: 12px 0;
        }
    }
    .ant-input-prefix {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 100%;
        background-color: #f8f9fa;
        border: 1px solid #e9ecef;
        left: 0;
    }
    .ant-input {
        height: 45px !important;
        /* padding-left: 45px !important; */
        font-size: 20px;
        font-weight: normal !important;
        color: #4f5467 !important;
        background-color: #fff;
        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            font-size: 18px;
            height: 45px;
            padding: 10px;
        }
        @media (max-width: ${(p) => p.theme.breakPoints.breakMobilePhone}) {
            font-size: 16px;
        }
    }
    .forgot {
        font-size: 1rem;
        color: ${theme.colors.pink};
        cursor: pointer;
        justify-content: flex-end;
        display: flex;
        margin: 0.4rem 0;
        :hover {
            opacity: 0.9;
        }
        @media (min-width: ${theme.breakPoints.breakMobilePhone}) and (max-width: ${theme.breakPoints.breakIpadPro}) {
            font-size: 1.2rem;
        }
    }
    .group-text {
        .text {
            text-align: center;
            white-space: nowrap;
            font-weight: 800;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #3bb3c1;
            font-size: 1rem;
        }
        .move-to-login {
            margin-top: 0.6rem;
            cursor: pointer;
        }
        @media (min-width: ${theme.breakPoints.breakMobilePhone}) and (max-width: ${theme.breakPoints.breakIpadPro}) {
            margin-top: 1rem;
        }
    }
    .submit {
        margin-top: 1rem;
        color: #fff;
        background-color: #2962ff;
        border-color: #2962ff;
        margin-bottom: 40px;
        :hover {
            color: #fff !important;
            background-color: #0346ff;
            border-color: #0041f5;
        }
    }

    .remember {
        input {
            background-color: #dee2e6;
            width: 16px !important;
            height: 16px !important;
        }
    }
    .create {
        font-weight: bold;
        font-size: 20px;
        line-height: 27px;
        color: #000;
        cursor: pointer;
        text-align: center;
        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            font-size: 18px;
        }
        @media (max-width: ${(p) => p.theme.breakPoints.breakMobilePhone}) {
            font-size: 16px;
        }
    }
`;
