import { NO_IMAGE_URL } from "@configs";

interface IProps {
    src: string | null;
    alt?: string;
    className?: string;
}

export const ComponentImage = (props: IProps) => {
    //Props
    const { src, alt, className } = props;

    return <img className={className} src={src || NO_IMAGE_URL} alt={alt} />;
};
