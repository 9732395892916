import React, { ReactNode } from "react";
import styled from "styled-components";

interface IChatComponent {
    children: ReactNode;
}

export const ChatComponent = (props: IChatComponent) => {
    const { children } = props;
    return <StyledChatComponent>{children}</StyledChatComponent>;
};

const StyledChatComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-image: url("/images/splash/choose_gender_background.svg");
    width: 100%;
`;
