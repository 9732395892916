import { Url } from "url";

import { StyledUserPreviewCard, ComponentImage } from "@components";
import { PATH_DETAIL_USER } from "@configs";

interface IProps {
    userAvatarUrl: Url | null;
    userName: string;
    prefecture: string | number;
    age: number;
    userId: number;
}

export const ComponentUserPreviewCard = (props: IProps) => {
    //Props
    const { userAvatarUrl, userName, prefecture, userId, age } = props;

    return (
        <StyledUserPreviewCard to={`${PATH_DETAIL_USER}/${userId}`}>
            <ComponentImage
                className="user_avatar"
                src={userAvatarUrl && userAvatarUrl.toString()}
                alt="Avatar"
            />
            <div className="user_info">
                <p className="user_name">{userName}</p>
                <p className="user_age">{age}</p>
                <p className="prefecture">{prefecture}</p>
            </div>
        </StyledUserPreviewCard>
    );
};
