import styled from "styled-components";

import { theme } from "@utils";

export const StyledPageSplash = styled.div`
  background: linear-gradient(360deg, #3bb3c1 66.07%, #fac9e1 110.71%);
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .authentication_action_step {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .logo__wrapper {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button_group {
    margin-bottom: 6.563rem;
    width: 100%;
    max-width: 66.667rem;
    padding: 0 1rem;
    font-family: ${theme.family.fontKozuka};

    .btn__sign_up {
      background-color: ${theme.colors.secondary};
      color: ${theme.colors.black};
      font-weight: 500;
      font-size: 1.25rem;
      padding: 0.438rem 0;
      line-height: 2.5rem;
    }

    .button_group__sub {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1.25rem;
    }

    .button_group__separator {
      height: 1.375rem;
      width: 1px;
      background-color: ${theme.colors.white};
      margin: 0 0.875rem;
    }

    .btn__sign_in,
    .btn__forgot {
      background: transparent;
      border: none;
      font-weight: 500;
      font-size: 1rem;
      line-height: 2rem;
      color: ${theme.colors.white};
    }
  }

  .opening_step {
    position: relative;
    width: auto;
    max-width: 66.667rem;
    height: 100%;

    .opening_image {
      width: auto;
      height: 100%;
      display: block;
    }

    .btn__next_step {
      position: absolute;
      bottom: 0;
      right: 0;
      font-family: ${theme.family.fontKozuka};
      font-weight: 400;
      line-height: 3rem;
      color: ${theme.colors.blue};
      margin-right: 1.813rem;
      margin-bottom: 1.813rem;
      cursor: pointer;
    }

    .btn__next_login {
      position: absolute;
      bottom: 0;
      left: 0;
      font-family: ${theme.family.fontKozuka};
      font-weight: 400;
      line-height: 3rem;
      color: ${theme.colors.blue};
      margin-left: 1.813rem;
      margin-bottom: 1.813rem;
      font-size: 24px;
      cursor: pointer;
    }

    @media (max-width: ${theme.breakPoints.breakMobilePhone}) {
      width: 100vw;
      .opening_image {
        width: 100%;
        object-fit: cover;
      }

      .btn__next_step {
        font-size: 1.5rem;
      }
    }
    @media (max-width: ${theme.breakPoints.breakIpadPro}) and (min-width: ${theme.breakPoints.breakMobilePhone}) {
      .btn__next_step {
        font-size: 2.25rem;
      }
    }
  }

  .choose_gender__step {
    background-color: ${theme.colors.grey};
    background-image: url("/images/splash/choose_gender_background.svg");
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0.5rem;
    @media (max-width: ${theme.breakPoints.breakIpadPro}) {
      width: 100%;
      height: 100%;
      justify-content: center;
      border-radius: 0;
    }
    @media (max-width: ${theme.breakPoints.breakIpadPro}) and (min-width: ${theme.breakPoints.breakMobileMedium}) {
      padding: 0 6rem;
    }

    .choose_gender__text {
      font-weight: 400;
      font-family: ${theme.family.fontKozuka};
      font-size: 1.063rem;
      line-height: 1.5rem;
      color: ${theme.colors.fadedText};
      margin-bottom: 0;
      @media (max-width: ${theme.breakPoints.breakIpadPro}) and (min-width: ${theme.breakPoints.breakMobileMedium}) {
        font-size: 2rem;
        line-height: 2.5rem;
      }
    }

    .gender_group {
      margin-top: 2.563rem;
      margin-bottom: 1.938rem;
      display: flex;
      align-items: center;
    }

    .gender_group__item {
      background-color: ${theme.colors.white};
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 9.063rem;
      height: 9.063rem;
      font-weight: 400;
      font-size: 0.875rem;
      font-family: ${theme.family.fontKozuka};
      line-height: 1.5rem;
      color: ${theme.colors.black};
      margin-right: 0.25rem;
      box-shadow: 0px 2px 2px ${theme.colors.grey};
      cursor: pointer;

      .icon__wrapper {
        margin-bottom: 0.813rem;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        opacity: 0.8;
      }

      @media (max-width: ${theme.breakPoints.breakIpadPro}) and (min-width: ${theme.breakPoints.breakMobileMedium}) {
        width: 16rem;
        height: 16rem;
        font-size: 1.2rem;
        margin-right: 0.75rem;
      }
    }

    .gender_group__item.active {
      background-color: ${theme.colors.secondary};
    }

    .btn__next_step {
      border: none;
      font-family: ${theme.family.fontKozuka};
      background-color: ${theme.colors.darkGrey};
      font-weight: 400;
      font-size: 0.938rem;
      line-height: 1.875rem;
      margin-top: 1.25rem;
    }

    .btn__next_step.active {
      background-color: ${theme.colors.primary};
      color: ${theme.colors.black};
    }

    .button__wrapper {
      width: 100%;
    }
  }

  .service_explanation_step {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-image: url("/images/service_explanation/other/bg.jpg");
    background-size: contain;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    img {
      width: 100%;
    }

    .service_explanation_footer {
      width: 100%;
      background-color: #3bb3c1;
      padding-top: 50px;
      display: flex;
      align-items: center;
      flex-direction: column;

      .btn_next {
        background-image: url("/images/service_explanation/other/next_btn_off.jpg");
        width: 218px;
        height: 41px;
        background-repeat: no-repeat;
        background-position: center center;
        cursor: pointer;

        &:hover {
          background-image: url("/images/service_explanation/other/next_btn_on.jpg");
        }
      }

      .btn_prev {
        background-image: url("/images/service_explanation/other/prev_btn.jpg");
        width: 218px;
        height: 41px;
        background-repeat: no-repeat;
        background-position: center center;
        margin-top: 20px;
        cursor: pointer;
      }
    }

    @media (min-width: ${theme.breakPoints.breakMobilePhone}) {
      display: flex;
      align-items: center;
      flex-direction: column;
      background-color: white;
      img {
        width: 100%;
        max-width: 900px;
      }
    }
  }
`;
