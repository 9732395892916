import { useState, useEffect } from "react";

import { ComponentImage } from "@components";

interface IProps {
    file: any;
    className?: string;
}

export const ComponentImagePreview = (props: IProps) => {
    //props
    const { file, className } = props;

    const [preview, setPreview] = useState<string>();

    //read file
    useEffect(() => {
        if (typeof file === "string") {
            setPreview(file);
        } else {
            setPreview(URL.createObjectURL(file));
        }
        return () => {
            preview && URL.revokeObjectURL(preview);
        };
    }, [file]);

    const convertToSrc = (): string | null => {
        if (typeof preview === "string") return preview;
        return null;
    };

    return <ComponentImage src={convertToSrc()} alt="Preview" className={className} />;
};
