import { useTranslation } from "react-i18next";
import React from "react";
import { Formik } from "formik";

import { ShareButton, ShareInput, StyledUpdateProfile } from "@components";
import { HeaderSignUp } from "../SignUp";
import { ShareSelect } from "../Shared/Select";

interface IUpdateProfileS2Component {
    data?: any;
    onSubmit: (values: any) => void;
    bloodgroups?: any;
    languages: any;
    jobs?: any;
    salaries?: any;
    drinkers?: any;
    smokers?: any;
    hopeMeets?: any;
    dateCosts?: any;
    withChilds?: any;
    readyMarrieds?: any;
    lookingFors?: any;
    handleOnBack: () => void;
    handleOnSkip: () => void;
}

export const UpdateProfileS2Component = (props: IUpdateProfileS2Component) => {
    const {
        data,
        onSubmit,
        bloodgroups,
        languages,
        jobs,
        salaries,
        drinkers,
        smokers,
        hopeMeets,
        dateCosts,
        withChilds,
        readyMarrieds,
        lookingFors,
        handleOnSkip,
        handleOnBack,
    } = props;
    const { t } = useTranslation();

    return (
        <StyledUpdateProfile>
            <HeaderSignUp
                title="オプションのフィールド"
                noteTitle="最終段階"
                showIcon
                titleHeader="スキップ"
                onBack={handleOnBack}
                onSkip={handleOnSkip}
            />
            <div className="form_container">
                <div className="form">
                    <Formik
                        initialValues={data}
                        onSubmit={(values: any) => onSubmit(values)}
                        enableReinitialize={true}
                    >
                        {({
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                            /* and other goodies */
                        }) => (
                            <>
                                <div className="bottom-group">
                                    <ShareSelect
                                        name="bloodgroup"
                                        style={{ width: "47%" }}
                                        placeholder={"- すべて -"}
                                        label={t("page.account.bloodgroup")}
                                        selectOptions={bloodgroups}
                                        onChange={setFieldValue}
                                        value={values.bloodgroup}
                                    />
                                    <ShareSelect
                                        name="language"
                                        style={{ width: "47%" }}
                                        placeholder={"- すべて -"}
                                        label={t("page.account.language")}
                                        selectOptions={languages}
                                        onChange={setFieldValue}
                                        value={values.language}
                                    />
                                </div>
                                <ShareSelect
                                    name="job"
                                    style={{ width: "100%" }}
                                    placeholder={"- すべて -"}
                                    label={t("page.account.job")}
                                    selectOptions={jobs}
                                    onChange={setFieldValue}
                                    value={values.job}
                                />
                                <ShareSelect
                                    name="salary"
                                    style={{ width: "100%" }}
                                    placeholder={"- すべて -"}
                                    label={t("page.account.salary")}
                                    selectOptions={salaries}
                                    onChange={setFieldValue}
                                    value={values.salary}
                                />
                                <ShareSelect
                                    name="lookingFor"
                                    style={{ width: "100%" }}
                                    placeholder={"- すべて -"}
                                    label={"求めている関係"}
                                    selectOptions={lookingFors}
                                    onChange={setFieldValue}
                                    value={values.lookingFor}
                                />
                                <ShareInput
                                    name="hobby"
                                    style={{ width: "100%" }}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    label={"好きなこと・趣味"}
                                    value={values.hobby}
                                />
                                <ShareSelect
                                    name="willSee"
                                    style={{ width: "100%" }}
                                    placeholder={"- すべて -"}
                                    label={"出会うまでの希望"}
                                    selectOptions={hopeMeets}
                                    onChange={setFieldValue}
                                    value={values.willSee}
                                />
                                <ShareSelect
                                    name="firstFee"
                                    style={{ width: "100%" }}
                                    placeholder={"- すべて -"}
                                    label={"初回デート費用"}
                                    selectOptions={dateCosts}
                                    onChange={setFieldValue}
                                    value={values.firstFee}
                                />
                                <ShareSelect
                                    name="hasChildren"
                                    style={{ width: "100%" }}
                                    placeholder={"- すべて -"}
                                    label={"子供の有無"}
                                    selectOptions={withChilds}
                                    onChange={setFieldValue}
                                    value={values.hasChildren}
                                />
                                <ShareSelect
                                    name="readyMarried"
                                    style={{ width: "100%" }}
                                    placeholder={"- すべて -"}
                                    label={"結婚に対する意思"}
                                    selectOptions={readyMarrieds}
                                    onChange={setFieldValue}
                                    value={values.readyMarried}
                                />
                                <div className="bottom-group">
                                    <ShareSelect
                                        name="drinker"
                                        style={{ width: "47%" }}
                                        placeholder={"- すべて -"}
                                        label={t("page.account.drinker")}
                                        selectOptions={drinkers}
                                        onChange={setFieldValue}
                                        value={values.drinker}
                                    />
                                    <ShareSelect
                                        name="smoker"
                                        style={{ width: "47%" }}
                                        placeholder={"- すべて -"}
                                        label={t("page.account.smoker")}
                                        selectOptions={smokers}
                                        onChange={setFieldValue}
                                        value={values.smoker}
                                    />
                                </div>
                                <ShareButton
                                    className="submit"
                                    type="primary"
                                    size="large"
                                    text={"確認"}
                                    disable={isSubmitting}
                                    htmlType="submit"
                                    onClick={handleSubmit}
                                />
                            </>
                        )}
                    </Formik>
                </div>
            </div>
        </StyledUpdateProfile>
    );
};
