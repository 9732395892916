import { PATH_HOME, PATH_MESSAGE, PATH_FAVORITE, PATH_BLOG, PATH_SETTING } from "@configs";
import { StyledNavigationBar, StyledNavigationItem, IconHome, IconMessage, IconFavorite, IconSetting, IconBlog } from "@components";
import { INavigationItem } from "@interfaces";

export const ComponentNavigationBar = () => {
    const NAVIGATION_ITEMS: Array<INavigationItem> = [
        {
            name: "ホーム",
            path: PATH_HOME,
            icon: <IconHome />,
            exact: true,
        },
        {
            name: "メッセージ",
            path: PATH_MESSAGE,
            icon: <IconMessage />,
            exact: true,
        },
        {
            name: "お気に入り",
            path: PATH_FAVORITE,
            icon: <IconFavorite />,
            exact: true,
        },
        {
            name: "設定",
            path: PATH_SETTING,
            icon: <IconSetting />,
            exact: true,
        },
        {
            name: "コラム",
            path: PATH_BLOG,
            icon: <IconBlog />,
            exact: false,
        },
    ];

    return (
        <StyledNavigationBar>
            {NAVIGATION_ITEMS.map((navigationItem: INavigationItem, index: number) => (
                <StyledNavigationItem key={index} to={navigationItem.path} exact={navigationItem.exact} className="navigation_item">
                    {navigationItem.icon}
                    <p className="navigation_item__name">{navigationItem.name}</p>
                </StyledNavigationItem>
            ))}
        </StyledNavigationBar>
    );
};
