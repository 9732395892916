import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IAuth, IInfo, ILogin, ISignUp } from "@interfaces";
import { authApi } from "@api";
import { RootState } from ".";

export const login = createAsyncThunk<IAuth, ILogin>(
    "auth/login",
    async (values: ILogin, { rejectWithValue }) => {
        try {
            const res = await authApi.login(values);
            return res.data as IAuth;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const register = createAsyncThunk<IAuth, ISignUp>(
    "auth/signup",
    async (values: ISignUp, { rejectWithValue }) => {
        try {
            const res = await authApi.register(values);
            return res.data as IAuth;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

interface IState {
    auth: IAuth | null;
    isRemember: boolean;
    isLoading: boolean;
    error: string;
    tokenInfoAuth: string;
    userInfo: IInfo | null;
    gender: number | null;
    isProfile: boolean;
    tokenDevice: string;
}

const initialState: IState = {
    auth: null,
    isRemember: false,
    isLoading: false,
    error: "",
    tokenInfoAuth: "",
    userInfo: null,
    gender: null,
    isProfile: false,
    tokenDevice: "",
};

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        logout: () => {
            localStorage.clear();
            return initialState;
        },
        setAuth: (state, action) => {
            state.auth = action.payload;
        },
        setInfo: (state, action) => {
            state.userInfo = action.payload;
        },
        setTokenAuth: (state, action) => {
            state.tokenInfoAuth = action.payload;
        },
        setGenderRedux: (state, action) => {
            state.gender = action.payload;
        },
        setIsProfile: (state, action) => {
            state.isProfile = action.payload;
        },
        setTokenDevice: (state, action) => {
            state.tokenDevice = action.payload;
        },
        setIsReceiveNotification(state, action) {
            //@ts-ignore
            state.userInfo.push_notification = action.payload;
        },
        resetInfoAuth(){
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(login.fulfilled, (state, action: { payload: IAuth }) => {
            if (action.payload.access_token) {
                state.auth = action.payload;
            }
            // if (!state.isRemember) {
            //     // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            //     state.auth!.refreshToken = null;
            // }
            state.isLoading = false;
        });

        builder.addCase(login.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(login.rejected, (state) => {
            state.auth = null;
            state.isLoading = false;
        });
    },
});

export const selectAuth = (state: RootState) => state.auth;
export const selectToken = (state: RootState) => state.auth.tokenInfoAuth;
export const selectInfoUser = (state: RootState) => state.auth.userInfo;
export const selectIsProfile = (state: RootState) => state.auth.isProfile;

export const {
    logout,
    setAuth,
    setInfo,
    setTokenAuth,
    setGenderRedux,
    setIsProfile,
    setTokenDevice,
    setIsReceiveNotification,
    resetInfoAuth
} = authSlice.actions;

export default authSlice.reducer;
