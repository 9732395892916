import axiosClient from "./axiosClient";

export const settingApi = {
    getListFaq() {
        const url = "faqs";
        return axiosClient.get(url);
    },
    getTerms() {
        const url = "terms";
        const baseURL = process.env.REACT_APP_API_URL;
        const newBaseUrl = baseURL?.substring(0, baseURL.length - 4);
        return axiosClient.get(url, { baseURL: newBaseUrl });
    },
    getPrivacyPolicy() {
        const url = "privacy-policy";
        const baseURL = process.env.REACT_APP_API_URL;
        const newBaseUrl = baseURL?.substring(0, baseURL.length - 4);
        return axiosClient.get(url, { baseURL: newBaseUrl });
    },
    getListUserBlock(page: number) {
        const url = "user/blocks";
        return axiosClient.get(url, { params: { page } });
    },
    unBlockUser(userId: number) {
        const url = "user/unblock";
        return axiosClient.post(url, { id: userId });
    },
    deleteAccount(id: number | undefined) {
        const url = `user/delete-user/${id}`;
        return axiosClient.get(url);
    },
    updateDeviceToken(token: string) {
        const url = "user/device/token";
        return axiosClient.post(url, { token, type: 3 });
    },
    updateSetting(param: 0 | 1) {
        const url = "user/setting";
        return axiosClient.post(url, { push_notification: param });
    },
};
