import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { ShareButton } from "@components";

interface IProps {
    onClose(): void;
    onDelete(): void;
}

export const ComponentMessageModal = (props: IProps) => {
    //props
    const { onClose, onDelete } = props;

    //page hooks
    const { t } = useTranslation();

    return (
        <StyledContainer>
            <div className="modalContainer">
                <div className="back_drop" onClick={onClose} />
                <div className="viewModal">
                    <h4 className="txtTitle">{t("page.message.deleteConfirm")}</h4>
                    <div className="viewButton">
                        <ShareButton className="buttonActive" type="primary" size="large" text={t("page.message.delete")} htmlType="button" styleContainer={{ width: "49%" }} onClick={onDelete} />
                        <ShareButton className="buttonInactive" type="primary" size="large" text={t("page.message.cancel")} htmlType="button" styleContainer={{ width: "49%" }} onClick={onClose} />
                    </div>
                </div>
            </div>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    .modalContainer {
        width: 100vw;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.54);
        padding-bottom: 20px;
        z-index: 99;
        position: fixed;
        top: 0;
        left: 0;
    }
    .back_drop {
        width: 100%;
        height: 100%;
    }
    .viewModal {
        width: 35%;
        display: flex;
        background: white;
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-bottom: 20px;
        padding-left: 16px;
        padding-right: 16px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .txtTitle {
        padding-top: 17px;
        padding-bottom: 17px;
    }
    .viewButton {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }
    .buttonActive {
        background: red;
        border-radius: 0.25rem;
    }
    .buttonInactive {
        background: #c4c4c4;
        border-radius: 0.25rem;
    }

    //mobile
    @media only screen and (max-width: 46.1875em) {
        .viewModal {
            width: 90%;
        }
    }

    //tablet
    @media only screen and (min-width: 46.25em) and (max-width: 62.9375em) {
        .viewModal {
            width: 90%;
        }
    }
`;
