import React, { useState, useRef } from "react";
import styled from "styled-components";
import { CloseOutlined, PlayCircleFilled } from "@ant-design/icons";

interface IModalViewImage {
    viewMedia: any;
    onClose: () => void;
}

export const ModalViewImage = (props: IModalViewImage) => {
    const { onClose, viewMedia } = props;

    const [isPlayVideo, setIsPlayVideo] = useState<boolean>(true);

    const videoRef = useRef<HTMLVideoElement>(null);

    const handleVideo = () => {
        setIsPlayVideo(!isPlayVideo);
        isPlayVideo ? videoRef?.current?.pause() : videoRef?.current?.play();
    };

    const handleEndedVideo = () => {
        setIsPlayVideo(false);
    };

    return (
        <StyledModalViewImage>
            <div className="close" onClick={onClose}>
                <CloseOutlined className="close_icon" />
            </div>
            <div className="view" onClick={handleVideo}>
                {viewMedia?.type === 0 ? (
                    <>
                        <video autoPlay onEnded={handleEndedVideo} ref={videoRef} className="img">
                            <source src={viewMedia?.url} type="video/mp4" />
                            <source src={viewMedia?.url} type="video/ogg" />
                        </video>
                        {!isPlayVideo && (
                            <div className="play">
                                <PlayCircleFilled />
                            </div>
                        )}
                    </>
                ) : (
                    <img src={viewMedia?.url} className="img"></img>
                )}
            </div>
        </StyledModalViewImage>
    );
};

const StyledModalViewImage = styled.div`
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .view {
        overflow: hidden;
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        .img {
            max-height: 100%;
            max-width: 100%;
            display: block;
        }
        .play {
            color: white;
            font-size: 2rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .close {
        position: absolute;
        top: 3%;
        right: 10px;
        z-index: 999;
        .close_icon {
            padding: 0.5rem;
            cursor: pointer;
            font-size: 2rem;
            color: white;
            &:hover {
                opacity: 0.8;
            }
        }
    }
`;
