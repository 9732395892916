import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import { StyledListBlogCategory, ShareButton, ComponentBlogCard, ComponentInfiniteScrollLoader } from "@components";
import { selectAuth, selectApp } from "@redux";
import { PATH_CREATE_ARTICLE } from "@configs";
import { IBlogData } from "@interfaces";

interface IProps {
    listBlog: Array<IBlogData>;
    hasMore: boolean;
    handleNextPage(): void;
    handleDeleteBlogCard?(id: number): void;
}

export const ComponentListBlogCategory = (props: IProps) => {
    //page hooks
    const history = useHistory();
    const { t } = useTranslation();

    //Props
    const { listBlog, hasMore, handleNextPage, handleDeleteBlogCard } = props;

    //Redux state
    const active_blog = useSelector(selectAuth).userInfo?.active_blog;

    const handleClickNewArticle = () => {
        history.push(PATH_CREATE_ARTICLE);
    };

    const isLoading = useSelector(selectApp).loading;

    return (
        <StyledListBlogCategory active_blog={active_blog === 1}>
            <div className="list_blog_button__wrapper">
                {active_blog === 1 && (
                    <ShareButton className="list_blog__button" type="primary" size="large" text={t("page.list_blog.create_article")} htmlType="button" onClick={handleClickNewArticle} />
                )}
                <div className="category_title">{listBlog[0]?.category_name}</div>
            </div>
            <div id="scroll_view" className="content__wrapper">
                {isLoading ? (
                    <div className="empty_list">ローディング中</div>
                ) : listBlog?.length > 0 ? (
                    <InfiniteScroll
                        dataLength={listBlog?.length}
                        hasMore={hasMore}
                        loader={<ComponentInfiniteScrollLoader className="loader" />}
                        next={handleNextPage}
                        className="list_blog"
                        scrollableTarget="scroll_view"
                    >
                        {listBlog?.map((blog: IBlogData) => (
                            <ComponentBlogCard handleDelete={handleDeleteBlogCard} blogCard={blog} key={blog.id} />
                        ))}
                    </InfiniteScroll>
                ) : (
                    <div className="empty_list">{t("page.message.emptyList")}</div>
                )}
            </div>
        </StyledListBlogCategory>
    );
};
