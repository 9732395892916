import { ISendChat } from "@interfaces";
import axiosClient from "./axiosClient";

export const ChatService = {
    listChat: () => {
        const url = `chat/messages`;
        return axiosClient.get(url);
    },
    listChatByTopic: (id: number, page: number) => {
        const url = `chat/topic/${id}`;
        return axiosClient.get(url, { params: { page } });
    },
    sendChat: (param: ISendChat) => {
        const url = "chat/message";
        return axiosClient.post(url, param);
    },
    createChat: (param: { user_id: number }) => {
        const url = "chat/create";
        return axiosClient.post(url, param);
    },
    sendImage: (file: any) => {
        const url = "upload/image";
        const form = new FormData();
        form.append("image", file);
        return axiosClient.post(url, form);
    },
    sendVideo: (file: any) => {
        const url = "upload/video";
        const form = new FormData();
        form.append("video", file);
        return axiosClient.post(url, form);
    },
    deleteMessageChat(id: number) {
        const url = `chat/messages/${id}`;
        return axiosClient.delete(url);
    },
};
