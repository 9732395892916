import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { StyledModalPaymentMethod, StyledItemPaymentMethod } from "@components";

interface IProps {
    onCloseModal(): void;
    payment(method: number): void;
}

export const ComponentModalPaymentMethod = (props: IProps) => {
    //page Hooks
    const { t } = useTranslation();

    //props
    const { onCloseModal, payment } = props;

    //State
    const [activeMethod, setActiveMethod] = useState<number>(0);

    const payments = [
        { title: "1ヶ月（女装と女子会Jojo)", price: "¥2,800", method: 1 },
        { title: "3ヶ月（女装と女子会Jojo)", price: "¥7,800", method: 3 },
        { title: "6ヶ月（女装と女子会Jojo)", price: "¥15,000", method: 6 },
    ];

    const selectMethod = (index: number) => {
        setActiveMethod(index);
    };

    return (
        <StyledModalPaymentMethod>
            <div className="backdrop" onClick={onCloseModal} />
            <div className="modal">
                <div className="payment_method__wrapper">
                    {payments && payments.length === 0
                        ? t("page.payment.empty")
                        : payments.map((payment: any, index: number) => (
                              <ComponentItemPaymentMethod key={index} title={payment?.title} price={payment?.price} active={activeMethod === index} onClick={() => selectMethod(index)} />
                          ))}
                </div>
                <div onClick={payments && payments.length === 0 ? onCloseModal : () => payment(payments[activeMethod].method)} className="confirm_method">
                    {payments && payments.length === 0 ? t("page.payment.btnClose") : t("page.payment.btnJoin")}
                </div>
            </div>
        </StyledModalPaymentMethod>
    );
};

interface IItemPaymentMethodProps {
    title: string;
    price: string;
    active: boolean;
    onClick(): void;
}

const ComponentItemPaymentMethod = (props: IItemPaymentMethodProps) => {
    const { title, price, active, onClick } = props;
    return (
        <StyledItemPaymentMethod className={active ? "active" : ""} onClick={onClick}>
            <div className="title_method">
                Jojo会員登録：
                <br />
                {title}
            </div>
            <div className="price_method">{price}</div>
        </StyledItemPaymentMethod>
    );
};
