import { coverString2, theme } from "@utils";
import moment from "moment";
import { PlayCircleFilled } from "@ant-design/icons";
import React from "react";
import styled from "styled-components";

interface IItemMessage {
    isAuth?: boolean;
    data: any;
    onClickBoxImage: (url: string, type: number) => void;
}

export const ItemMessage = (prop: IItemMessage) => {
    const { isAuth, data, onClickBoxImage } = prop;

    const message = coverString2(data?.message);

    return (
        <StyledItemMessage isAuth={isAuth}>
            <div className="content-chat">
                {data?.image ? (
                    <div className="imageBox" onClick={() => onClickBoxImage(data?.image, data?.type_media)}>
                        {data?.type_media === 0 ? (
                            <>
                                <video className="video">
                                    <source src={data?.image} type="video/mp4" />
                                    <source src={data?.image} type="video/ogg" />
                                </video>
                                <div className="play">
                                    <PlayCircleFilled />
                                </div>
                            </>
                        ) : (
                            <img src={data?.image} className="image" />
                        )}
                    </div>
                ) : (
                    <div className="message" dangerouslySetInnerHTML={{ __html: message }}></div>
                )}

                <p className="time">{moment(data?.updated_at).format("HH:mm")}</p>
            </div>
        </StyledItemMessage>
    );
};

const StyledItemMessage = styled.div<{ isAuth?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: ${(p) => (p.isAuth ? "flex-end" : "flex-start")};
    .content-chat {
        max-width: 60%;

        .message {
            background-color: ${(p) => (p.isAuth ? theme.colors.secondary : theme.colors.textHeader)};
            padding: 0.3rem 1rem;
            border-radius: 14px;
            a {
                word-break: break-word;
                word-wrap: break-word;
                white-space: pre-wrap;
            }
        }
    }
    p {
        margin: 0;
    }
    .imageBox {
        margin-bottom: 5px;
        display: flex;
        align-items: flex-end;
        justify-content: ${(p) => (p.isAuth ? "flex-end" : "flex-start")};
        overflow: hidden;
        border-radius: 8px;
        position: relative;
        .image {
            height: 11.25rem;
            width: calc(calc(var(--window-width) * 3) / 5);
            border-radius: 8px;
            object-fit: cover;
        }
        .play {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-size: 2rem;
        }
        .video {
            background-color: #a7a7ab;
            height: 11.25rem;
            width: calc(calc(var(--window-width) * 3) / 5);
            object-fit: cover;
        }
    }
    @media (min-width: ${theme.breakPoints.breakMobilePhone}) {
        .imageBox {
            .image {
                width: calc(calc(var(--window-width) * 2) / 5);
                max-width: 400px;
            }
            .video {
                width: calc(calc(var(--window-width) * 2) / 5);
                max-width: 400px;
            }
        }
    }

    .time {
        margin: 0.5rem 0;
        font-size: 0.8rem;
        color: ${theme.colors.black};
        text-align: ${(p) => (p.isAuth ? "right" : "left")};
    }
`;
