import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import { ComponentImage } from "@components";

interface IAvatarImage {
    id: number;
    url: string;
}

interface IProps {
    listImages?: Array<IAvatarImage> | null;
}

export const ComponentSliderImages = (props: IProps) => {
    //props
    const { listImages } = props;

    SwiperCore.use([Pagination]);

    const pagination = {
        clickable: true,
    };

    return (
        <Swiper slidesPerView={1} pagination={pagination}>
            {listImages ? (
                listImages.map((imageUrl: IAvatarImage) => (
                    <SwiperSlide key={imageUrl.id}>
                        <ComponentImage className="avatar_image" src={imageUrl.url} />
                    </SwiperSlide>
                ))
            ) : (
                <SwiperSlide>
                    <ComponentImage className="avatar_image" src={null} />
                </SwiperSlide>
            )}
        </Swiper>
    );
};
