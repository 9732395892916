import styled from "styled-components";

import { theme } from "@utils";

export const StyledArticleDetail = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    font-family: ${theme.family.fontKozuka};
    &::-webkit-scrollbar {
        display: none;
    }
    .top_img__wrapper {
        width: 100%;
        position: relative;
        margin-bottom: 0.625rem;
        .main_image {
            width: 100%;
            height: auto;
            display: block;
        }
        .username {
            width: 74%;
            background: linear-gradient(90deg, #ffffff 47.66%, rgba(255, 255, 255, 0) 104.27%);
            opacity: 0.7;
            border-radius: 0 0 0.25rem 0;
            padding: 0.375rem 0.813rem;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.25rem;
            color: ${theme.colors.textSecondary};
            position: absolute;
            bottom: 0;
            left: 0;
            text-decoration: none;
        }
    }
    .center {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0.813rem;
        margin-bottom: 1.688rem;
        .category {
            background-color: ${theme.colors.secondary};
            border-radius: 6.25rem;
            color: ${theme.colors.darkText};
            padding: 0.125rem 1.25rem;
            font-size: 0.563rem;
            line-height: 1rem;
            font-weight: 500;
            text-decoration: none;
            display: block;
            text-align: center;
        }
        .create_at {
            color: ${theme.colors.text};
            font-size: 0.563rem;
            line-height: 1rem;
            font-weight: 500;
        }
    }
    .content {
        padding: 0 1rem;
        .title {
            font-weight: 700;
            font-size: 1rem;
            line-height: 1.3rem;
            color: ${theme.colors.highlight};
            margin-bottom: 1.5rem;
        }
        .body {
            font-weight: 300;
            font-size: 0.75rem;
            line-height: 1.375rem;
            color: ${theme.colors.black};
            img {
                width: 100%;
                height: auto;
            }
            a {
                word-break: break-word;
                white-space: pre-wrap;
                word-wrap: break-word;
            }
        }
    }

    @media (min-width: ${theme.breakPoints.breakMobilePhone}) {
        .center {
            .category {
                padding: 0.5rem 1.5rem;
                font-size: 0.875rem;
                min-width: 9.375rem;
            }
        }
    }
    @media (min-width: ${theme.breakPoints.breakIpadPro}) {
        max-width: 66.667rem;
    }
`;

export const StyledArticleLightBox = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: ${theme.colors.black};
    color: white;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    .light_box_image {
        width: 100%;
        height: auto;
        object-fit: cover;
        display: block;
    }
    .close_icon {
        position: absolute;
        top: 1rem;
        right: 1rem;
        padding: 0.5rem;
        cursor: pointer;
        font-size: 2rem;
        &:hover {
            opacity: 0.8;
        }
    }

    @media (min-width: ${theme.breakPoints.breakIpadPro}) {
        .light_box_image {
            width: auto;
            height: 100%;
        }
    }
`;
