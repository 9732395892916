export const TRANSLATIONS_JA_PAGE = {
    search: "Search name, phone...",
    tip_loading: "Loading...",
    welcome_to_Admin: "Welcome to Rocket CRM!",
    you_are_signing: "You're signing",
    instead: "instead",
    total: "total",
    theme_color: "Theme color",
    cover_image: "Cover image",
    plan_settings: "Plan settings",
    you_are_on: "You are on",
    free_plan: "Free plan",
    or: "or",
    message: {
        emptyList: "リストは空です",
        delete: "デリート",
        deleteConfirm: "このチャットを削除しますか",
        cancel: "キャンセル",
        not_approved:"審査中です。少々お待ちください。",
        confirm: "検証",
    },
    action: {
        edit: "Edit",
        add: "Add",
        save: "Save",
        cancel: "Cancel",
        upgrade: "Upgrade",
        reset_password: "パスワードを再設定する",
        use: "Use",
        close: "Close",
        upload_text: "Upload {{name}}",
        delete: "Delete",
    },
    layout: {
        home: "Home",
        LINE: "LINE",
        connections: "Connections",
        inbox: "Inbox",
        workflow: "Workflow",
        report: "Report",
        help: "Help",
        current_plan: "Current plan",
        setting: "Setting",
    },
    auth: {
        sign_in: "ログイン",
        log_with: "Log with",
        login_success: "正常にログイン",
        login_fail: "選択されたメールアドレスは、有効ではありません",
        or: "or",
        username: "ユーザー名",
        email: "メールアドレス",
        password: "パスワード",
        password_confirmation: "パスワードの確認",
        change_password: "Change password",
        login: "ログイン",
        forgot: "パスワードを忘れた方",
        remember_me: "Remember Me",
        sign_up_with: "Sign up with",
        member_registration: "会員登録",
        sign_up: "サインアップ",
        step2: "ステップ2",
        profile_editing: "プロフィール編集",
        add_avatar: "ファイルを追加 ",
        step3: "最終段階",
        optional_fields: "オプションのフィールド",
        back: "戻って",
        skip: "スキップ",
        next: "次の一歩",
        done: "確認",
        salary: "給料",
        text_verify_email: "Please verify your email address",
        desc_verify_email: "Check your mailbox for a verification email. Didn't receive an email?",
        resend_email: "Resend email",
        what_is_your_name: "What is your name",
        confirm_password: "パスワードを認証する",
        continue: "Continue",
        login_successfully: "Login Successfully",
        login_failed: "Login Failed",
        already_account: "すでにアカウントをお持ちですか？",
        password_validate: "パスワードが一致する必要があります",
    },
    account: {
        address: "ロケーション",
        prefecture: " 都道府県",
        profile: "プロフィール",
        avatar: "写真",
        gender: "性別",
        age: "年齢",
        height: "高さ",
        mariage: "婚姻歴",
        bloodgroup: "血液型",
        language: "言語",
        job: "職業",
        salary: "給料",
        looking_for: "探している",
        hobby: "趣味",
        drinker: "アルコール",
        smoker: "タバコ",
        appearance: "ボディタイプ",
    },
    password: {
        current_password: "Current password",
        new_password: "新しいパスワード",
        confirm_new_password: "パスワードを認証する",
    },
    choose_plan: {
        choose_your_plan: "Choose your plan",
        crm_loyalty_hub: "CRM + Loyalty hub",
        crm_standalone: "CRM standalone",
        loyalty_standalone: "Loyalty standalone",
        year_save_20: "Yearly SAVE 20%",
        monthly: "Monthly",
    },
    merchant_account_setting: {
        merchant_account_settings: "Merchant account settings",
        basic_settings: "Basic settings",
        account_name: "Account name",
        gozzy_fruit_shop: "Gozzy fruit shop",
        free_plan: "Free plan",
        notification_settings: "Notification settings",
        channels: "Channels",
        events: "Events",
    },
    dashboard: {
        overall_stats: "Overall Stats",
    },
    contact: {
        contacts: "Contacts",
        add_contact: "Add contact",
        name: "Name",
        role: "Role",
        tel: "Tel",
        member_id: "Member Id",
        points: "Points",
        sales_amount: "Sales Amount",
        tier: "Tier",
        create_date: "Create date",
        basic_info: "Basic info",
        date_of_birth: "Date of birth",
        email: "Email",
        contact_add_date: "Contact add date",
        member_registration_date: "Member registration date",
        traffic_source: "Traffic source",
        last_activity_date: "Last activity date",
        _points: "points",
        points_to_next_tier: "Points to next tier",
        membership: "Membership",
        membership_tier: "Membership tier",
        LINE: "LINE",
        LINE_ID: "LINE ID",
        LINE_User_ID: "LINE User ID",
        LINE_chat_tags: "LINE chat tags",
        points_balance: "Points balance",
        points_to_expire_in_1_month: "Points to expire in 1 month",
        last_points_collection_date: "Last points collection date",
        last_points_redemption_date: "Last points redemption date",
        coupon_balance: "Coupon balance",
        coupon_used: "Coupon used",
        coupons_to_expire_in_1_month: "Coupons to expire in 1 month",
        last_coupon_collection_date: "Last coupon collection date",
        last_coupon_used_date: "Last coupon used date",
        coupon: "Coupon",
        sales: "Sales",
        sales_last_date: "Sales last date",
        form: "Form",
        field_tags: "Field {{name}} - tags",
    },
    preview: {
        preview: "Preview",
        points_edit: "Points edit",
        change_points_to: "Change points to",
        current_points: "Current points ",
        edit_name: "Edit name",
        edit_membership_tier: "Edit membership tier",
        customer_is_on: "Customer is on",
        change_membership_tier: "Change membership tier",
        good: "Good",
        points: "Points",
        coupons: "Coupons",
        member_details: "รายละเอียดสมาชิก &gt;",
        Expiration_month: "หมดอายุอีก 1 เดือน : 500",
        1820: "1,820",
        coupon_5: "5 คูปอง",
        use_point: "ใช้ Point",
        privilege: "สิทธิพิเศษ",
        coupon: "คูปอง",
        record: "ประวัติ",
    },
    permission: {
        manage_permissions: "Manager permissions",
        text_permission_sub_title: "Add new account members and manage their permissions here.",
        permission_types: "Permission types",
        add_member: "Add member",
        choose_permission: "Choose permission",
        edit_permissions_granted_to_this_account_member:
            "Edit permissions granted to this account member.",
        delete_this_account_member: "Delete this account member",
        grant_members_permissions_via_direct_URL_links:
            "Grant members permissions via direct URL links.",
        choose_permission_and_copy_link: "Choose permission and copy link",
    },
    loyalty: {
        membership_name: "Membership name",
        points: "Points",
        bath_spent: "Bath spent",
        benefits: "Benefits",
        loyalty_rules: "Loyalty rules",
        loyalty_settings: "Loyalty settings",
        set_point_expiry: "Set point expiry",
        automatically_assign_starting_points_to_new_members:
            "Automatically assign starting points to new members?",
        set_membership_tier: "Set membership tier",
        images: "Images",
        max_images: "(max {{name}} images)",
        image_ratio_support_only_1_1: "Image ratio support only 1: 1 (square square)",
        the_proposed_size_is_640_x_640: "The proposed size is 640 x 640",
        maximum_image_size_10_MB: "Maximum image size is 10 MB",
    },
    splash: {
        next_step: "次へ",
        choose_gender__welcome: "会えて嬉しい ♪",
        choose_gender__title: "性別を教えてください",
        male: "女装男子 (男性)",
        female: "女性",
        term_of_transaction: "取引条件",
        sign_up: "会員登録",
    },
    forgot_password: {
        username_or_email: "ユーザー名またはメールアドレス",
        title: "パスワードを忘れた方",
        send_email: "パスワード再発行メールを送信",
        send_password_success: "パスワードが正常に送信されました",
    },
    daft_top: {
        categories: "コラムニスト",
    },
    list_blog: {
        category: "カテゴリー",
        blog_recommend: "おすすめの記事",
        create_article: "記事を作成する",
        show_all: "すべて表示",
        delete: "デリート",
    },
    create_article: {
        new_article: "新しい記事",
        article_title: "記事のタイトル",
        article_category: "カテゴリー",
        article_images: "写真",
        article_content: "コンテンツ",
        enter_something: "何かを入力",
        add_file: "ファイルを追加 ",
        edit: "編集",
    },
    setting: {
        title: "設定",
        changePassword: "パスワード変更",
        profile: "プロフィール編集",
        notification: "プッシュ通知",
        localNotification: "ローカル通知\nマッチング、チャット返事",
        remoteNotification: "リモート通知\n管理から",
        inquiry: "お問い合わせ",
        FAQ: "よくある質問",
        term: "利用規約",
        privacy: "プライバシーポリシー",
        logout: "ログアウト",
        logoutAlert: "ログアウトしますか？",
        confirm: "はい",
        cancel: "いいえ",
        userProfile: "プロフィール確認",
        listBlock: "ブロックした人",
        withdrawal: "退会",
    },
    FAQ: {
        noResult: "検索結果はありません",
    },
    userDetail: {
        unblockMessageConfirm: "このユーザーをブロックを解除しますか？",
        unblock: "ブロックを消す",
    },
    deleteAccount: {
        title: "退会",
        questionDelete: "本当に退会されますか？",
        rule: "なお一度退会されますと、全ての本会員の \nサービスがご利用できなくなります。\n下記ボタンから手続きができます。 \nこの処理は取り消しできません。",
        cancel: "キャンセル",
        confirm: "退会",
    },
    sign_up_step_2: {
        age: "年齢は、",
        location: "都道府県は、",
        sexuality: "ボディタイプは、",
        mariage: " 婚姻歴は、",
        height: "身長は、",
        appearance: "性別は、",
    },
    payment: {
        empty: "メンバーシップパッケージはストアから入手できません",
        btnJoin: "メンバーに参加する",
        btnClose: "閉じる",
    },
    verify_passport: {
        title: "年齢確認",
        sub_title_1: "安心、安全に使っていただく為に",
        sub_title_2: "下記の証明書を1つ選んで提出してください",
        upload_id: "タップで身分名証明書をアップロード",
        passport: "パスポート",
        health_card: "健康保険証",
        license_card: "免許証",
        verify_rule_title: "以下の画像は審査対象外となります",
        verify_rule_1: "・一部が隠れている",
        verify_rule_2: "・ピントぼけ",
        verify_rule_3: "・写真が切れている",
        verify_privacy_1: "＊お客様情報は厳重に管理しております",
        verify_privacy_2: "提出頂いた証明書は年齢確認、性別確認のみに使用し",
        verify_privacy_3: "それ以外の用途では使用いたしません。",
        verify_privacy_4: "またお客様が退会されたら一定期間保管させていただ",
        verify_privacy_5: "いたのち消去させていただきます。",
        done_verify: "終了",
        go_back: "戻る",
        uploading: "アップロード中...",
        please_wait: "少々お待ちください",
    },
};
