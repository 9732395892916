import { useState } from "react";

import { VerifyPassportComponent } from "@components";
import { authApi } from "@api";
import { VerifyPassportEnum } from "@configs";

export const PageVerifyPassport = () => {
    //page state
    const [toggleLoading, setToggleLoading] = useState<boolean>(false);
    const [screen, setScreen] = useState<string>(VerifyPassportEnum.FORM);
    const [message, setMessage] = useState<string>("");

    const handleSubmit = async (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            //WHAT: CALL API LOGIN
            setToggleLoading(true);
            const data = new FormData();
            !!event.target.files && data.append("passport", event.target.files[0]);
            const res = await authApi.verifyPassport(data);
            //push on success
            setScreen(VerifyPassportEnum.SUCCESS);
            setMessage(res.data.data.message || res.data.message);
        } catch (error: any) {
            //push on error
            setScreen(VerifyPassportEnum.ERROR);
            setMessage(error.response.data.data.message || error.response.data.message);
        } finally {
            setToggleLoading(false);
        }
    };

    return (
        <VerifyPassportComponent
            screen={screen}
            message={message}
            toggleLoading={toggleLoading}
            onChangeImage={handleSubmit}
            handleGoBack={() => setScreen(VerifyPassportEnum.FORM)}
        />
    );
};
