import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { StyledNotificationOption, StyledSwitch } from "@components";
import { selectAuth, setIsReceiveNotification, openToast } from "@redux";
import { IInfo } from "@interfaces";
import { settingApi } from "@api";

export const ComponentNotificationOption = () => {
    //redux state
    const userInfo: IInfo | null = useSelector(selectAuth).userInfo;

    //State
    const [checked, setChecked] = useState<boolean>(userInfo?.push_notification || false);

    //page hook
    const dispatch = useDispatch();

    const updateProfile = async (param: 0 | 1) => {
        try {
            const res = await settingApi.updateSetting(param);
            dispatch(
                openToast({
                    //@ts-ignore
                    message: res?.data?.data?.message,
                    type: "success",
                    autoHideDuration: 2000,
                })
            );
        } catch (error: any) {
            dispatch(
                openToast({
                    //@ts-ignore
                    message: error?.response?.data?.message || error?.response?.message,
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (checked) {
            dispatch(setIsReceiveNotification(false));
            updateProfile(0);
        } else {
            dispatch(setIsReceiveNotification(true));
            updateProfile(1);
        }
        setChecked(event.target.checked);
    };

    return (
        <StyledNotificationOption>
            <div className="sub_option">
                <div className="option_title">
                    ローカル通知
                    <br />
                    マッチング、チャット返事
                </div>
                <StyledSwitch checked={checked} onChange={handleChange} />
            </div>
        </StyledNotificationOption>
    );
};
