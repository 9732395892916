export const IconSendChat = () => {
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M25.6667 2.33334L12.8334 15.1667"
                stroke="black"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26.233 2.53155C26.3092 2.31399 26.254 2.07205 26.091 1.90907C25.928 1.74609 25.6861 1.69088 25.4685 1.76702L2.13516 9.93369C1.90296 10.015 1.7439 10.2297 1.73387 10.4755C1.72384 10.7214 1.86488 10.9484 2.08969 11.0483L12.3788 15.6212L16.9518 25.9104C17.0517 26.1352 17.2787 26.2762 17.5245 26.2662C17.7703 26.2561 17.9851 26.0971 18.0664 25.8649L26.233 2.53155Z"
                stroke="black"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
