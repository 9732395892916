import styled from "styled-components";

import { NOT_FOUND_IMAGE_URL } from "@configs";
import { ComponentImage } from "@components";

export const PageError404 = () => {
    return (
        <StyledNotFoundPage>
            <ComponentImage className="not_found_image" src={NOT_FOUND_IMAGE_URL} />
        </StyledNotFoundPage>
    );
};

const StyledNotFoundPage = styled.div`
    width: 100vw;
    height: 100%;
    overflow: hidden;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    .not_found_image {
        width: 100%;
        height: auto;
        display: block;
    }
`;
