import { Link } from "react-router-dom";
import parserHTML from "html-react-parser";
import moment from "moment";

import { StyledArticleDetail, ComponentImage } from "@components";
import { IBlogData } from "@interfaces";
import { PATH_BLOG_CATEGORY, PATH_BLOG_USER } from "@configs";

interface IProps {
    articleData: IBlogData;
    openLightBox(): void;
}

export const ComponentArticleDetail = (props: IProps) => {
    //Props
    const { articleData, openLightBox } = props;

    return (
        <StyledArticleDetail>
            <div className="top_img__wrapper">
                <div className="main_img__wrapper" onClick={openLightBox}>
                    <ComponentImage className="main_image" src={articleData?.image_original} />
                </div>
                <Link className="username" to={`${PATH_BLOG_USER}/${articleData?.user?.id}`}>
                    {articleData?.user?.username}
                </Link>
            </div>
            <div className="center">
                <Link className="category" to={`${PATH_BLOG_CATEGORY}/${articleData?.category_id}`}>
                    {articleData?.category_name}
                </Link>
                <div className="create_at">
                    {moment(articleData.created_at).format("YYYY/MM/DD")}
                </div>
            </div>
            <div className="content">
                <div className="title">{articleData?.title}</div>
                <div className="body">{parserHTML(articleData?.content)}</div>
            </div>
        </StyledArticleDetail>
    );
};
