import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { IconGenderFemale, IconGenderMale, ShareButton, StyledPageSplash, ComponentTopMenu, IconAddressBook } from "@components";
import { PATH_PRICING } from "@configs";
import { useDispatch } from "react-redux";
import { setGenderRedux } from "@redux";

export const PageSelectGender = () => {
    const [gender, setGender] = useState<number>(0);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const history = useHistory();

    const handleToNextStep = (gender: number) => {
        if (gender === 3) {
            dispatch(setGenderRedux(gender));
        } else {
            dispatch(setGenderRedux(gender));
        }
        return history.push(PATH_PRICING);
    };

    return (
        <>
            <ComponentTopMenu prefixIcon={<div></div>} suffixIcon={<IconAddressBook />} hideLogo />
            <StyledPageSplash style={{ paddingTop: "3.375rem", overflow: "hidden" }}>
                <div className="choose_gender__step">
                    {/* <p className="choose_gender__text">{t("page.splash.choose_gender__welcome")}</p> */}
                    <p className="choose_gender__text">{t("page.splash.choose_gender__title")}</p>
                    <div className="gender_group">
                        <div className={["gender_group__item", gender === 3 && "active"].join(" ")} onClick={() => setGender(3)}>
                            <div className="icon__wrapper">
                                <IconGenderMale />
                            </div>
                            {t("page.splash.male")}
                        </div>
                        <div className={["gender_group__item", gender === 1 && "active"].join(" ")} onClick={() => setGender(1)}>
                            <div className="icon__wrapper">
                                <IconGenderFemale />
                            </div>
                            {t("page.splash.female")}
                        </div>
                    </div>
                    {/* <p className="choose_gender__text">{t("page.splash.term_of_transaction")}</p> */}
                    <div className="button__wrapper">
                        <ShareButton
                            className={["btn__next_step", !!gender && "active"].join(" ")}
                            type="primary"
                            size="large"
                            text={t("page.splash.next_step")}
                            htmlType="button"
                            onClick={() => handleToNextStep(gender)}
                            disable={!gender}
                        />
                    </div>
                </div>
            </StyledPageSplash>
        </>
    );
};
