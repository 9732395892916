import { Formik } from "formik";
import React from "react";

import { StyledChangePassWord } from "./styledChangePassWord";
import { ShareButton, ShareInput } from "@components";

interface IChangePassWordComponent {
    initialValues: any;
    validate?: any;
    onSubmit: (value: any, resetForm: any) => void;
}

export const ChangePassWordComponent = (props: IChangePassWordComponent) => {
    const { initialValues, validate, onSubmit } = props;
    return (
        <StyledChangePassWord>
            <div className="form">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validate}
                    onSubmit={(values: any, { resetForm }) => onSubmit(values, resetForm)}
                    enableReinitialize={true}
                >
                    {({
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,

                        /* and other goodies */
                    }) => (
                        <>
                            <div className="form_top">
                                <p className="title">パスワード変更</p>
                                <ShareInput
                                    name="currentPass"
                                    style={{ width: "100%" }}
                                    label={"現在のパスワードを入力"}
                                    onChange={handleChange}
                                    value={values.currentPass}
                                    onBlur={handleBlur}
                                    type="password"
                                />
                                <ShareInput
                                    name="password"
                                    style={{ width: "100%" }}
                                    label={"新しいパスワードを入力"}
                                    onChange={handleChange}
                                    value={values.password}
                                    onBlur={handleBlur}
                                    type="password"
                                />
                                <ShareInput
                                    name="confirmPassword"
                                    style={{ width: "100%" }}
                                    label={"新しいパスワードを再入力"}
                                    onChange={handleChange}
                                    value={values.confirmPassword}
                                    onBlur={handleBlur}
                                    type="password"
                                />
                            </div>
                            <ShareButton
                                className="submit"
                                type="primary"
                                size="large"
                                text={"完了"}
                                disable={isSubmitting}
                                htmlType="submit"
                                onClick={handleSubmit}
                            />
                        </>
                    )}
                </Formik>
            </div>
        </StyledChangePassWord>
    );
};
