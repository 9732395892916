import axiosClient from "./axiosClient";

export const Favorite = {
    listFavorites: (id: any, page: number) => {
        const url = `user/${id}/favorites`;
        return axiosClient.get(url, { params: { page } });
    },
    listFavoriting: (id: any, page: number) => {
        const url = `user/${id}/favoriting`;
        return axiosClient.get(url, { params: { page } });
    },
    favoriteUser: (body: any) => {
        const url = "user/favourite";
        return axiosClient.post(url, body);
    },
    blockUser: (body: any) => {
        const url = "user/block";
        return axiosClient.post(url, body);
    },
    reportUser: (body: any) => {
        const url = "user/save-report-user";
        return axiosClient.post(url, body);
    },
};
