import axiosClient from "./axiosClient";
import { ILogin, ICreateNewPassword } from "@interfaces";

export const authApi = {
    login: (params: ILogin) => {
        const url = "auth/login";
        return axiosClient.post(url, params);
    },
    register: (params: { username: string; email: string; password: string; password_confirmation: string }) => {
        const url = "auth/signup";
        return axiosClient.post(url, params);
    },
    getComboUser: () => {
        const url = "dashboard/users";
        return axiosClient.get(url);
    },
    updateProfile: (formData: any) => {
        const url = "user/profile/save";
        return axiosClient.post(url, formData);
    },
    updateProfileS2: (formData: any) => {
        const url = "user/custom-signup";
        return axiosClient.post(url, formData);
    },
    refreshToken: (refreshToken: string) => {
        const url = "auth/login-refresh-token";
        const params = {
            refreshToken,
        };
        return axiosClient.post(url, params);
    },
    sendEmail: (value: string) => {
        const url = "auth/send-reset-password";
        const params = {
            email: value,
        };
        return axiosClient.post(url, params);
    },
    createNewPassword: (params: ICreateNewPassword) => {
        const url = "auth/reset/password";
        const { email, token, password } = params;
        return axiosClient.post(url, {
            email,
            token,
            password,
            password_confirmation: params.confirmPassword,
        });
    },
    forgotPassword: (email: string) => {
        const url = "auth/forgot/email-web";
        return axiosClient.post(url, { email });
    },
    getUserInfo: (id: number) => {
        const url = `user/${id}`;
        return axiosClient.get(url);
    },
    changePassword: (param: any) => {
        const url = "user/change-password";
        return axiosClient.post(url, param);
    },
    payment: (method: number) => {
        const url = `payment/create/${method}`;
        return axiosClient.get(url);
    },
    verifyPassport: (data: FormData) => {
        const url = "user/upload/passport";
        return axiosClient.post(url, data);
    },
};
