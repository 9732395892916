import { useHistory, useParams } from "react-router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
    ComponentArticleDetail,
    ComponentArticleLightBox,
    ComponentNavigationBar,
    ComponentTopMenu,
    IconArrowRight,
    StyledContainer,
} from "@components";
import { blogApi } from "@api";
import { openToast, selectAuth, setLoading } from "@redux";
import { IBlogData } from "@interfaces";
import { PATH_CREATE_ARTICLE } from "@configs";

export const PageArticleDetail = () => {
    //page hooks
    const dispatch = useDispatch();
    const history = useHistory();
    //@ts-ignore
    const { id } = useParams();

    //State
    const [article, setArticle] = useState<IBlogData>();
    const [isShowLightBox, setIsShowLightBox] = useState<boolean>(false);

    //Redux state
    const userInfo = useSelector(selectAuth).userInfo;

    useEffect(() => {
        getDetail();
    }, [id]);

    const getDetail = async () => {
        try {
            dispatch(setLoading(true));

            const res = await blogApi.getArticleDetail(id);
            setArticle(res?.data?.data);
        } catch (error: any) {
            dispatch(
                openToast({
                    message:
                        error?.response?.data?.message ||
                        error?.response?.message ||
                        error?.message,
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleGoBack = () => {
        history.goBack();
    };

    const handleClickEditButton = () => {
        history.push(`${PATH_CREATE_ARTICLE}/${id}`);
    };

    const handleOpenLightBox = () => {
        setIsShowLightBox(true);
    };

    const handleCloseLightBox = () => {
        setIsShowLightBox(false);
    };

    return (
        <>
            <ComponentTopMenu
                prefixIcon={<IconArrowRight />}
                onClickPrefixIcon={handleGoBack}
                suffixIcon={userInfo?.id === article?.user?.id && <EditButton />}
                onClickSuffixIcon={handleClickEditButton}
            />
            <StyledContainer hasNavbar>
                {article && (
                    <ComponentArticleDetail
                        openLightBox={handleOpenLightBox}
                        articleData={article}
                    />
                )}
            </StyledContainer>
            {isShowLightBox && article?.image && (
                <ComponentArticleLightBox
                    imgSrc={article?.image_original}
                    closeLightBox={handleCloseLightBox}
                />
            )}
            <ComponentNavigationBar />
        </>
    );
};

const EditButton = () => {
    //page hooks
    const { t } = useTranslation();
    return <div className="top_menu__button">{t("page.create_article.edit")}</div>;
};
