import styled from "styled-components";

import { theme } from "@utils";
import { ReactElement } from "react";

interface IProps {
    prefixIcon?: ReactElement;
    onClickPrefixIcon?(): void;
    suffixIcon?: ReactElement | boolean;
    onClickSuffixIcon?(): void;
    title?: string;
    hideLogo?: boolean;
}

export const ComponentTopMenu = (props: IProps) => {
    const { prefixIcon, suffixIcon, onClickPrefixIcon, onClickSuffixIcon, title, hideLogo } = props;

    return (
        <StyledTopMenu>
            <div className="content__wrapper">
                {prefixIcon && (
                    <div className="prefix__wrapper" onClick={onClickPrefixIcon}>
                        {prefixIcon}
                    </div>
                )}
                {title ? (
                    <div className="top_menu__logo">{title}</div>
                ) : (
                    !hideLogo && (
                        <img
                            className="top_menu__logo"
                            src="/images/splash/logojojo.png"
                            alt="Logo"
                        />
                    )
                )}
                {suffixIcon && (
                    <div className="suffix__wrapper" onClick={onClickSuffixIcon}>
                        {suffixIcon}
                    </div>
                )}
            </div>
        </StyledTopMenu>
    );
};

const StyledTopMenu = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 3.375rem;
    padding: 0.313rem 0;
    background-color: ${theme.colors.primary};
    z-index: 999;
    .content__wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin: auto;
    }
    .top_menu__logo {
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        color: ${theme.colors.white};
        font-size: 1.25rem;
        font-weight: 500;
        font-family: ${theme.family.fontKozuka};
        display: flex;
        align-items: center;
    }
    .prefix__wrapper,
    .suffix__wrapper {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 0.625rem;
        cursor: pointer;
        .top_menu__button {
            color: white;
            font-size: 1rem;
            font-family: ${theme.family.fontKozuka};
        }
    }

    @media (min-width: ${theme.breakPoints.breakIpadPro}) {
        .content__wrapper {
            max-width: 66.667rem;
        }
    }
`;
