import styled from "styled-components";

import { theme } from "@utils";

interface IStyledContainerProps {
    hasNavbar: boolean;
}

export const StyledContainer = styled.div<IStyledContainerProps>`
    width: 100vw;
    height: 100%;
    padding-top: 3.375rem;
    padding-bottom: ${(props) => (props.hasNavbar ? "3.563rem" : 0)};
    background-image: url("/images/splash/choose_gender_background.svg");
    background-size: contain;

    .scrollable_view {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        padding: 0 1.25rem;
        &::-webkit-scrollbar {
            display: none;
        }
        &.pt_1 {
            padding-top: 1.25rem;
        }
    }

    @media (max-width: ${theme.breakPoints.breakIpadPro}) and (min-width: ${theme.breakPoints.breakMobilePhone}) {
        padding-bottom: ${(props) => (props.hasNavbar ? "4.563rem" : 0)};
    }
    @media (min-width: ${theme.breakPoints.breakIpadPro}) {
        display: flex;
        justify-content: center;
        .scrollable_view {
            max-width: 66.667rem;
        }
    }
`;

export const StyledPageInner = styled.div`
    margin: 0 auto;
    /* padding: 1.5rem; */
    position: relative;
    background-color: #f0f0f0;
    min-height: ${(props) => `calc(100vh - ${props.theme.header.height})`};
    height: 100vh;
    overflow-y: scroll;
`;
