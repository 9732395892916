import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import parserHTML from "html-react-parser";

import { ComponentTopMenu, StyledContainer, IconArrowRight } from "@components";
import { settingApi } from "@api";
import { setLoading, openToast } from "@redux";

export const PageTerm = () => {
    //page hooks
    const history = useHistory();
    const dispatch = useDispatch();

    //state
    const [pageContent, setPageContent] = useState<string>();

    const handleGoBack = () => {
        history.goBack();
    };

    //What call api terms
    useEffect(() => {
        fetchTermPage();
    }, []);

    const fetchTermPage = async () => {
        try {
            dispatch(setLoading(true));
            const res = await settingApi.getTerms();
            setPageContent(res?.data);
        } catch (error: any) {
            dispatch(
                openToast({
                    message:
                        error?.response?.data?.message ||
                        error?.response?.message ||
                        error?.message,
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <>
            <ComponentTopMenu prefixIcon={<IconArrowRight />} onClickPrefixIcon={handleGoBack} />
            <StyledContainer hasNavbar={false}>
                {pageContent && <div className="scrollable_view">{parserHTML(pageContent)}</div>}
            </StyledContainer>
        </>
    );
};
