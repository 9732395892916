export const TRANS_EN_VALIDATION = {
    invalid_type: {
        email: "Not type of email",
    },
    field_required: "This field is required!",
    email_invalid: "Email is invalid",
    required: "{{name}} is required",
    pass_not_confirm: "This password not confirm",
    max_length: "{{name}} should not exceed 255 characters",
    min: "{{name}} is at least {{number}} ",
    password_must_match: "Passwords must match",
};
