import { TRANSLATIONS_EN_PAGE } from "./page";

import format from "./format";
import error from "./error";
import {TRANS_EN_MESSAGE} from "./message";
import { TRANS_EN_VALIDATION } from "./validation";

export const TRANSLATIONS_EN = {
    format,
    page: TRANSLATIONS_EN_PAGE,
    error,
    message: TRANS_EN_MESSAGE,
    validation: TRANS_EN_VALIDATION,

};
