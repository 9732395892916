import { Route, useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { useEffect } from "react";

import { IRoute } from "@interfaces";
import { authRoutes, normalRoutes, PATH_HOME, PATH_LOGIN, PATH_SPLASH } from "@configs";
import { selectInfoUser, selectIsProfile, selectToken } from "@redux";

export const ComponentAppRoute = (props: IRoute) => {
    //page props
    const { path, exact } = props;
    const userInfo = useSelector(selectInfoUser);
    //page hook
    const access_token = useSelector(selectToken);
    const isProFile = useSelector(selectIsProfile);
    const history = useHistory();
    const { pathname } = useLocation();
    const Component = props.component;
    // const Layout = props.layout || LayoutApp;

    useEffect(() => {
        //WHAT: check user login
        const token = access_token;
        //WHAT: check normal path
        const isNormalRoute = normalRoutes.some((item: string) => {
            if (item === PATH_HOME) {
                return false;
            }
            return pathname.includes(item);
        });

        //WHAT: check auth path
        const isAuthRoute = authRoutes.some((item: string) => pathname.includes(item));

        if (token) {
            if (isAuthRoute && isProFile) {
                (userInfo && userInfo.is_verify) ? history.push(PATH_HOME) : history.push(PATH_LOGIN);
            }
        } else {
            if (isNormalRoute || pathname === PATH_HOME) {
                history.push(PATH_SPLASH);
            }
        }
    }, [pathname, access_token]);
    return <Route path={path} exact={exact} render={() => <Component />} />;
};
