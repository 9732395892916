// import querystring from "querystring";
import { useTranslation } from "react-i18next";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router";
import { useState } from "react";
import { useDispatch } from "react-redux";

import {
  ComponentLoading,
  HeaderSignUp,
  ModalMessageNotApproved,
  ShareButton,
  ShareInput,
  StyledLogin
} from "@components";

import { ILogin } from "@interfaces";
import { PATH_FORGOT_PASSWORD, PATH_HOME, PATH_SELECT_GENDER } from "@configs";
import { login, openToast, resetInfoAuth, setInfo, setIsProfile, setTokenAuth } from "@redux";

export const PageLogin = () => {
    //page Hooks
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    //page state
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [toggleLoading, setToggleLoading] = useState<boolean>(false);
    // page variable
    const data: ILogin = {
        email: process.env.EMAIL_USER || "",
        password: process.env.PASSWORD || "",
    };
    //page state
    // const [loading, setToggleLoading] = useState<boolean>(false);
    // page variable

    const loginSchema = Yup.object().shape({
        email: Yup.string()
            .required(t("validation.field_required"))
            .email(t("validation.email_invalid"))
            .max(
                255,
                t("validation.max_length", {
                    returnObjects: true,
                    name: t("page.auth.email"),
                })
            ),
        password: Yup.string()
            .required(t("validation.field_required"))
            .min(
                6,
                t("validation.min", {
                    returnObjects: true,
                    name: t("page.auth.password"),
                    number: 6,
                })
            )
            .max(
                32,
                t("validation.max", {
                    returnObjects: true,
                    name: t("page.auth.password"),
                    number: 32,
                })
            ),
    });

    const handleForgotPassword = () => {
        history.push(PATH_FORGOT_PASSWORD);
    };

    const handleLogin = async (values: ILogin, { setSubmitting }: FormikHelpers<ILogin>) => {
        try {
            //WHAT: CALL API LOGIN
            setToggleLoading(true);

            const res: any = await dispatch(login(values));

            const { data, success } = res?.payload;

            if (success) {
                if(data && data.profile.is_verify === 1){
                  dispatch(
                    openToast({
                      message: t("page.auth.login_success"),
                      type: "success",
                      autoHideDuration: 2000,
                    })
                  );
                  dispatch(setTokenAuth(data?.access_token));
                  dispatch(setInfo(data?.profile));
                  dispatch(setIsProfile(data?.isProfile));
                  history.push(PATH_HOME);
                }else {
                  setIsOpenModal(true);
                }
            } else {
                dispatch(
                    openToast({
                        //@ts-ignore
                        message: t("page.auth.login_fail"),
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
            }
        } catch (error: any) {
            dispatch(
                openToast({
                    message: error.message,
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
        } finally {
            setSubmitting(false);
            setToggleLoading(false);
        }
    };

    const onClick = () => {
      dispatch(resetInfoAuth());
        history.push(PATH_SELECT_GENDER);
    };
    const handleConfirmModal = () =>{
      setIsOpenModal(false);
    }

    return (
        <>
          {isOpenModal && <ModalMessageNotApproved onConfirm={handleConfirmModal}></ModalMessageNotApproved>}
            {toggleLoading && <ComponentLoading />}
            <StyledLogin>
                <HeaderSignUp title={t("page.auth.login")} />
                <div className="form_container">
                    <div className="form">
                        <Formik
                            initialValues={data}
                            validationSchema={loginSchema}
                            onSubmit={handleLogin}
                        >
                            {({
                                values,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                /* and other goodies */
                            }) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <ShareInput
                                            name="email"
                                            style={{ width: "100%" }}
                                            onChange={handleChange}
                                            value={values.email}
                                            onBlur={handleBlur}
                                            label={t("page.auth.email")}
                                        />
                                        <ShareInput
                                            name="password"
                                            style={{ width: "100%" }}
                                            type="password"
                                            onChange={handleChange}
                                            value={values.password}
                                            onBlur={handleBlur}
                                            label={t("page.auth.password")}
                                        />
                                        <div className="forgot" onClick={handleForgotPassword}>
                                            {t("page.auth.forgot")}
                                        </div>
                                        <ShareButton
                                            className="submit"
                                            type="primary"
                                            size="large"
                                            text={t("page.auth.login")}
                                            htmlType="submit"
                                            disable={isSubmitting}
                                        />
                                    </form>
                                );
                            }}
                        </Formik>
                    </div>
                    <div className="group-text">
                        <div className="text already  text-dark">
                            アカウントをお持ちでありませんか?
                        </div>

                        <div className="text move-to-login " onClick={onClick}>
                            ご登録はこちら
                        </div>
                    </div>
                </div>
            </StyledLogin>
        </>
    );
};
