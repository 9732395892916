export const TRANS_EN_MESSAGE = {
    delete: {
        success: "Delete {{name}} successfully",
        fail: "Delete {{name}} failed",
    },
    duplicate: {
        success: "Duplicate {{name}} successfully",
        fail: "Duplicate {{name}} failed",
    },
    create: {
        success: "Create {{name}} successfully",
        fail: "Create {{name}} failed",
    },
    update: {
        success: "Update {{name}} successfully",
        fail: "Update {{name}} failed",
    },
    form_validate:{
        warning: "Some fields are invalid. Please check!"
    },
    reset_pass: {
        fail: "Reset password not successfully!" ,
        success: "Reset password successfully!"
    },
    sign_up:{
        success: "Sign up successfully!",
        fail: "Sign up not successfully!"
    },
    object: {
        user: "user"
    },
    delete_confirm: "Are you sure delete this {{name}}?",
    exist_email: "Email already exist!",
    not_found: "This {{name}} is not found",
    delete_title: "Are you sure?",
    try_again_text: "Have an error. Please try again",
    invalid: "Invalid page",
    not_permission: "You don't have this permission"
};
