import styled from "styled-components";

import { theme } from "@utils";

export const StyledListUserBlock = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    font-family: ${theme.family.fontKozuka};
    .title {
        font-weight: 500;
        font-size: 1.063rem;
        line-height: 1rem;
        color: ${theme.colors.black};
        padding: 1.688rem 1rem 1.188rem 1rem;
        border-bottom: 0.3px solid ${theme.colors.text};
    }
    .empty_list {
        text-align: center;
        font-weight: 500;
        font-size: 1.063rem;
        line-height: 1rem;
        color: ${theme.colors.black};
        margin-top: 1.5rem;
    }
    .user__wrapper {
        display: flex;
        padding: 0.5rem;
        .user_avatar {
            width: 6.25rem;
            height: 6.25rem;
            border-radius: 0.25rem;
            margin-right: 0.688rem;
            margin-bottom: 0.875rem;
            object-fit: cover;
            display: block;
        }
        .user_block_right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-grow: 1;
            border-bottom: 1px solid #e5e5e5;
            padding-bottom: 0.875rem;
            .username {
                font-weight: 500;
                font-size: 1rem;
                line-height: 1rem;
                color: ${theme.colors.black};
                margin-top: 0.313rem;
            }
            .button_un_block {
                background-color: ${theme.colors.secondary};
                border: none;
                border-radius: 0.25rem;
            }
        }
    }

    @media (min-width: ${theme.breakPoints.breakIpadPro}) {
        max-width: 66.667rem;
    }
`;
