import { CloseOutlined } from "@ant-design/icons";

import { ComponentImage, StyledArticleLightBox } from "@components";

interface IProps {
    closeLightBox(): void;
    imgSrc: string | null;
}

export const ComponentArticleLightBox = (props: IProps) => {
    //props
    const { closeLightBox, imgSrc } = props;

    return (
        <StyledArticleLightBox>
            <ComponentImage src={imgSrc} className="light_box_image" />
            <CloseOutlined className="close_icon" onClick={closeLightBox} />
        </StyledArticleLightBox>
    );
};
