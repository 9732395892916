import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

import {
    ComponentNavigationBar,
    ComponentTopMenu,
    StyledContainer,
    ComponentSetting,
    ComponentNotificationOption,
    ComponentLogoutModal,
} from "@components";
import { ISettingOption } from "@interfaces";
import {
    ActionSetting,
    PATH_CHANGE_PASSWORD,
    PATH_EDIT_PROFILE,
    PATH_FAQ,
    PATH_TERM,
    PATH_PRIVACY,
    PATH_USER_BLOCK,
    PATH_WITHDRAWAL,
} from "@configs";
import { logout, selectAuth } from "@redux";

export const PageSetting = () => {
    //page Hooks
    const { t } = useTranslation();

    //Redux state
    const userInfo = useSelector(selectAuth).userInfo;

    const dispatch = useDispatch();

    const SETTING_PAGE_OPTIONS: Array<ISettingOption> = [
        { name: t("page.setting.changePassword"), hasExpand: true, path: PATH_CHANGE_PASSWORD },
        {
            name: t("page.setting.userProfile"),
            hasExpand: true,
            path: `/detail-favorites/${userInfo?.id}`,
        },
        { name: t("page.setting.profile"), hasExpand: true, path: PATH_EDIT_PROFILE },
        {
            name: t("page.setting.notification"),
            hasExpand: false,
            children: <ComponentNotificationOption />,
        },
        {
            name: t("page.setting.inquiry"),
            hasExpand: true,
            type: ActionSetting.SUPPORT,
        },
        { name: t("page.setting.listBlock"), hasExpand: true, path: PATH_USER_BLOCK },
        { name: t("page.setting.FAQ"), hasExpand: true, path: PATH_FAQ },
        { name: t("page.setting.term"), hasExpand: true, path: PATH_TERM },
        { name: t("page.setting.privacy"), hasExpand: true, path: PATH_PRIVACY },
        { name: t("page.setting.withdrawal"), hasExpand: true, path: PATH_WITHDRAWAL },
        { name: t("page.setting.logout"), hasExpand: true, type: ActionSetting.LOGOUT },
    ];

    const SUPPORT_EMAIL_ADDRESS = "matchingjojojo@gmail.com";

    const [isShowLogoutModal, setIsShowLogoutModal] = useState<boolean>(false);

    const handleCloseLogoutModal = () => {
        setIsShowLogoutModal(false);
    };

    const handleLogout = () => {
        dispatch(logout());
    };

    const handleAction = (type: string | undefined) => {
        switch (type) {
            case ActionSetting.LOGOUT:
                setIsShowLogoutModal(true);
                break;
            case ActionSetting.SUPPORT:
                window.location.href = `mailto:${SUPPORT_EMAIL_ADDRESS}`;
                break;
            default:
                break;
        }
    };

    return (
        <>
            <ComponentTopMenu title={t("page.setting.title")} />
            <StyledContainer hasNavbar>
                <ComponentSetting settingOptions={SETTING_PAGE_OPTIONS} onHandle={handleAction} />
            </StyledContainer>
            {isShowLogoutModal && (
                <ComponentLogoutModal onLogout={handleLogout} onClose={handleCloseLogoutModal} />
            )}
            <ComponentNavigationBar />
        </>
    );
};
