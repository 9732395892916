import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { theme } from "@utils";

export const StyledNavigationBar = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 3.563rem;
    background-color: ${theme.colors.primary};
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: ${theme.breakPoints.breakIpadPro}) {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
    }
    @media (max-width: ${theme.breakPoints.breakIpadPro}) and (min-width: ${theme.breakPoints.breakMobilePhone}) {
        height: 4.536rem;
    }

    @media (max-width: ${theme.breakPoints.breakMobilePhone}) {
        justify-content: space-around;
    }
`;

export const StyledNavigationItem = styled(NavLink)`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 5.625rem;
    text-decoration: none;
    &:hover {
        opacity: 0.7;
    }
    &.active {
        background-color: #299ba8;
    }
    .navigation_item__name {
        margin-bottom: 0;
        color: ${theme.colors.white};
        font-weight: 700;
        font-size: 0.688rem;
        line-height: 1.375rem;
        text-align: center;
        font-family: ${theme.family.fontKozuka};
    }

    @media (max-width: ${theme.breakPoints.breakIpadPro}) {
        min-width: unset;
        flex-grow: 1;
    }

    @media (max-width: ${theme.breakPoints.breakIpadPro}) and (min-width: ${theme.breakPoints.breakMobilePhone}) {
        .navigation_item__name {
            font-size: 0.888rem;
            line-height: 1.875rem;
        }
    }

    @media (max-width: ${theme.breakPoints.breakSmall}) {
        padding: 0;
        .navigation_item__name {
            display: none;
        }
    }
`;
