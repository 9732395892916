import React, { forwardRef, useImperativeHandle, useRef, useMemo, useState } from "react";
import { Formik, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { ICreateNewArticle, ICategoryOption } from "@interfaces";
import { StyledCreateArticle, ShareInput, ShareSelect, ComponentInputImage } from "@components";

interface IProps {
    formData: ICreateNewArticle;
    formValidationSchema?: any;
    editorUploadImage(file: any): Promise<any>;
    categoryOption?: Array<ICategoryOption>;
    onSubmit(values: ICreateNewArticle, { setSubmitting }: FormikHelpers<ICreateNewArticle>): void;
    handleDeleteImage(): void;
}

export const ComponentCreateArticle = forwardRef((props: IProps, ref: React.ForwardedRef<unknown>) => {
    //Props
    const { formData, onSubmit, categoryOption, formValidationSchema, editorUploadImage, handleDeleteImage } = props;
    //page hooks
    const { t } = useTranslation();

    const sendButton = useRef<HTMLButtonElement>(null);

    const [readOnly, setReadOnly] = useState<boolean>(true);

    useImperativeHandle(ref, () => ({
        click: () => {
            sendButton.current?.click();
        },
    }));

    const imageHandler = () => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = () => {
            //@ts-ignore
            const file = input.files[0];

            // file type is only image.
            if (/^image\//.test(file.type)) {
                editorUploadImage(file).then((data) => {
                    insertToEditor(data?.data?.link);
                });
            }
        };
    };

    const linkHandler = (value) => {
        if (value) {
            const href = prompt("Enter the URL");
            if (href) {
                //@ts-ignore
                editorRef?.current?.getEditor().insertText(0, href, "link", href);
            }
        }
    };

    const editorRef = useRef(null);

    const insertToEditor = (url: string) => {
        // push image url to rich editor.
        //@ts-ignore
        editorRef?.current?.getEditor().insertEmbed(null, "image", url);
    };

    //@ts-ignore
    const modules = useMemo(
        () => ({
            toolbar: {
                container: [["image", "bold", "italic", "underline", "clean", { list: "bullet" }, { indent: "-1" }, { indent: "+1" }, "link"]],

                handlers: {
                    image: imageHandler,
                    link: linkHandler,
                },
            },
        }),
        []
    );

    // const handleBlurEditor = () => {
    //     if (editorRef.current) {
    //         setReadOnly(true);
    //         //@ts-ignores
    //         editorRef.current?.getEditor()?.enable(false);
    //         //@ts-ignore
    //         editorRef.current?.getEditor()?.blur();
    //     }
    // };

    const handleFocus = () => {
        if (editorRef.current) {
            setReadOnly(false);
            //@ts-ignore
            editorRef.current?.getEditor()?.enable();
            //@ts-ignore
            editorRef.current?.getEditor()?.focus();
        }
    };

    return (
        <StyledCreateArticle>
            <div className="title">{t("page.create_article.new_article")}</div>
            <Formik initialValues={formData} onSubmit={onSubmit} validationSchema={formValidationSchema} enableReinitialize>
                {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => {
                    const handleChangeEditor = (html) => {
                        setFieldValue("body", html);
                    };
                    return (
                        <form onSubmit={handleSubmit}>
                            <ShareInput
                                name="title"
                                style={{ width: "100%", backgroundColor: "transparent" }}
                                value={values.title}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                label={t("page.create_article.article_title")}
                            />
                            <ShareSelect
                                label={t("page.create_article.article_category")}
                                placeholder="- すべて -"
                                name="category_id"
                                style={{ width: "100%" }}
                                selectOptions={categoryOption}
                                onChange={setFieldValue}
                                value={values.category_id}
                            />
                            <ComponentInputImage
                                name="image"
                                label={t("page.create_article.article_images")}
                                previewImage={values.image}
                                setFieldValue={setFieldValue}
                                handleRemoteClose={handleDeleteImage}
                            />
                            <div className="editor__group">
                                <div className="editor_label">{t("page.create_article.article_content")}</div>
                                <ReactQuill
                                    theme="snow"
                                    onChange={handleChangeEditor}
                                    placeholder={t("page.create_article.enter_something")}
                                    modules={modules}
                                    value={values.body}
                                    ref={editorRef}
                                    readOnly={readOnly}
                                    onFocus={handleFocus}
                                    // onBlur={handleBlurEditor}
                                />
                            </div>
                            <button className="button_submit" ref={sendButton} type="submit" disabled={isSubmitting}>
                                Send
                            </button>
                        </form>
                    );
                }}
            </Formik>
        </StyledCreateArticle>
    );
});
