import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { IBlogData } from "@interfaces";
import { setLoading, openToast } from "@redux";
import { blogApi } from "@api";
import {
    StyledContainer,
    ComponentTopMenu,
    IconArrowRight,
    ComponentListUserBlog,
    ComponentNavigationBar,
} from "@components";

export const PageListUserBlog = () => {
    //page hooks
    const dispatch = useDispatch();
    const history = useHistory();
    //@ts-ignore
    const { userId } = useParams();

    //State
    const [listBlog, setListBlog] = useState<Array<IBlogData>>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPage, setTotalPage] = useState<number>(1);

    //What call api
    useEffect(() => {
        fetchListBlogCategory(currentPage);
    }, [userId, currentPage]);

    const fetchListBlogCategory = async (page: number) => {
        try {
            if (page === 1) {
                dispatch(setLoading(true));
            }
            const res = await blogApi.getListUserBlog(userId, page);
            //Cache prev page
            setListBlog([...listBlog, ...res?.data?.data?.data]);
            setTotalPage(res?.data?.data?.paging?.last_page);
        } catch (error: any) {
            dispatch(
                openToast({
                    message:
                        error?.response?.data?.message ||
                        error?.response?.message ||
                        error?.message,
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
        } finally {
            if (page === 1) {
                dispatch(setLoading(false));
            }
        }
    };

    const handleGoBack = () => {
        history.goBack();
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    //What call api delete article
    const deleteArticle = async (id: number) => {
        try {
            dispatch(setLoading(true));
            const res = await blogApi.deleteArticle(id);
            dispatch(
                openToast({
                    message: res?.data?.data?.message,
                    type: "success",
                    autoHideDuration: 2000,
                })
            );
            const newListBlog = listBlog.filter((blog: IBlogData) => blog.id !== id);
            setListBlog(newListBlog);
        } catch (error: any) {
            dispatch(
                openToast({
                    message:
                        error?.response?.data?.message ||
                        error?.response?.message ||
                        error?.message,
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleDeleteBlogCard = (id: number) => {
        deleteArticle(id);
    };

    return (
        <>
            <ComponentTopMenu prefixIcon={<IconArrowRight />} onClickPrefixIcon={handleGoBack} />
            <StyledContainer hasNavbar>
                <ComponentListUserBlog
                    hasMore={currentPage < totalPage}
                    listBlog={listBlog}
                    handleNextPage={handleNextPage}
                    handleDeleteBlogCard={handleDeleteBlogCard}
                />
            </StyledContainer>
            <ComponentNavigationBar />
        </>
    );
};
