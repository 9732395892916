import { Button } from "antd";
import { CSSProperties } from "styled-components";

import { StyledButton, StyleButtonSub } from "@components";

interface IProps {
    type: "default" | "ghost" | "primary" | "dashed" | "danger" | undefined;
    size: "default" | "small" | "large" | undefined;
    text?: string;
    className?: string;
    style?: CSSProperties | undefined;
    htmlType?: "button" | "reset" | "submit" | undefined;
    disable?: boolean;
    onClick?: () => void;
    icon?: any;
    styleContainer?: CSSProperties | undefined;
    children?: React.ReactNode;
}

export const ShareButton = (props: IProps) => {
    return (
        <StyledButton className="ButtonContainer" size={props.size || ""} style={{ ...props.styleContainer }}>
            <Button
                htmlType={props.htmlType}
                type={props.type}
                size={props.size}
                className={props.className}
                style={{ height: "auto", ...props.style }}
                disabled={props.disable}
                onClick={props.onClick}
                icon={props.icon}
            >
                {props.text}
                {props.children}
            </Button>
        </StyledButton>
    );
};

interface IPropsSub {
    size?: "default" | "small" | "large" | undefined;
    type: "default" | "sub" | "textMain" | "disable" | undefined;
    text: string | null;
    className?: string;
    style?: CSSProperties | undefined;
    htmlType?: "button" | "reset" | "submit" | undefined;
    onClick?: () => void;
}

export const ShareButtonSub = (props: IPropsSub) => {
    const { text, size, type, className, style, htmlType, onClick } = props;
    return (
        <StyleButtonSub disabled={type === "disable" ? true : false} size={size} type={htmlType} typeBtn={type} className={className} style={style} onClick={onClick}>
            {text}
        </StyleButtonSub>
    );
};
