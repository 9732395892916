import { ShareButton, ShareInput, ShareSelect } from "@components";
import { Formik } from "formik";
import React from "react";
import { StyledEditProfile } from "./styledEditProfile";
import Delete from "../../assets/icon/auth/delete.svg";
import { useTranslation } from "react-i18next";
import { selectDataDefault } from "@redux";
import { useSelector } from "react-redux";
import _ from "lodash";
import { AgeConst, Height } from "@configs";
import TextArea from "antd/es/input/TextArea";

interface IEditProfileComponent {
    initialValue: any;
    imagesDefault: { id: number; url: string }[];
    listAvatar: any;
    handleImageChange: (e: any) => void;
    handleDeleteAvt: (item: any) => void;
    handleDeleteImageDefault: (id: number) => void;
    onSubmit: (value: any) => void;
    profileSchema: any;
}

export const EditProfileComponent = (props: IEditProfileComponent) => {
    const {
        initialValue,
        imagesDefault,
        listAvatar,
        handleImageChange,
        handleDeleteAvt,
        handleDeleteImageDefault,
        onSubmit,
        profileSchema,
    } = props;
    const { t } = useTranslation();

    const dataDefault = useSelector(selectDataDefault);

    const convertList = (dataObject: any) => {
        const listOptions: any = [];
        _.forOwn(dataObject, (value, key) =>
            listOptions.push({
                label: value,
                value: key,
            })
        );
        return listOptions;
    };

    return (
        <StyledEditProfile>
            <p className="title">プロフィール編集</p>
            <Formik
                initialValues={initialValue}
                enableReinitialize
                onSubmit={(value: any) => onSubmit(value)}
                validationSchema={profileSchema}
            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    /* and other goodies */
                }) => (
                    <div>
                        <div className="form-step1">
                            <ShareSelect
                                name="location"
                                style={{ width: "100%" }}
                                placeholder={"- すべて -"}
                                label={"都道府県"}
                                selectOptions={convertList(dataDefault?.prefectures || []).reverse()}
                                onChange={setFieldValue}
                                value={values.location}
                            />
                            <ShareInput
                                name="name"
                                style={{ width: "100%" }}
                                label={"名前"}
                                onChange={handleChange}
                                value={values.name}
                                onBlur={handleBlur}
                            />
                            <label htmlFor={"textarea"} className={"label"}>自己紹介文</label>
                            <TextArea
                                name="profile"
                                style={{ width: "100%" }}
                                //label={"自己紹介文"}
                                id="textarea"
                                onChange={handleChange}
                                value={values.profile}
                                onBlur={handleBlur}
                            />
                            <div className="avatar-label">{t("page.account.avatar")}</div>
                            <div className="avatar-container">
                                {imagesDefault &&
                                    imagesDefault?.length > 0 &&
                                    imagesDefault.map(
                                        (item: { id: number; url: string }, index: number) => (
                                            <div className="img-container" key={index}>
                                                <img
                                                    className="delete"
                                                    src={Delete}
                                                    onClick={() =>
                                                        handleDeleteImageDefault(item?.id)
                                                    }
                                                />
                                                <img className="avatar-img" src={item?.url} />
                                            </div>
                                        )
                                    )}
                                {listAvatar &&
                                    listAvatar.length > 0 &&
                                    listAvatar.map((image: any, index: number) => (
                                        <div className="img-container" key={index}>
                                            <img
                                                className="delete"
                                                src={Delete}
                                                onClick={() => handleDeleteAvt(image)}
                                            />
                                            <img
                                                className="avatar-img"
                                                src={URL.createObjectURL(image)}
                                            />
                                        </div>
                                    ))}
                            </div>

                            <label className="file-upload">
                                <input
                                    type="file"
                                    multiple="multiple"
                                    accept="image/png, image/gif, image/jpeg"
                                    name="avatar"
                                    onChange={(e: any) => handleImageChange(e)}
                                />
                                <div className="add-avatar">{t("page.auth.add_avatar")}</div>
                            </label>
                            <div className="bottom-group">
                                <ShareSelect
                                    name="age"
                                    style={{ width: "47%" }}
                                    placeholder={"- すべて -"}
                                    label={"年齢"}
                                    selectOptions={AgeConst || []}
                                    onChange={setFieldValue}
                                    value={values.age}
                                />
                                <ShareSelect
                                    name="height"
                                    style={{ width: "47%" }}
                                    label={"身長"}
                                    placeholder={"- すべて -"}
                                    value={values.height}
                                    selectOptions={Height}
                                    onChange={setFieldValue}
                                />
                            </div>
                            <div className="bottom-group">
                                <ShareSelect
                                    name="appearance"
                                    style={{ width: "47%" }}
                                    placeholder={"- すべて -"}
                                    label={"ボディタイプ"}
                                    selectOptions={convertList(dataDefault?.appearances || [])}
                                    onChange={setFieldValue}
                                    value={values.appearance}
                                />
                                <ShareSelect
                                    name="mariage"
                                    style={{ width: "47%" }}
                                    label={"婚姻歴"}
                                    placeholder={"- すべて -"}
                                    value={values.mariage}
                                    selectOptions={convertList(dataDefault?.mariages || [])}
                                    onChange={setFieldValue}
                                />
                            </div>
                        </div>
                        <p className="title option2">オプションのフィールド</p>
                        <div className="form-step2 ">
                            <div className="bottom-group">
                                <ShareSelect
                                    name="blood"
                                    style={{ width: "47%" }}
                                    placeholder={"- すべて -"}
                                    label={"血液型"}
                                    selectOptions={convertList(dataDefault?.bloodgroups || [])}
                                    onChange={setFieldValue}
                                    value={values.blood}
                                />
                                <ShareSelect
                                    name="language"
                                    style={{ width: "47%" }}
                                    label={"言語"}
                                    placeholder={"- すべて -"}
                                    value={values.language}
                                    selectOptions={convertList(dataDefault?.languages || [])}
                                    onChange={setFieldValue}
                                />
                            </div>
                            <ShareSelect
                                name="job"
                                style={{ width: "100%" }}
                                label={"職業"}
                                placeholder={"- すべて -"}
                                value={values.job}
                                selectOptions={convertList(dataDefault?.jobs || [])}
                                onChange={setFieldValue}
                            />
                            <ShareSelect
                                name="salary"
                                style={{ width: "100%" }}
                                label={"給料"}
                                placeholder={"- すべて -"}
                                value={values.salary}
                                selectOptions={convertList(dataDefault?.salaries || [])}
                                onChange={setFieldValue}
                            />
                            <ShareSelect
                                name="lookingFor"
                                style={{ width: "100%" }}
                                label={"求めている関係"}
                                placeholder={"- すべて -"}
                                value={values.lookingFor}
                                selectOptions={convertList(dataDefault?.looking_for || [])}
                                onChange={setFieldValue}
                            />
                            <ShareInput
                                name="hobby"
                                style={{ width: "100%" }}
                                label={"趣味"}
                                onChange={handleChange}
                                value={values.hobby}
                                onBlur={handleBlur}
                            />
                            <ShareSelect
                                name="willSee"
                                style={{ width: "100%" }}
                                label={"出会うまでの希望"}
                                placeholder={"- すべて -"}
                                value={values.willSee}
                                selectOptions={convertList(dataDefault?.hope_meet || [])}
                                onChange={setFieldValue}
                            />
                            <ShareSelect
                                name="firstFee"
                                style={{ width: "100%" }}
                                label={"初回デート費用"}
                                placeholder={"- すべて -"}
                                value={values.firstFee}
                                selectOptions={convertList(dataDefault?.date_cost || [])}
                                onChange={setFieldValue}
                            />
                            <ShareSelect
                                name="hasChildren"
                                style={{ width: "100%" }}
                                label={"子供の有無"}
                                placeholder={"- すべて -"}
                                value={values.hasChildren}
                                selectOptions={convertList(dataDefault?.with_child || [])}
                                onChange={setFieldValue}
                            />
                            <ShareSelect
                                name="readyMarried"
                                style={{ width: "100%" }}
                                label={"結婚に対する意思"}
                                placeholder={"- すべて -"}
                                value={values.readyMarried}
                                selectOptions={convertList(dataDefault?.willing_marriage || [])}
                                onChange={setFieldValue}
                            />
                            <div className="bottom-group">
                                <ShareSelect
                                    name="drink"
                                    style={{ width: "47%" }}
                                    placeholder={"- すべて -"}
                                    label={"アルコール"}
                                    selectOptions={convertList(dataDefault?.drinker || [])}
                                    onChange={setFieldValue}
                                    value={values.drink}
                                />
                                <ShareSelect
                                    name="smoke"
                                    style={{ width: "47%" }}
                                    label={"タバコ"}
                                    placeholder={"- すべて -"}
                                    value={values.smoke}
                                    selectOptions={convertList(dataDefault?.smoker || [])}
                                    onChange={setFieldValue}
                                />
                            </div>
                            <ShareButton
                                className="submit"
                                type="primary"
                                size="large"
                                text={"確認"}
                                disable={isSubmitting}
                                htmlType="submit"
                                onClick={handleSubmit}
                            />
                        </div>
                    </div>
                )}
            </Formik>
        </StyledEditProfile>
    );
};
