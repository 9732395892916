import styled from "styled-components";

import { theme } from "@utils";

interface IProps {
    active_blog: boolean;
}

export const StyledListBlog = styled.div<IProps>`
    font-family: ${theme.family.fontKozuka};
    height: 100%;
    padding-bottom: ${(props) => (props.active_blog ? "5.563rem" : "1.125rem")};
    .list_blog_button__wrapper {
        padding: 1rem;
        margin-top: 4.375rem;
        padding-top: 0;
        top: 0;
        left: 0;
        width: 100%;
        position: fixed;
        z-index: 99;
        .list_blog__button {
            background-color: ${theme.colors.secondary};
            border: none;
            color: ${theme.colors.black};
            font-weight: 500;
            font-size: 0.938rem;
            line-height: 1.875rem;
            font-family: ${theme.family.fontKozuka};
        }
    }
    .content__wrapper {
        width: 100vw;
        height: 100%;
        margin-top: ${(props) => (props.active_blog ? "5.5rem" : "1.125rem")};
        padding-left: 1rem;
        overflow-y: auto;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .list_blog__title {
        font-weight: 700;
        font-size: 1.063rem;
        line-height: 1rem;
        color: ${theme.colors.highlight};
        margin-bottom: 1rem;
    }
    .category {
        margin-top: 0.875rem;
        margin-bottom: 1.813rem;
        .list_category {
            display: flex;
            width: 100%;
            overflow-x: auto;
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
    .blog_recommend {
        margin-bottom: 1.813rem;
        .swiper-slide {
            box-sizing: border-box;
        }
        .button-prev {
            display: none;
        }
        .button-next {
            display: none;
        }
    }
    .blog_category {
        padding-right: 1rem;
        margin-bottom: 1.813rem;
        text-align: center;
        .blog_category__name {
            margin-bottom: 0.938rem;
            font-weight: 700;
            font-size: 1.063rem;
            line-height: 1rem;
            color: ${theme.colors.black};
            text-align: left;
        }
        .show_all {
            text-decoration: none;
            font-size: 0.875rem;
            color: ${theme.colors.primary};
            display: inline-flex;
            align-items: center;
            &:hover {
                opacity: 0.4;
            }
            .right_arrow {
                margin-left: 3px;
            }
        }
    }

    @media (min-width: 0px) {
        .category,
        .blog_recommend {
            padding-right: 1rem;
        }
    }

    @media (min-width: ${theme.breakPoints.breakIpadPro}) {
        .list_blog_button__wrapper {
            max-width: 66.667rem;
            left: 50%;
            transform: translateX(-50%);
        }
        .content__wrapper {
            max-width: 66.667rem;
            padding-right: 1rem;
        }
        .blog_recommend {
            padding-right: 0;
        }
        .category {
            padding-right: 0;
            .list_category {
                padding-bottom: 0.5rem;
                &::-webkit-scrollbar {
                    display: block;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #b8e5ea;
                }
            }
        }
        .blog_category {
            padding-right: 0;
            .blog_category__list_blog {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 1.813rem;
            }
            .show_all {
                margin-top: 1rem;
            }
        }
        .blog_recommend {
            .list_blog_recommend {
                width: calc(100% - 4rem);
                margin: auto;
                position: relative;
            }
            .button-prev {
                position: absolute;
                left: -2rem;
                top: 50%;
                transform: translateY(-50%);
                display: flex;
                font-size: 2rem;
                color: ${theme.colors.primary};
                cursor: pointer;
            }
            .button-next {
                position: absolute;
                display: flex;
                right: -2rem;
                top: 50%;
                transform: translateY(-50%);
                font-size: 2rem;
                color: ${theme.colors.primary};
                cursor: pointer;
            }
            .swiper-button-disabled {
                opacity: 0.2;
                cursor: default;
            }
        }
    }
`;
