import { Button } from "antd";
import styled from "styled-components";
import { CSSProperties } from "styled-components";

interface IProps {
    text: string;
    type?: "danger" | "default" | "primary" | "ghost" | "dashed";
    size?: "small" | "default" | "large";
    className?: string;
    style?: CSSProperties;
    disable?: boolean;
    onClick?: () => void;
    icon?: string;
}

export const CommonButton = (props: IProps) => {
    const { text, type, size, className, style, disable, onClick, icon } = props;
    return (
        <StyledContainer>
            <Button
                style={style}
                className={className}
                type={type || "default"}
                size={size || "default"}
                disabled={disable}
                onClick={onClick}
                icon={icon}
            >
                {text}
            </Button>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    button {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
