import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";

import {
    ComponentImage,
    ComponentInfiniteScrollLoader,
    ComponentNavigationBar,
    ComponentTopMenu,
    ComponentUserPreviewCard,
    StyledContainer,
    StyledDaftTop
} from "@components";
import { authApi, blogApi, daftTopApi } from "@api";
import { openToast, selectApp, setDataDefault, setLoading } from "@redux";
import { IBlogData, IInfo } from "@interfaces";
import { PATH_ARTICLE_DETAIL } from "@configs";

export const PageHome = () => {
    //page Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //State
    const [listUser, setListUser] = useState<Array<IInfo>>([]);
    const [listHighlightBlog, setListHighlightBlog] = useState<Array<IBlogData>>([]);
    const [currentPageUser, setCurrentPageUser] = useState<number>(1);
    const [totalPageUser, setTotalPageUser] = useState<number>(1);
    //page Hooks
    const auth: any | null = useSelector((state: any) => state?.auth?.tokenInfoAuth);

    //reduxState
    const listPrefecture = useSelector(selectApp).dataDefault?.prefectures;

    //What call api list category
    useEffect(() => {
        if (auth) {
            fetchListHighlightBlog();
            fetchData();
        }
        return () => {
            dispatch(setLoading(false));
        };
    }, [auth]);

    //What call api daft top
    useEffect(() => {
        if (auth) {
            fetchListUser(currentPageUser);
        }
    }, [currentPageUser, auth, listPrefecture]);

    useEffect(() => {
        const a = document.getElementsByClassName("list_user");
        a[0].children[0].setAttribute('style',"position:absolute");
    }, [listUser]);


    const fetchData = async () => {
        const res = await authApi.getComboUser();
        dispatch(setDataDefault(res?.data?.data?.default_data));
    };

    const fetchListUser = async (page: number) => {
        if (listPrefecture) {
            try {
                if (page === 1) {
                    dispatch(setLoading(true));
                }
                const res = await daftTopApi.getListUser(page);
                const data = res?.data?.data?.data;
                //What
                //map prefecture id to name
                const newListUser = data.map((user: IInfo) => ({
                    ...user,
                    prefecture: listPrefecture[user.prefecture],
                }));
                //Cache the prev list user
                setListUser([...listUser, ...newListUser]);
                setTotalPageUser(res?.data?.data?.paging?.last_page);
            } catch (error: any) {
                dispatch(
                    openToast({
                        message: error?.response?.data?.message || error?.response?.message || error?.message,
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    const fetchListHighlightBlog = async (): Promise<void> => {
        try {
            const res = await blogApi.getListHighlightBlog();
            setListHighlightBlog(res?.data?.data?.data);
        } catch (error: any) {
            dispatch(
                openToast({
                    message: error?.response?.data?.message || error?.response?.message || error?.message,
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
        } finally {
            // dispatch(setLoading(false));
        }
    };

    const handleNextPage = () => {
        setCurrentPageUser(currentPageUser + 1);
    };

    const handleRefresh = () => {
        setCurrentPageUser(1);
        fetchListUser(currentPageUser);
    }
    return (
        <>
            <ComponentTopMenu />
            <StyledContainer hasNavbar>
                <StyledDaftTop id="daft_top_container" >
                    <div className="highlight_blog__wrapper">
                        <div className="title">{t("page.daft_top.categories")}</div>
                        <div className="list_highlight_blog" >
                            {listHighlightBlog?.map((highlightBlog: IBlogData) => (
                                <Link to={`${PATH_ARTICLE_DETAIL}/${highlightBlog?.id}`} className="highlight_blog_item" key={highlightBlog.id}>
                                    <ComponentImage className="highlight_blog_image" src={highlightBlog.image} alt="Category" />
                                    <div className="highlight_blog_text__wrapper" >
                                        <div className="highlight_blog_name">{highlightBlog?.user?.username}</div>
                                        <div className="highlight_blog_title">{highlightBlog.title}</div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                    <InfiniteScroll
                        dataLength={listUser?.length}
                        next={handleNextPage}
                        hasMore={currentPageUser < totalPageUser}
                        loader={<ComponentInfiniteScrollLoader className="loader" />}
                        className="list_user"
                        scrollableTarget="daft_top_container"
                        pullDownToRefresh
                        pullDownToRefreshContent={
                            <h3 style={{ textAlign: 'center' }}>&#8595;</h3>
                        }
                        releaseToRefreshContent={
                            <h3 style={{ textAlign: 'center' }}>&#8593;</h3>
                        }
                        pullDownToRefreshThreshold={50}
                        refreshFunction={handleRefresh}
                        hasChildren
                    >
                        {listUser?.map((user: IInfo, index: number) => (
                            <ComponentUserPreviewCard key={index} prefecture={user.prefecture} userAvatarUrl={user.avatar} userName={user.username} userId={user.id} age={user.age} />
                        ))}
                    </InfiniteScroll>
                </StyledDaftTop>
            </StyledContainer>
            <ComponentNavigationBar />
        </>
    );
};
