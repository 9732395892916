import { theme } from "@utils";
import React from "react";
import styled from "styled-components";
import BackIcon from "../../assets/icon/auth/backIcon.svg";

interface IHeaderSignUp {
    title?: string;
    noteTitle?: string;
    titleHeader?: string;
    showIcon?: boolean;
    onBack?: () => void;
    onSkip?: () => void;
}

export const HeaderSignUp = (props: IHeaderSignUp) => {
    const { title, noteTitle, titleHeader, showIcon, onBack, onSkip } = props;
    return (
        <StyleHeaderSignUp>
            <div className="headerTop" onClick={onBack}>
                {showIcon && <img style={{ cursor: "pointer" }} src={BackIcon} />}
                <p className="titleHeader" style={{ cursor: "pointer" }} onClick={onSkip}>
                    {titleHeader}
                </p>
            </div>
            <div>
                {noteTitle && <p className="titleNote">{noteTitle}</p>}
                {title && <p className="title">{title}</p>}
            </div>
        </StyleHeaderSignUp>
    );
};

const StyleHeaderSignUp = styled.div`
    min-height: 9rem;
    background-color: ${theme.colors.primary};
    width: 100%;
    padding: 1.15rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    p {
        margin-bottom: 0;
    }
    .headerTop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .titleHeader {
            font-size: 1rem;
            font-weight: 700;
            color: ${theme.colors.textHeader};
        }
    }
    .titleNote {
        font-size: 1.2rem;
        font-weight: 700;
        color: ${theme.colors.secondary};
    }
    .title {
        font-size: 1.8rem;
        font-weight: 700;
        color: ${theme.colors.white};
        margin-top: 0.6rem;
    }
    @media (min-width: ${(p) => p.theme.breakPoints.breakIpadPro}) {
        width: 600px;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
`;
