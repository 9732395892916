import styled from "styled-components";
import React from "react";

export const ModalFavorite = (props: any) => {
    const { onClose, data = [], onPressReport } = props;
    return (
        <StyledContainer>
            <div className="viewContainer">
                <div className="back_drop" onClick={onClose} />
                <div className="viewModal">
                    <div className="itemModalTop">
                        <p style={{ margin: 0 }}>通報する</p>
                        <p style={{ margin: 0 }}>相手に知らせません</p>
                    </div>
                    <div className="itemModalBottom">
                        {data?.map((item: any, index: number) => {
                            return (
                                <div
                                    key={index}
                                    className="itemModal"
                                    onClick={() => onPressReport(item)}
                                >
                                    <p style={{ margin: 0 }}>{item}</p>
                                </div>
                            );
                        })}
                    </div>
                    <div className="buttonClose" onClick={onClose}>
                        <p style={{ margin: 0 }}>キャンセル</p>
                    </div>
                </div>
            </div>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    .viewContainer {
        width: 100vw;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.54);
        padding-bottom: 20px;
        z-index: 99;
        position: fixed;
        top: 0;
        left: 0;
    }
    .back_drop {
        width: 100%;
        height: 100%;
    }
    .viewModal {
        width: 35%;
        border-radius: 4px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .itemModalTop {
        display: flex;
        flex-direction: column;
        padding-top: 17px;
        padding-bottom: 17px;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #e5e5e5;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        background-color: white;
    }
    .itemModal {
        display: flex;
        flex-direction: column;
        padding-top: 17px;
        padding-bottom: 17px;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #e5e5e5;
        cursor: pointer;
        background-color: white;
    }
    .itemModalBottom {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        overflow: hidden;
    }
    .buttonClose {
        display: flex;
        width: 100%;
        background: white;
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        margin-top: 0.563rem;
        padding-top: 17px;
        padding-bottom: 17px;
        cursor: pointer;
    }

    //mobile
    @media only screen and (max-width: 46.1875em) {
        .viewModal {
            width: 90%;
        }
        .viewContainer {
            justify-content: flex-end;
        }
    }

    //tablet
    @media only screen and (min-width: 46.25em) and (max-width: 62.9375em) {
        .viewModal {
            width: 90%;
        }
        .viewContainer {
            justify-content: flex-end;
        }
    }
`;
