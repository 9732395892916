import { theme } from "@utils";
import { Input } from "antd";
import React, { forwardRef } from "react";
import styled from "styled-components";

const { TextArea } = Input;
import { IconCamera, IconSendChat } from "../Icon";

interface IFooterChat {
    onChangeText: (value: string) => void;
    onSend: () => void;
    value: string;

    onChoseFile: (e: any) => void;
}

export const FooterChat = forwardRef((props: IFooterChat, ref: any) => {
    const { onChangeText, value, onSend, onChoseFile } = props;

    return (
        <StyledFooterChat>
            <div className="content__wrapper">
                <label className="btn-file">
                    <IconCamera />
                    <input
                        ref={ref}
                        type="file"
                        className="input"
                        onChange={(e) => onChoseFile(e.target.files)}
                    />
                </label>
                <div className="input-box">
                    <TextArea
                        className="input"
                        autoSize={{ minRows: 1, maxRows: 4 }}
                        onChange={(e) => {
                            onChangeText(e?.target?.value);
                        }}
                        value={value}
                        placeholder="Type a message..."
                    />
                    <div onClick={onSend}>
                        <IconSendChat />
                    </div>
                </div>
            </div>
        </StyledFooterChat>
    );
});

export const StyledFooterChat = styled.div`
    background-color: ${theme.colors.primary};
    overflow: hidden;
    width: 100%;
    .content__wrapper {
        padding: 0.5rem 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .btn-file {
        margin-right: 1rem;
        position: relative;
        cursor: pointer;

        & > .input {
            position: absolute;
            display: none;
            left: 0;
            z-index: 1;
            right: 0;
        }
    }

    .input-box {
        display: flex;
        width: 100%;
        align-items: center;
        background-color: ${theme.colors.white};
        justify-content: space-between;
        padding: 4px 10px;
        border-radius: 8px;
        .input {
            margin-right: 1rem;
            border: none;
            padding-left: 0;
            color: ${theme.colors.black};
        }
    }

    @media (min-width: ${theme.breakPoints.breakIpadPro}) {
        .content__wrapper {
            max-width: 66.667rem;
            margin: auto;
        }
    }
`;
