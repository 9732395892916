import { FormikHelpers } from "formik";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";

import { blogApi } from "@api";
import { openToast, setLoading } from "@redux";
import { ICreateNewArticle, ICategoryOption, IBlogData } from "@interfaces";
import {
    ComponentCreateArticle,
    ComponentTopMenu,
    StyledContainer,
    IconSend,
    IconArrowRight,
} from "@components";
import { PATH_BLOG } from "@configs";

export const PageCreateArticle = () => {
    //page Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    //@ts-ignore
    const { id } = useParams();
    //State
    const [listCategoryForm, setListCategoryForm] = useState<Array<ICategoryOption>>([]);
    const [formData, setFormData] = useState<ICreateNewArticle>({
        title: "",
        category_id: null,
        image: null,
        body: "",
    });
    const [deleteImage, setDeleteImage] = useState<string | null>(null);

    useEffect(() => {
        fetchListCategoryForm();
    }, []);

    useEffect(() => {
        fetchArticleDetail();
    }, [id]);

    const fetchArticleDetail = async () => {
        if (id) {
            try {
                dispatch(setLoading(true));
                const res = await blogApi.getArticleDetail(id);
                const articleData: IBlogData = res?.data?.data;
                setFormData({
                    title: articleData?.title,
                    category_id: articleData?.category_id,
                    image: articleData?.image,
                    body: articleData?.content,
                });
            } catch (error: any) {
                dispatch(
                    openToast({
                        message:
                            error?.response?.data?.message ||
                            error?.response?.message ||
                            error?.message,
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    const fetchListCategoryForm = async () => {
        try {
            dispatch(setLoading(true));
            const res = await blogApi.getListCategoryForm();
            const resCategories = res?.data?.data?.default_data?.category;
            const arrayCategories: Array<ICategoryOption> = [];
            for (const category in resCategories) {
                arrayCategories.push({ value: category, label: resCategories[category] });
            }
            setListCategoryForm(arrayCategories);
        } catch (error: any) {
            dispatch(
                openToast({
                    message:
                        error?.response?.data?.message ||
                        error?.response?.message ||
                        error?.message,
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
        } finally {
            dispatch(setLoading(false));
        }
    };

    const createArticleSchema = Yup.object().shape({
        title: Yup.string().required(t("validation.field_required")),
        category_id: Yup.string().nullable().required(t("validation.field_required")),
    });

    const handleUpdateArticle = async (
        values: ICreateNewArticle,
        { setSubmitting }: FormikHelpers<ICreateNewArticle>
    ) => {
        try {
            //WHAT: CALL API forgot password
            dispatch(setLoading(true));
            const data = new FormData();
            data.append("title", values.title);
            data.append("content", values.body);
            !!values.category_id && data.append("category_id", values.category_id);
            !!values.image && data.append("image[]", values.image);
            !!deleteImage && data.append("delete_image[]", deleteImage);
            const res = await blogApi.updateArticle(id, data);
            dispatch(
                openToast({
                    message: res?.data?.data?.message,
                    type: "success",
                    autoHideDuration: 2000,
                })
            );
            setDeleteImage(null);
            history.push(PATH_BLOG);
        } catch (error: any) {
            dispatch(
                openToast({
                    message:
                        error?.response?.data?.message ||
                        error?.response?.message ||
                        error?.message,
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
        } finally {
            setSubmitting(false);
            dispatch(setLoading(false));
        }
    };

    const handleCreateArticle = async (
        values: ICreateNewArticle,
        { setSubmitting }: FormikHelpers<ICreateNewArticle>
    ) => {
        try {
            //WHAT: CALL API forgot password
            dispatch(setLoading(true));
            const data = new FormData();
            data.append("title", values.title);
            data.append("content", values.body);
            !!values.category_id && data.append("category_id", values.category_id);
            !!values.image && data.append("image[]", values.image);
            const res = await blogApi.createArticle(data);
            dispatch(
                openToast({
                    message: res?.data?.data?.message,
                    type: "success",
                    autoHideDuration: 2000,
                })
            );
            history.push(PATH_BLOG);
        } catch (error: any) {
            console.log("catch")
            dispatch(
                openToast({
                    message:
                        error?.response?.data?.message ||
                        error?.response?.message ||
                        error?.message,
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
        } finally {
            setSubmitting(false);
            dispatch(setLoading(false));
        }
    };

    //editor upload image
    const editorUploadImage = (file: any) => {
        return new Promise((resolve, reject) => {
            const data = new FormData();
            data.append("image", file);
            blogApi
                .uploadImage(data)
                .then((res) => {
                    resolve({
                        data: {
                            link: res?.data?.data?.image_url,
                        },
                    });
                })
                .catch((error: any) => {
                    reject(error?.response?.data?.message);
                });
        });
    };

    const buttonSend = useRef<HTMLButtonElement>(null);

    const handleClickSend = () => {
        buttonSend?.current?.click();
    };

    const handleGoBack = () => {
        history.goBack();
    };

    const handleDeleteImage = () => {
        //get current image id
        const SEARCH_TERM = "file=storage/";
        const startIdIndex = formData.image.indexOf(SEARCH_TERM) + SEARCH_TERM.length;
        const lastIdIndex = formData.image.indexOf("/", startIdIndex);
        const imageId = formData.image.slice(startIdIndex, lastIdIndex);
        setDeleteImage(imageId);
        setFormData({ ...formData, image: null });
    };

    return (
        <>
            <ComponentTopMenu
                prefixIcon={<IconArrowRight />}
                onClickPrefixIcon={handleGoBack}
                suffixIcon={<IconSend />}
                onClickSuffixIcon={handleClickSend}
            />
            <StyledContainer hasNavbar={false}>
                <ComponentCreateArticle
                    formData={formData}
                    onSubmit={id ? handleUpdateArticle : handleCreateArticle}
                    categoryOption={listCategoryForm}
                    formValidationSchema={createArticleSchema}
                    editorUploadImage={editorUploadImage}
                    handleDeleteImage={handleDeleteImage}
                    ref={buttonSend}
                />
            </StyledContainer>
        </>
    );
};
