import React from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { ChangePassWordComponent, ComponentTopMenu, IconArrowRight } from "@components";
import { authApi } from "@api";
import { useDispatch } from "react-redux";
import { openToast, setInfo, setLoading } from "@redux";

export const ChangePassWord = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSubmit = async (values: any, resetForm) => {
        try {
            dispatch(setLoading(true));

            const param = {
                current_password: values.currentPass,
                password: values.password,
                password_confirmation: values.confirmPassword,
            };
            const res = await authApi.changePassword(param);
            const { success, data } = res?.data;

            if (success) {
                dispatch(setInfo(data?.profile));
                dispatch(
                    openToast({
                        //@ts-ignore
                        message: data.message,
                        type: "success",
                        autoHideDuration: 1000,
                    })
                );
                resetForm({
                    currentPass: "",
                    password: "",
                    confirmPassword: "",
                });
                handleGoBack();
            } else {
                dispatch(
                    openToast({
                        message: data.message,
                        type: "error",
                        autoHideDuration: 1000,
                    })
                );
            }
        } catch (error: any) {
            dispatch(
                openToast({
                    message: error.response.data.message,
                    type: "error",
                    autoHideDuration: 1000,
                })
            );
        } finally {
            dispatch(setLoading(false));
        }
    };
    const instalValue = {
        currentPass: "",
        password: "",
        confirmPassword: "",
    };
    const validate = Yup.object().shape({
        currentPass: Yup.string()
            .nullable()
            .required(t("validation.field_required"))
            .min(
                6,
                t("validation.min", {
                    returnObjects: true,
                    name: t("page.auth.password"),
                    number: 6,
                })
            )
            .max(32, t("validation.max_length_password")),
        password: Yup.string()
            .nullable()
            .required(t("validation.field_required"))
            .min(
                6,
                t("validation.min", {
                    returnObjects: true,
                    name: t("page.auth.password"),
                    number: 6,
                })
            )
            .max(32, t("validation.max_length_password")),
        confirmPassword: Yup.string()
            .nullable()
            .required(t("validation.field_required"))
            .oneOf([Yup.ref("password"), null], "パスワードが一致する必要があります"),
    });

    const handleGoBack = () => {
        history.goBack();
    };

    return (
        <>
            <ComponentTopMenu prefixIcon={<IconArrowRight />} onClickPrefixIcon={handleGoBack} />
            <ChangePassWordComponent
                initialValues={instalValue}
                onSubmit={handleSubmit}
                validate={validate}
            />
        </>
    );
};
