import InfiniteScroll from "react-infinite-scroll-component";

import { StyledListUserBlog, ComponentBlogCard, ComponentInfiniteScrollLoader } from "@components";
import { IBlogData } from "@interfaces";

interface IProps {
    listBlog: Array<IBlogData>;
    hasMore: boolean;
    handleNextPage(): void;
    handleDeleteBlogCard?(id: number): void;
}

export const ComponentListUserBlog = (props: IProps) => {
    //Props
    const { listBlog, hasMore, handleNextPage, handleDeleteBlogCard } = props;

    return (
        <StyledListUserBlog id="scroll_view">
            <InfiniteScroll
                dataLength={listBlog?.length}
                hasMore={hasMore}
                loader={<ComponentInfiniteScrollLoader className="loader" />}
                next={handleNextPage}
                className="list_blog"
                scrollableTarget="scroll_view"
            >
                {listBlog?.map((blog: IBlogData) => (
                    <ComponentBlogCard
                        handleDelete={handleDeleteBlogCard}
                        blogCard={blog}
                        key={blog.id}
                    />
                ))}
            </InfiniteScroll>
        </StyledListUserBlog>
    );
};
