import styled from "styled-components";

import { theme } from "@utils";

export const StyledDaftTop = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    .list_user {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.313rem;
        padding: 0.625rem;
        padding-top: 5.938rem;
        .loader {
            grid-column-start: 1;
            grid-column-end: 3;
        }
    }
    .highlight_blog__wrapper {
        width: 100%;
        height: 5.313rem;
        overflow: hidden;
        background-image: url("/images/daft_top_category_bg.png");
        background-size: auto 100%;
        padding-left: 1.375rem;
        position: fixed;
        font-family: ${theme.family.fontKozuka};
        text-align: center;
        z-index: 99;
        .title {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 1.375rem;
            padding: 0 0.063rem;
            padding-top: 0.375rem;
            z-index: 10;
            background-color: ${theme.colors.pink};
            font-weight: 700;
            font-size: 0.688rem;
            line-height: 0.75rem;
            color: ${theme.colors.white};
        }
        .list_highlight_blog {
            width: 100%;
            height: 100%;
            display: grid;
            padding: 0 0.625rem;
            grid-template-columns: repeat(5, 1fr);
            grid-column-gap: 0.5rem;
            .highlight_blog_item {
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                padding: 0.375rem 0;
            }

            @media (min-width: ${theme.breakPoints.breakTablet}) {
                display: flex;
                justify-content: center;
                .highlight_blog_item {
                    width: auto;
                    height: auto;
                    margin-right: 0.844rem;
                }
                .highlight_blog_item:last-child {
                    margin-right: 0;
                }
            }
        }
        .highlight_blog_image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
            border-top-left-radius: 100px;
            border-top-right-radius: 100px;
            border-bottom-left-radius: 50px;
            border-bottom-right-radius: 50px;
            border: 1px solid ${theme.colors.primary};
        }
        .highlight_blog_text__wrapper {
            width: 100%;
            background-color: ${theme.colors.pink};
            border-radius: 0.25rem;
            position: absolute;
            bottom: 0.375rem;
            left: 50%;
            transform: translateX(-50%);
            padding: 0.125rem;
            .highlight_blog_name {
                font-weight: 700;
                font-size: 0.544rem;
                color: ${theme.colors.white};
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                margin-bottom: -0.125rem;
            }
            .highlight_blog_title {
                font-weight: 400;
                font-size: 0.481rem;
                color: #5e414f;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
        }
    }

    @media (min-width: ${theme.breakPoints.breakMobilePhone}) {
        .list_user {
            grid-template-columns: repeat(3, 1fr);
            padding: 6.907rem 0 0.6rem;
            .loader {
                grid-column-start: 1;
                grid-column-end: 4;
            }
        }
        .highlight_blog__wrapper {
            height: 6.5rem;
            .highlight_blog_text__wrapper {
                .highlight_blog_name {
                    font-size: 0.744rem;
                }
                .highlight_blog_title {
                    font-size: 0.681rem;
                }
            }
        }
    }
    @media (min-width: ${theme.breakPoints.breakIpadPro}) {
        max-width: 66.667rem;
        .list_user {
            grid-template-columns: repeat(5, 1fr);
            .loader {
                grid-column-start: 1;
                grid-column-end: 6;
            }
        }
        .highlight_blog__wrapper {
            max-width: 66.667rem;
        }
    }
`;
