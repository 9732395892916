import { ErrorMessage } from "formik";
import { Select } from "antd";
import { CSSProperties } from "styled-components";
import styled from "styled-components";

import { IHandleBlur } from "@interfaces";
import { StyledError } from "@components";
import _ from "lodash";

interface IProps {
    label?: string;
    name: string;
    className?: string;
    style?: CSSProperties | undefined;
    selectOptions?: any;
    onChange?: any;
    value?: string | number | null;
    onBlur?: IHandleBlur;
    notFormik?: boolean;
    placeholder?: string;
    err?: string;
    notErr?: boolean;
    disable?: boolean;
}

const { Option } = Select;

export const ShareSelect = (props: IProps) => {
    //page props
    const {
        label,
        className,
        name,
        style,
        value,
        selectOptions,
        onChange,
        onBlur,
        notFormik,
        placeholder,
        err,
        notErr,
        disable,
    } = props;

    return (
        <StyledContainer style={{ ...style }}>
            {label && (
                <label className="label" htmlFor={name}>
                    {label}
                </label>
            )}
            <Select
                defaultActiveFirstOption={false}
                showArrow={true}
                filterOption={false}
                // onSearch={handleSearch}
                notFoundContent={null}
                className={err ? `${className} input-invalid` : className}
                onChange={(value: string | number) => onChange(name, value)}
                value={value?.toString()}
                onBlur={onBlur}
                placeholder={placeholder}
                disabled={disable}
                optionFilterProp="children"
            >
                {_.map(selectOptions, (item) => (
                    <Option key={item.value} value={item.value}>
                        {item.label}
                    </Option>
                ))}
            </Select>

            {!notFormik && <ErrorMessage name={name} component={StyledError} />}

            {!notErr && (
                <StyledError style={err ? { visibility: "visible" } : { visibility: "hidden" }}>
                    {err || "err"}
                </StyledError>
            )}
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    .label {
        font-size: 1.2rem;
        color: #3bb3c1;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: none;
        height: 100%;
        border: none;
        border-bottom: 0.5px solid #928e8d !important;
        border-radius: 0;
        background-color: transparent;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        padding: 0;
        display: flex;
        align-items: center;
    }
    .ant-select-focused .ant-select-selector,
    .ant-select-selector:focus,
    .ant-select-selector:active,
    .ant-select-open .ant-select-selector {
        border-color: #d9d9d9 !important;
        box-shadow: none !important;
    }

    .ant-select {
        height: 45px;
        line-height: 27px;
        color: #000;
        font-weight: 500;
        font-size: 1.2rem !important;

        .ant-select-selection {
            height: 100%;
            border: none;
            border-bottom: 1px solid #787878 !important;
            border-radius: 0;
            background-color: transparent;
            border-color: transparent !important;
            outline: none !important;

            .ant-select-selection__rendered {
                height: 100%;
                align-items: center;
                display: flex;
                margin-left: 0 !important;
            }
        }
        @media (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
            height: 40px;
        }
    }
    .input-invalid {
        input {
            border-width: 1px;
            border-color: ${(p) => p.theme.colors.danger}!important;
            &:focus-within {
                border-color: ${(p) => p.theme.colors.danger}!important;
            }
        }
        .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),
        .ant-input:focus,
        .ant-input:hover,
        .ant-select-selection:hover,
        .ant-select-selection:focus,
        .ant-select-selection:focus-within {
            border-width: 1px;
            border-color: ${(p) => p.theme.colors.danger}!important;
        }
    }
`;
