export const IconCircleError = () => {
    return (
        <svg width="174" height="174" viewBox="0 0 174 174" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="87.4761" cy="87.386" rx="37.7504" ry="50.4972" fill="white" />
            <path
                d="M86.8043 123.25C88.8466 123.25 90.5598 122.554 91.9438 121.162C93.323 119.775 94.0126 118.054 94.0126 116C94.0126 113.946 93.323 112.223 91.9438 110.831C90.5598 109.444 88.8466 108.75 86.8043 108.75C84.7619 108.75 83.0511 109.444 81.6719 110.831C80.2879 112.223 79.5959 113.946 79.5959 116C79.5959 118.054 80.2879 119.775 81.6719 121.162C83.0511 122.554 84.7619 123.25 86.8043 123.25ZM86.8043 159.5C76.8327 159.5 67.4619 157.596 58.6918 153.787C49.9216 149.983 42.2928 144.819 35.8053 138.294C29.3178 131.769 24.1831 124.096 20.4011 115.275C16.6143 106.454 14.7209 97.0292 14.7209 87C14.7209 76.9708 16.6143 67.5458 20.4011 58.725C24.1831 49.9042 29.3178 42.2313 35.8053 35.7063C42.2928 29.1813 49.9216 24.0144 58.6918 20.2057C67.4619 16.4019 76.8327 14.5 86.8043 14.5C96.7758 14.5 106.147 16.4019 114.917 20.2057C123.687 24.0144 131.316 29.1813 137.803 35.7063C144.291 42.2313 149.425 49.9042 153.207 58.725C156.994 67.5458 158.888 76.9708 158.888 87C158.888 97.0292 156.994 106.454 153.207 115.275C149.425 124.096 144.291 131.769 137.803 138.294C131.316 144.819 123.687 149.983 114.917 153.787C106.147 157.596 96.7758 159.5 86.8043 159.5ZM86.8043 94.25C88.8466 94.25 90.5598 93.554 91.9438 92.162C93.323 90.7748 94.0126 89.0542 94.0126 87V58C94.0126 55.9458 93.323 54.2228 91.9438 52.8307C90.5598 51.4436 88.8466 50.75 86.8043 50.75C84.7619 50.75 83.0511 51.4436 81.6719 52.8307C80.2879 54.2228 79.5959 55.9458 79.5959 58V87C79.5959 89.0542 80.2879 90.7748 81.6719 92.162C83.0511 93.554 84.7619 94.25 86.8043 94.25Z"
                fill="#E46060"
            />
        </svg>
    );
};
