import axios from "axios";
const queryString = require("query-string");
import { PATH_UPDATE_PROFILE } from "@configs";

import { store, logout } from "@redux";

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,

    headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        platform: "web",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
        "Access-Control-Allow-Headers": "Content-Type",
    },

    paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.interceptors.request.use((config) => {
    const token = store.getState().auth.tokenInfoAuth;
    config.headers["Authorization"] = token;

    return config;
});

axiosClient.interceptors.response.use(
    (response) => {
        if (response && response.data) {
            return response;
        }
        return response;
    },

    (error: any) => {
        // Handle errors
        if (error.response && error.response.status === 401) {
            //logout
            store.dispatch(logout());
        }
        if (error.response && error.response.status === 404) {
            //Return 404 page
            window.location.replace("/*");
        }
        if (error.response && error.response.data.code === 1001) {
            window.location.replace(PATH_UPDATE_PROFILE);
        }
        throw error;
    }
);

export default axiosClient;
