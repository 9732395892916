import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import socketio from "socket.io-client";
import Echo from "laravel-echo";
import InfiniteScroll from "react-infinite-scroll-component";
import {
    ChatComponent,
    ComponentInfiniteScrollLoader,
    ContentChat,
    FooterChat,
    HeaderChat,
    ItemMessage,
    ModalViewImage,
} from "@components";
import { authApi, ChatService } from "@api";
import { selectAuth, setLoading } from "@redux";
import { useDispatch, useSelector } from "react-redux";
import { PATH_DETAIL_USER } from "@configs";

// const socketId = undefined;
let echo: any;

export const Chat = () => {
    const userInfo = useSelector(selectAuth).userInfo;
    const token = useSelector(selectAuth).tokenInfoAuth;

    //@ts-ignore
    const { idTopic, id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const [friend, setFriend] = useState({});
    const [listChat, setListChat] = useState([]);
    const [page, setPaging] = useState({
        current_page: 1,
        last_page: 0,
        total_page: 0,
    });

    const [text, setText] = useState("");

    const [showViewImage, setShowViewImage] = useState(false);
    const [viewMedia, setViewMedia] = useState({});

    const elementScroll = useRef();

    useEffect(() => {
        getProfile();
        const options = {
            broadcaster: "socket.io",
            host: process.env.REACT_APP_SOCKET_URL,
            client: socketio,
            auth: {
                headers: {
                    Authorization: token,
                },
            },
        };
        echo = new Echo(options);
        innitChat(idTopic);
        return () => {
            echo.private("chat.topic." + idTopic).stopListening("SendPrivateChat");
        };
    }, []);

    useEffect(() => {
        getListChatByTopic();
    }, [page?.current_page]);

    const innitChat = (topicId: string | number) => {
        echo.private("chat.topic." + topicId).listen("SendPrivateChat", (ev: any) => {
            if (ev?.message?.user?.id === userInfo?.id) return;
            //@ts-ignore
            setListChat((prev) => [ev?.message, ...prev]);
        });
    };

    const getProfile = async () => {
        try {
            const res = await authApi.getUserInfo(id);
            setFriend(res?.data?.data);
        } catch (error: any) {}
    };

    const getListChatByTopic = async () => {
        try {
            if (page?.current_page === 1) {
                dispatch(setLoading(true));
            }
            const res = await ChatService.listChatByTopic(idTopic, page?.current_page);
            const { data, paging } = res?.data?.data?.data;
            if (data.length === 0) return;
            //@ts-ignore
            setListChat((prev) => [...prev, ...data]);
            setPaging({
                current_page: paging?.current_page,
                last_page: paging?.last_page,
                total_page: paging?.total,
            });
        } catch (error: any) {
            //
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleBack = () => {
        history.goBack();
    };

    const handleChangeText = (value: string) => {
        setText(value);
    };

    const convertData = (value: string, url?: string, type?: number) => {
        const param = {
            message: value,
            image: url,
            updated_at: new Date(),
            type_media: type,
            user: {
                id: userInfo?.id,
            },
        };
        //@ts-ignore
        setListChat((prev) => [param, ...prev]);
        if (!url) {
            setText("");
        }
    };

    const handleSendMessage = async () => {
        try {
            if (!text.trim()) return;
            convertData(text);
            const message = {
                message: text,
                topic_id: idTopic,
            };
            await ChatService.sendChat(message);
        } catch (error: any) {
            //
        } finally {
            //@ts-ignore
            elementScroll.current.scrollTop = elementScroll.current.scrollHeight;
        }
    };

    const handleNextPage = () => {
        if (page?.current_page >= page?.last_page) return;
        setPaging({
            ...page,
            current_page: page?.current_page + 1,
        });
    };

    const handleChoseFile = async (e) => {
        try {
            if (!e[0]) return;
            if (e[0].type.includes("image")) {
                const image = e[0];

                const res = await ChatService.sendImage(image);
                const { image_url, message } = res.data.data;
                handleSendImg(message, image_url, 1);
                //@ts-ignore
                inputMedia.current.value = "";
            } else {
                const video = e[0];
                const res = await ChatService.sendVideo(video);
                const { image_url, message } = res.data.data;
                handleSendImg(message, image_url, 0);
                //@ts-ignore
                inputMedia.current.value = "";
            }
        } catch (error: any) {}
    };

    const handleSendImg = async (message: string, url: string, type: number) => {
        try {
            convertData(message, url, type);
            const param = {
                message: message,
                image: url,
                topic_id: idTopic,
            };
            await ChatService.sendChat(param);
        } catch (error: any) {
            //
        }
    };

    const handleClose = () => {
        setShowViewImage(false);
    };

    const handleClickImage = (link: string, type: number) => {
        setShowViewImage(true);
        setViewMedia({
            url: link,
            type: type,
        });
    };
    const onClickProfile = () => {
        history.push(`${PATH_DETAIL_USER}/${id}`);
    };

    const inputMedia = useRef(null);

    return (
        <>
            <ChatComponent>
                <HeaderChat friend={friend} onBack={handleBack} onClickProfile={onClickProfile} />
                <ContentChat ref={elementScroll}>
                    <InfiniteScroll
                        dataLength={listChat.length}
                        hasMore={page?.current_page < page?.last_page}
                        next={handleNextPage}
                        className="list_message"
                        loader={<ComponentInfiniteScrollLoader className="loader" />}
                        scrollableTarget="scrollableDiv"
                        style={{ display: "flex", flexDirection: "column-reverse" }}
                        inverse={true}
                    >
                        {listChat &&
                            listChat.length > 0 &&
                            listChat?.map((item: any, index: number) => (
                                <ItemMessage
                                    key={index}
                                    isAuth={userInfo?.id === item?.user?.id}
                                    data={item}
                                    onClickBoxImage={handleClickImage}
                                />
                            ))}
                    </InfiniteScroll>
                </ContentChat>
                <FooterChat
                    ref={inputMedia}
                    onChangeText={handleChangeText}
                    value={text}
                    onSend={handleSendMessage}
                    onChoseFile={handleChoseFile}
                />
            </ChatComponent>
            {showViewImage && <ModalViewImage viewMedia={viewMedia} onClose={handleClose} />}
        </>
    );
};
