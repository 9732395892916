import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import {
    StyledContainer,
    ComponentTopMenu,
    ComponentNavigationBar,
    ComponentBlogSlideCard,
    ComponentBlogCard,
    ComponentBlogCategoryCard,
    StyledListBlog,
    ShareButton,
    IconArrowRight, IconBlog
} from "@components";
import { blogApi } from "@api";
import { openToast, selectAuth, setLoading } from "@redux";
import { IBlogData, ICategoryOption, IBlogsCategory } from "@interfaces";
import { PATH_CREATE_ARTICLE, PATH_BLOG_CATEGORY } from "@configs";

export const PageListBlog = () => {
    //page Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    //State
    const [listCategoryForm, setListCategoryForm] = useState<Array<ICategoryOption>>([]);
    const [listHighlightBlog, setListHighlightBlog] = useState<Array<IBlogData>>([]);
    const [listBlogCategory, setListBlogCategory] = useState<Array<IBlogsCategory>>([]);

    //Redux state
    const active_blog = useSelector(selectAuth).userInfo?.active_blog;

    //call api list category + list highlight blog +  list blog category
    useEffect(() => {
        fetchListCategoryForm();
        fetchListHighlightBlog();
    }, []);

    useEffect(() => {
        listCategoryForm.forEach((category: ICategoryOption) => {
            fetchListBlogCategory(category.value);
        });
    }, [listCategoryForm]);

    const fetchListCategoryForm = async () => {
        try {
            dispatch(setLoading(true));
            const res = await blogApi.getListCategoryForm();
            const resCategories = res?.data?.data?.default_data?.category;
            const arrayCategories: Array<ICategoryOption> = [];
            for (const category in resCategories) {
                arrayCategories.push({ value: category, label: resCategories[category] });
            }
            setListCategoryForm(arrayCategories);
        } catch (error: any) {
            dispatch(
                openToast({
                    message: error?.response?.data?.message || error?.response?.message || error?.message,
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
        } finally {
            dispatch(setLoading(false));
        }
    };

    const fetchListHighlightBlog = async () => {
        try {
            dispatch(setLoading(true));
            const res = await blogApi.getListHighlightBlog();
            setListHighlightBlog(res?.data?.data?.data);
        } catch (error: any) {
            dispatch(
                openToast({
                    message: error?.response?.data?.message || error?.response?.message || error?.message,
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
        } finally {
            dispatch(setLoading(false));
        }
    };

    const fetchListBlogCategory = async (idCategory: string) => {
        try {
            dispatch(setLoading(true));
            const res = await blogApi.getListBlogCategory(idCategory, 1);
            //Get two blog to display by category
            setListBlogCategory((prev) => {
                //remove current list blog category
                const filterList = prev.filter((blogCategory: IBlogsCategory) => blogCategory.category_id !== idCategory);
                const newListBlog = [...filterList, { category_id: idCategory, listBlog: res?.data?.data?.blog?.data.splice(0, 2) }];
                newListBlog.sort((a: IBlogsCategory, b: IBlogsCategory) => parseInt(a.category_id) - parseInt(b.category_id));
                return newListBlog;
            });
        } catch (error: any) {
            dispatch(
                openToast({
                    message: error?.response?.data?.message || error?.response?.message || error?.message,
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleClickNewArticle = () => {
        history.push(PATH_CREATE_ARTICLE);
    };

    const handleGoBack = () => {
        history.goBack();
    };

    const handleDeleteArticle = (id: number, categoryId: string) => {
        deleteArticle(id, categoryId);
    };

    //What call api delete article
    const deleteArticle = async (id: number, categoryId: string) => {
        try {
            dispatch(setLoading(true));
            const res = await blogApi.deleteArticle(id);
            dispatch(
                openToast({
                    message: res?.data?.data?.message,
                    type: "success",
                    autoHideDuration: 2000,
                })
            );
            fetchListBlogCategory(categoryId);
        } catch (error: any) {
            dispatch(
                openToast({
                    message: error?.response?.data?.message || error?.response?.message || error?.message,
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <>
            <ComponentTopMenu prefixIcon={<IconArrowRight />} suffixIcon={<IconBlog/>} onClickSuffixIcon={handleClickNewArticle} onClickPrefixIcon={handleGoBack}/>
            <StyledContainer hasNavbar>
                <StyledListBlog active_blog={active_blog === 1}>
                    <div className="list_blog_button__wrapper">
                        {active_blog === 1 && (
                            <ShareButton className="list_blog__button" type="primary" size="large" text={t("page.list_blog.create_article")} htmlType="button" onClick={handleClickNewArticle} />
                        )}
                    </div>
                    <div className="content__wrapper">
                        <div className="category">
                            <div className="list_blog__title">{t("page.list_blog.category")}</div>
                            <div className="list_category">
                                {listCategoryForm?.map((category: ICategoryOption) => (
                                    <ComponentBlogCategoryCard key={category.value} category={category} />
                                ))}
                            </div>
                        </div>
                        <div className="blog_recommend">
                            <div className="list_blog__title">{t("page.list_blog.blog_recommend")}</div>
                            <div className="list_blog_recommend">
                                <ComponentSlider listBlog={listHighlightBlog} />
                            </div>
                        </div>
                        {listBlogCategory?.map((blogCategory: IBlogsCategory, index: number) => (
                            <div className="blog_category" key={index}>
                                <div className="blog_category__name">{blogCategory?.listBlog[0]?.category_name}</div>
                                <div className="blog_category__list_blog">
                                    {blogCategory?.listBlog?.map((blog: IBlogData) => (
                                        <ComponentBlogCard handleDelete={() => handleDeleteArticle(blog.id, blogCategory.category_id)} key={blog.id} blogCard={blog} />
                                    ))}
                                </div>
                                <Link className="show_all" to={`${PATH_BLOG_CATEGORY}/${blogCategory.category_id}`}>
                                    {t("page.list_blog.show_all")}
                                    <RightOutlined className="right_arrow" />
                                </Link>
                            </div>
                        ))}
                    </div>
                </StyledListBlog>
            </StyledContainer>
            <ComponentNavigationBar />
        </>
    );
};

interface ISliderProps {
    listBlog: Array<IBlogData>;
}

const ComponentSlider = ({ listBlog }: ISliderProps) => {
    SwiperCore.use([Navigation]);

    const navigation = {
        nextEl: ".button-next",
        prevEl: ".button-prev",
    };

    return (
        <>
            <LeftOutlined className="button-prev" />
            <RightOutlined className="button-next" />
            <Swiper
                slidesPerView={1.2}
                spaceBetween={8}
                navigation={navigation}
                breakpoints={{
                    425: {
                        slidesPerView: 1.2,
                        spaceBetween: 12,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 16,
                    },
                }}
            >
                {listBlog?.map((blog: IBlogData) => (
                    <SwiperSlide key={blog.id}>
                        <ComponentBlogSlideCard blogCard={blog} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
};
