export const IconHeart = (props: any) => {
    const { active } = props;
    return (
        <svg
            width="28"
            height="24"
            viewBox="0 0 28 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.6815 3.31034C18.368 3.3131 17.0947 3.72274 16.0715 4.47174C15.0482 5.22075 14.3361 6.26443 14.0525 7.43078C13.7577 6.26137 13.0358 5.21734 12.0046 4.46902C10.9734 3.72069 9.69377 3.31226 8.37451 3.31034C5.15483 3.31034 2.54541 5.69779 2.54541 8.64022C2.54541 17.1429 14.0669 21.5172 14.0669 21.5172C14.0669 21.5172 25.4545 17.2528 25.4545 8.58655C25.4545 5.6703 22.871 3.31034 19.6815 3.31034V3.31034Z"
                stroke={active === true ? "red" : "black"}
                strokeWidth="1.3"
            />
        </svg>
    );
};

export const IconWhiteHeart = ({ className }: any) => {
    return (
        <svg
            className={className}
            width="1rem"
            height="0.875rem"
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.25 1.5C2.914 1.5 1.5 2.664 1.5 4.5C1.5 6.65 3.08 8.644 4.865 10.182C5.83883 11.0151 6.88839 11.7553 8 12.393C9.11162 11.7553 10.1612 11.0151 11.135 10.182C12.92 8.644 14.5 6.65 14.5 4.5C14.5 2.664 13.086 1.5 11.75 1.5C10.377 1.5 9.141 2.486 8.721 3.956C8.67614 4.1126 8.58152 4.25033 8.45144 4.34838C8.32136 4.44643 8.16289 4.49946 8 4.49946C7.83711 4.49946 7.67864 4.44643 7.54856 4.34838C7.41848 4.25033 7.32386 4.1126 7.279 3.956C6.859 2.486 5.623 1.5 4.25 1.5ZM8 13.25L7.655 13.916L7.653 13.915L7.647 13.912L7.629 13.902C7.52437 13.8477 7.42101 13.791 7.319 13.732C6.10025 13.0382 4.95039 12.2299 3.885 11.318C2.045 9.731 0 7.35 0 4.5C0 1.836 2.086 0 4.25 0C5.797 0 7.153 0.802 8 2.02C8.847 0.802 10.203 0 11.75 0C13.914 0 16 1.836 16 4.5C16 7.35 13.955 9.731 12.115 11.318C10.9593 12.3059 9.70464 13.1719 8.371 13.902L8.353 13.912L8.347 13.915H8.345L8 13.25ZM8 13.25L8.345 13.916C8.23834 13.9711 8.12004 13.9998 8 13.9998C7.87996 13.9998 7.76166 13.9711 7.655 13.916L8 13.25Z"
                fill="white"
            />
        </svg>
    );
};
