import { useTranslation } from "react-i18next";
import React from "react";
import { Formik } from "formik";

import { ShareButton, ShareInput, StyledUpdateProfile } from "@components";
import { HeaderSignUp } from "../SignUp";
import { ShareSelect } from "../Shared/Select";

import Delete from "../../assets/icon/auth/delete.svg";

interface IUpdateProfileComponent {
    data?: any;
    updateProfileSchema?: any;
    onSubmit: (values: any) => void;
    address?: any;
    listAvtUrl: any;
    handleImageChange: (e: any) => void;
    marriages?: any;
    appearances?: any;
    ages?: any;
    heights?: any;
    handleDeleteAvt: (index: any) => void;
    handleOnBack: () => void;
}

export const UpdateProfileComponent = (props: IUpdateProfileComponent) => {
    const {
        data,
        updateProfileSchema,
        onSubmit,
        address,
        listAvtUrl,
        handleImageChange,
        marriages,
        appearances,
        ages,
        heights,
        handleDeleteAvt,
        handleOnBack,
    } = props;
    const { t } = useTranslation();

    return (
        <StyledUpdateProfile>
            <HeaderSignUp
                title="プロフィール編集"
                noteTitle="ステップ2"
                showIcon
                onBack={handleOnBack}
            />
            <div className="form_container">
                <div className="form">
                    <Formik
                        initialValues={data}
                        validationSchema={updateProfileSchema}
                        onSubmit={(values: any) => onSubmit(values)}
                        enableReinitialize={true}
                    >
                        {({
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                            /* and other goodies */
                        }) => (
                            <>
                                <ShareSelect
                                    name="location"
                                    style={{ width: "100%" }}
                                    placeholder={"- すべて -"}
                                    label={"都道府県"}
                                    selectOptions={address}
                                    onChange={setFieldValue}
                                    value={values.location}
                                />
                                <ShareInput
                                    name="profile"
                                    style={{ width: "100%" }}
                                    label={"自己紹介文"}
                                    onChange={handleChange}
                                    value={values.profile}
                                    onBlur={handleBlur}
                                />
                                <div className="avatar-label">{t("page.account.avatar")}</div>
                                <div className="avatar-container">
                                    {listAvtUrl &&
                                        listAvtUrl.length > 0 &&
                                        listAvtUrl.map((image: any, index: number) => (
                                            <div className="img-container" key={index}>
                                                <img
                                                    className="delete"
                                                    src={Delete}
                                                    onClick={() => handleDeleteAvt(image)}
                                                />
                                                <img
                                                    className="avatar-img"
                                                    src={URL.createObjectURL(image)}
                                                />
                                            </div>
                                        ))}
                                </div>

                                <label className="file-upload">
                                    <input
                                        type="file"
                                        multiple="multiple"
                                        accept="image/png, image/gif, image/jpeg"
                                        name="avatar"
                                        onChange={(e: any) => handleImageChange(e)}
                                    />
                                    <div className="add-avatar">{t("page.auth.add_avatar")}</div>
                                </label>
                                <div className="bottom-group">
                                    <ShareSelect
                                        name="age"
                                        style={{ width: "47%" }}
                                        placeholder={"- すべて -"}
                                        label={"年齢"}
                                        selectOptions={ages}
                                        onChange={setFieldValue}
                                        value={values.age}
                                    />
                                    <ShareSelect
                                        name="height"
                                        style={{ width: "47%" }}
                                        label={"身長"}
                                        placeholder={"- すべて -"}
                                        value={values.height}
                                        selectOptions={heights}
                                        onChange={setFieldValue}
                                    />
                                </div>
                                <div className="bottom-group">
                                    <ShareSelect
                                        name="typeBody"
                                        style={{ width: "47%" }}
                                        placeholder={"- すべて -"}
                                        label={t("page.account.appearance")}
                                        selectOptions={appearances}
                                        onChange={setFieldValue}
                                        value={values.typeBody}
                                    />
                                    <ShareSelect
                                        name="marriage"
                                        style={{ width: "47%" }}
                                        placeholder={"- すべて -"}
                                        label={t("page.account.mariage")}
                                        selectOptions={marriages}
                                        onChange={setFieldValue}
                                        value={values.marriage}
                                    />
                                </div>
                                <ShareButton
                                    className="submit"
                                    type="primary"
                                    size="large"
                                    text={"次へ"}
                                    disable={isSubmitting}
                                    htmlType="submit"
                                    onClick={handleSubmit}
                                />
                            </>
                        )}
                    </Formik>
                </div>
            </div>
        </StyledUpdateProfile>
    );
};
