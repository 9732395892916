import styled from "styled-components";

import { theme } from "@utils";

export const StyledWithdrawal = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    text-align: center;
    &::-webkit-scrollbar {
        display: none;
    }
    .question {
        margin-top: 2rem;
        margin-bottom: 0.5rem;
    }
    .rules {
        margin-bottom: 2rem;
        .rule {
            margin-bottom: 0.5rem;
        }
    }
    .button__wrapper {
        padding: 0 2rem;
        .button_delete {
            background-color: ${theme.colors.text};
            border-radius: 0.25rem;
            color: ${theme.colors.black};
            font-family: ${theme.family.fontKozuka};
            font-size: 1.25rem;
        }
    }
    .button_cancel {
        color: #0062a0;
        margin-top: 2rem;
        cursor: pointer;
        display: inline-block;
    }

    @media (min-width: ${theme.breakPoints.breakIpadPro}) {
        max-width: 66.667rem;
    }
`;
