import { createSlice } from "@reduxjs/toolkit";

import { RootState } from ".";

interface IState {
    currentStep: number;
}

const initialState: IState = {
    currentStep: 1,
};

const splashSlice = createSlice({
    initialState,
    name: "splash",
    reducers: {
        goToNextStep(state) {
            state.currentStep = state.currentStep + 1;
        },
        goToPrevStep(state) {
            state.currentStep = state.currentStep - 1;
        },
    },
});

export const { goToNextStep, goToPrevStep } = splashSlice.actions;

export const selectSplash = (state: RootState) => state.splash;

export default splashSlice.reducer;
