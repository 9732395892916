import styled from "styled-components";
import { Link } from "react-router-dom";

import { theme } from "@utils";
import { ICategoryOption } from "@interfaces";
import { PATH_BLOG_CATEGORY } from "@configs";
interface IProps {
    category: ICategoryOption;
}

export const ComponentBlogCategoryCard = (props: IProps) => {
    //Props
    const { category } = props;

    return (
        <StyledBlogCategoryCard to={`${PATH_BLOG_CATEGORY}/${category?.value}`}>
            <div className="category_label">{category?.label}</div>
        </StyledBlogCategoryCard>
    );
};

const StyledBlogCategoryCard = styled(Link)`
    background-color: ${theme.colors.pink};
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: calc(var(--window-width) / 4);
    min-height: calc(var(--window-width) / 4);
    width: calc(var(--window-width) / 4);
    overflow: hidden;
    margin-left: 0.5rem;
    text-decoration: none;
    .category_label {
        font-weight: 700;
        font-size: 0.75rem;
        line-height: 1rem;
        color: ${theme.colors.white};
        font-family: ${theme.family.fontKozuka};
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
    }
    &:first-child {
        margin-left: 0;
    }
    &:hover {
        color: ${theme.colors.white};
        opacity: 0.8;
    }

    @media (min-width: ${theme.breakPoints.breakIpadPro}) {
        width: 20%;
        min-width: unset;
        min-height: 15vh;
        padding: 0 0.75rem;
        .category_label {
            font-size: 1rem;
            line-height: 1.25rem;
        }
    }
`;
