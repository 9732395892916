import { StyledContentAuth, StyledAuth } from "@components";

interface IAppModuleLayout {
    children?: string | JSX.Element | JSX.Element[];
}

export const LayoutAuth = (props: IAppModuleLayout) => {
    //hook
    // const { pathname } = useLocation();
    //page variable
    // const PATH_VERIFY_EMAIL = "/verify-email";
    // const PATH_CHOOSE_PLAN = "/choose-plan";
    // const notImageAuth = pathname.includes(PATH_CHOOSE_PLAN);
    // const notImageAuthContent = pathname.includes(PATH_VERIFY_EMAIL) || pathname.includes(PATH_CHOOSE_PLAN);

    return (
        <StyledAuth notImage={true}>
            <StyledContentAuth  notImage={true}>
                {/* {!notImageAuthContent && (
                    <img className="bg-image__logo" src="/images/auth/logo.png" />
                )} */}
                {props.children}
            </StyledContentAuth>
        </StyledAuth>
    );
};
