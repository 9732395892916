import { makeStyles, CircularProgress } from "@material-ui/core";
import styled from "styled-components";

const useStyles = makeStyles(() => ({
    circle: {
        stroke: "url(#linearColors)",
    },
}));

export const ComponentCircularProgress = () => {
    const classes = useStyles({});
    return (
        <>
            <svg width="0" height="0">
                <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
                    <stop offset="20%" stopColor="#2c3e50" />
                    <stop offset="90%" stopColor="#bdc3c7" />
                </linearGradient>
            </svg>
            <CircularProgress thickness={6} classes={{ circle: classes.circle }} />
        </>
    );
};

export const ComponentLoading = () => {
    return (
        <StyledLoading>
            <ComponentCircularProgress />
        </StyledLoading>
    );
};

const StyledLoading = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
`;
