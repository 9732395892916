import styled from "styled-components";

import { theme } from "@utils";

export const StyledCreateArticle = styled.div`
    width: 100%;
    height: 100%;
    padding: 1.688rem 1rem;
    overflow-y: scroll;
    font-family: ${theme.family.fontKozuka};
    &::-webkit-scrollbar {
        display: none;
    }
    .title {
        font-weight: 500;
        font-size: 1.063rem;
        line-height: 1rem;
        padding-bottom: 1.188rem;
        border-bottom: 0.3px solid ${theme.colors.text};
        margin-bottom: 2.75rem;
    }
    .button_submit {
        display: none;
    }
    .editor_label {
        font-weight: 700;
        font-size: 1rem;
        line-height: 2rem;
        margin-top: 0.813rem;
        margin-bottom: 0.313rem;
    }
    .ql-editor {
        min-height: 12.5rem;
    }
    .ql-editor.ql-blank::before {
        color: #bfbfbf;
    }
    @media (min-width: ${theme.breakPoints.breakIpadPro}) {
        max-width: 66.667rem;
    }
`;

export const StyledInputImage = styled.div`
    width: 100%;
    overflow: hidden;
    font-family: ${theme.family.fontKozuka};
    border-bottom: 0.3px solid ${theme.colors.text};
    padding-bottom: 2.063rem;
    .upload_image__label {
        font-weight: 500;
        font-size: 1rem;
        line-height: 2rem;
        color: ${theme.colors.primary};
        margin-bottom: 0.563rem;
    }
    .preview_img__wrapper {
        position: relative;
        margin-bottom: 0.875em;
        .close_wrapper {
            position: absolute;
            top: 0;
            right: 0;
        }
        .preview_img {
            width: 100%;
            height: auto;
            object-fit: cover;
            display: block;
        }
    }
    .upload_image {
        background-color: ${theme.colors.secondary};
        border: none;
        font-weight: 500;
        font-size: 0.938rem;
        line-height: 1.875rem;
        color: ${theme.colors.black};
    }
`;
