import { useTranslation } from "react-i18next";
import { useRef } from "react";

import { ShareButton, ComponentImagePreview, StyledInputImage, IconClose } from "@components";

interface IProps {
    name: string;
    label: string;
    previewImage: any;
    setFieldValue?: any;
    handleRemoteClose?(): void;
}

export const ComponentInputImage = (props: IProps) => {
    //props
    const { name, setFieldValue, label, previewImage, handleRemoteClose } = props;

    //page hooks
    const { t } = useTranslation();

    const inputImage = useRef<HTMLInputElement>(null);

    const handleClickUpload = () => {
        if (inputImage.current) inputImage.current.click();
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(`${name}`, event.target.files && event.target.files[0]);
    };

    //localClose
    const handleLocalClose = () => {
        setFieldValue(`${name}`, null);
        //@ts-ignore
        inputImage.current.value = "";
    };

    return (
        <StyledInputImage>
            <label className="upload_image__label">{label}</label>
            {previewImage && (
                <div className="preview_img__wrapper">
                    <ComponentImagePreview className="preview_img" file={previewImage} />
                    <div
                        className="close_wrapper"
                        onClick={
                            typeof previewImage === "string" ? handleRemoteClose : handleLocalClose
                        }
                    >
                        <IconClose />
                    </div>
                </div>
            )}
            <input type="file" hidden ref={inputImage} onChange={handleChange} />
            <ShareButton
                className="upload_image"
                type="primary"
                size="large"
                text={t("page.create_article.add_file")}
                htmlType="button"
                onClick={handleClickUpload}
            />
        </StyledInputImage>
    );
};
