import { Link } from "react-router-dom";

import { StyledSettingOption, IconChevronRight } from "@components";
import { ISettingOption } from "@interfaces";

interface IProps {
    settingOption: ISettingOption;
    onHandle: (type: string | undefined) => void;
}

export const ComponentSettingOption = (props: IProps) => {
    //Props
    const { settingOption, onHandle } = props;

    return (
        <StyledSettingOption hasExpand={settingOption?.hasExpand}>
            {settingOption?.path ? (
                <Link className="option__wrapper" to={settingOption.path}>
                    <div className="setting_option">{settingOption?.name}</div>
                    {settingOption?.hasExpand && <IconChevronRight />}
                    {settingOption?.children}
                </Link>
            ) : (
                <div className="option__wrapper" onClick={() => onHandle(settingOption?.type)}>
                    <div className="setting_option">{settingOption?.name}</div>
                    {settingOption?.hasExpand && <IconChevronRight />}
                    {settingOption?.children}
                </div>
            )}
        </StyledSettingOption>
    );
};
