import { ErrorMessage } from "formik";
import { Input } from "antd";
import { CSSProperties } from "styled-components";
import { ReactElement } from "react";
import styled, { css } from "styled-components";

import { IHandleBlur, IHandleChange } from "@interfaces";
import { StyledError } from "@components";

interface IProps {
    label?: string;
    name: string;
    className?: string;
    style?: CSSProperties | undefined;
    onChange?: IHandleChange;
    value?: string | number;
    onBlur?: IHandleBlur;
    type?: string;
    prefixIcon?: ReactElement;
    suffixIcon?: ReactElement;
    notFormik?: boolean;
    placeholder?: string;
    err?: string;
    notErr?: boolean;
    disable?: boolean;
    readOnly?: boolean;
    onFocus?(event: React.FocusEvent<HTMLInputElement>): void;
}

export const ShareInput = (props: IProps) => {
    //page props
    const {
        label,
        name,
        style,
        onChange,
        onBlur,
        value,
        type,
        prefixIcon,
        suffixIcon,
        notFormik,
        placeholder,
        err,
        className,
        notErr,
        disable,
        readOnly,
        onFocus,
    } = props;

    return (
        <StyledContainer value={value} style={{ ...style }}>
            <div className="input_group">
                <Input
                    className={err ? `${className} input-invalid` : className}
                    type={type}
                    name={name}
                    onChange={onChange}
                    value={value}
                    onBlur={onBlur}
                    prefix={prefixIcon}
                    suffix={suffixIcon}
                    placeholder={placeholder}
                    disabled={disable}
                    readOnly={readOnly}
                    onFocus={onFocus}
                />
                {label && (
                    <label className="label" htmlFor={name}>
                        {label}
                    </label>
                )}
            </div>
            {!notFormik && <ErrorMessage name={name} component={StyledError} />}

            {!notErr && (
                <StyledError style={err ? { visibility: "visible" } : { visibility: "hidden" }}>
                    {err || "err"}
                </StyledError>
            )}
        </StyledContainer>
    );
};

interface IStyledContainerProps {
    value?: string | number;
}

const StyledContainer = styled.div<IStyledContainerProps>`
    .input_group {
        position: relative;
        margin-top: 20px;
    }
    .label {
        font-size: 1.2rem;
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        pointer-events: none;
        transition: all 0.3s linear;
        color: #bfbfbf;
        ${(props) => {
            if (props.value)
                return css`
                    transform: translateY(-100%);
                    align-items: flex-end;
                    color: #3bb3c1;
                `;
        }};
    }
    .input-add-contact {
        height: 45px;
        input {
            font-size: 16px;
        }
    }
    input {
        /* border-color: #d9d9d9 !important; */
        box-shadow: none !important;
    }
    .ant-input {
        height: 45px;
        line-height: 27px;
        color: #000;
        font-weight: 600;
        font-size: 1.2rem !important;
        border: none;
        border-bottom: 0.5px solid #928e8d !important;
        padding: 0 !important;
        border-radius: 0;
        background-color: transparent;
        @media (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
            height: 46px;
        }
        &:focus + .label {
            transform: translateY(-100%);
            align-items: flex-end;
            color: #3bb3c1;
            font-weight: 500;
        }
    }
    // hide arrow type number
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }
    .input-invalid {
        input {
            border-width: 1px;
            border-color: ${(p) => p.theme.colors.danger}!important;
            &:focus-within {
                border-color: ${(p) => p.theme.colors.danger}!important;
            }
        }
        .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),
        .ant-input:focus,
        .ant-input:hover,
        .ant-select-selection:hover,
        .ant-select-selection:focus,
        .ant-select-selection:focus-within {
            border-width: 1px;
            border-color: ${(p) => p.theme.colors.danger}!important;
        }
    }
`;
