import { theme } from "@utils";
import styled from "styled-components";

export const StyledEditProfile = styled.div`
    /* background-color: red; */
    height: 100%;
    margin-top: 2.8rem !important;
    padding: 1rem 0;
    overflow-y: scroll;
    background-image: url("/images/splash/choose_gender_background.svg");
    background-size: contain;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    max-width: 66.667rem;
    margin: 0 auto;
    background-color: white;
    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
    .title {
        border-bottom: 1px solid #d0d1d3;
        padding: 0 1rem 1rem;
        font-size: 1.2rem;
        color: #000;
        font-weight: 500;
    }
    .form-step1 {
        padding: 0 1rem;
      .label {
        font-size: 1.2rem;
        color: #3bb3c1;
      }
      #textarea{
        border: none;
        padding: 0;
        line-height: 27px;
        color: #000;
        font-weight: 600;
        font-size: 1.2rem !important;
        border-bottom: 0.5px solid #928e8d !important;
        margin-top: 20px;
        margin-bottom: 30px;
      }
    }
    .form-step2 {
        padding: 0 1rem;
    }

    .title.option2 {
        border-top: 1px solid #d0d1d3;
        border-bottom: none;
        padding-top: 1rem;
        padding-bottom: 0;
    }

    .avatar-label {
        font-size: 1.2rem;
        color: #3bb3c1;
    }
    .avatar-container {
        display: flex;
        width: 100%;
        overflow: auto;
        padding-bottom: 10px;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        .img-container {
            position: relative;
            width: 100px;
            height: 100px;
            margin: 5px;
            overflow: hidden;
            border-radius: 5px;

            .delete {
                position: absolute;
                right: 5px;
                bottom: 5px;
                cursor: pointer;
            }
            .avatar-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .file-upload {
        border: 2px solid $tertiary;
        width: 100%;
        border-radius: 10px;
        margin-top: 3px;
        color: $tertiary;
        font-family: "BarlowBold";
        cursor: pointer;

        input[type="file"] {
            display: none !important;
        }
        .add-avatar {
            background: #fac9e1;
            padding: 10px;
            text-align: center;
            font-size: 1.2rem;
            border-radius: 4px;
            font-weight: bold;
        }
    }
    .bottom-group {
        display: flex;
        justify-content: space-between;
        margin-top: 1.2rem;
    }
    .submit {
        margin-top: 1rem;
        background-color: ${theme.colors.primary} !important;
        margin-bottom: 3rem;
    }
`;
