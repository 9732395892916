import { initializeApp } from "firebase/app";

// App's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyATk_GzNNB6cBpEUZzuOSo7KX4LfF9HKZo",
    authDomain: "jojo-app-fb35a.firebaseapp.com",
    projectId: "jojo-app-fb35a",
    storageBucket: "jojo-app-fb35a.appspot.com",
    messagingSenderId: "8666220897",
    appId: "1:8666220897:web:c8f7dad9a893a4b2e29d20",
    measurementId: "G-NEW5CWWLRP",
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
