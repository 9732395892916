import { Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";

import { closeNotification, selectNotification, useAppSelector } from "@redux";

export const ComponentNotification = () => {
    //page hooks
    const notification = useAppSelector(selectNotification).notification;
    const dispatch = useDispatch();
    const history = useHistory();

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }
        dispatch(closeNotification());
    };

    const handleClick = () => {
        dispatch(closeNotification());
        //@ts-ignore
        history.push(notification.link);
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: notification.vertical || "top",
                    horizontal: notification.horizontal || "right",
                }}
                open={notification.open}
                autoHideDuration={notification.autoHideDuration}
                onClose={handleClose}
            >
                <Alert severity={"info"} onClick={handleClick}>
                    {notification.message}
                </Alert>
            </Snackbar>
            <ToastContainer position="top-right" autoClose={notification.autoHideDuration || 2000} hideProgressBar={true} newestOnTop={false} rtl={false} draggable limit={1} />
        </>
    );
};
