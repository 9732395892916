export const IconGenderMale = () => {
    return (
        <svg
            width="3.5rem"
            height="3.5rem"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M36.0937 5.25V10.0625H42.5348L35.9297 16.6677C28.4703 10.8861 17.664 11.4177 10.8237 18.2613C3.40373 25.6823 3.40373 37.7552 10.8281 45.1719C14.3998 48.7329 19.2376 50.7325 24.2812 50.7325C29.3248 50.7325 34.1627 48.7329 37.7344 45.1719C44.5779 38.3272 45.1095 27.5253 39.3279 20.0659L45.9375 13.4652V19.9063H50.75V5.25H36.0937ZM34.335 41.7725C31.6666 44.4353 28.0509 45.9308 24.2812 45.9308C20.5115 45.9308 16.8958 44.4353 14.2275 41.7725C8.68326 36.2294 8.68326 27.2081 14.2275 21.665C19.7717 16.1219 28.7919 16.1208 34.335 21.665C39.8781 27.2092 39.8792 36.2294 34.335 41.7725Z"
                fill="#A7D7DC"
            />
        </svg>
    );
};

export const IconGenderFemale = () => {
    return (
        <svg
            width="3.5rem"
            height="3.5rem"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M47.0312 20.7812C47.0312 10.2878 38.4934 1.75 28 1.75C17.5066 1.75 8.96875 10.2878 8.96875 20.7812C8.96875 30.4598 16.2313 38.4748 25.5938 39.6605V43.0938H19.25V47.9062H25.5938V54.25H30.4062V47.9062H36.75V43.0938H30.4062V39.6605C39.7687 38.4748 47.0312 30.4598 47.0312 20.7812ZM13.7812 20.7812C13.7812 12.9412 20.16 6.5625 28 6.5625C35.84 6.5625 42.2188 12.9412 42.2188 20.7812C42.2188 28.6213 35.84 35 28 35C20.16 35 13.7812 28.6213 13.7812 20.7812Z"
                fill="#F7B1B1"
            />
        </svg>
    );
};
