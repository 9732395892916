export const IconWarning = () => {
    return (
        <svg
            width="18"
            height="15"
            viewBox="0 0 18 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.1556 11.5483L10.6732 1.07275C10.258 0.400988 9.53871 0 8.74904 0C7.95936 0 7.24006 0.401022 6.82485 1.07275L6.82506 1.07245L0.342329 11.5486C-0.0951256 12.2563 -0.114401 13.1127 0.290723 13.8394C0.695846 14.5662 1.43446 15 2.26648 15H15.2317C16.0637 15 16.8023 14.5662 17.2074 13.8394C17.6125 13.1127 17.5933 12.2563 17.1556 11.5483ZM16.0136 13.1739C15.8533 13.4615 15.561 13.6332 15.2316 13.6332H2.26648C1.93716 13.6332 1.64481 13.4615 1.48446 13.1739C1.32411 12.8863 1.33173 12.5473 1.50469 12.2675L7.98729 1.79168L7.98749 1.79138C8.15427 1.52152 8.43185 1.36677 8.74907 1.36677C9.06629 1.36677 9.34387 1.52152 9.51086 1.79165L15.9932 12.2672C16.1664 12.5473 16.174 12.8863 16.0136 13.1739Z"
                fill="white"
            />
            <path d="M9.43253 11.0706H8.0658V12.4374H9.43253V11.0706Z" fill="white" />
            <path d="M9.43253 4.33939H8.0658V9.70384H9.43253V4.33939Z" fill="white" />
        </svg>
    );
};
