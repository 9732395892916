import { PageCreateArticle } from "./../Pages/CreateArticle";
import { LayoutAuth, LayoutError } from "@layouts";
import { IRoute } from "@interfaces";
import {
    PageHome,
    PageLogin,
    PageError404,
    PageSignUp,
    PageCreateNewPassword,
    PageSplash,
    PageForgotPassword,
    PageListBlog,
    PageListBlogCategory,
    PageListUserBlog,
    PageArticleDetail,
    PageSetting,
    PageFAQ,
    PageTerm,
    PagePrivacy,
    PageListUserBlock,
    PageWithdrawal,
    ListChat,
    EditProfile,
    ChangePassWord,
    PagePricing,
} from "@pages";
import { ListFavorites } from "src/Pages/Favorites/ListFavorites";
import { DetailFavorites } from "src/Pages/Favorites/DetailFavorites";
import { PageUpdateProfile } from "src/Pages/UpdateProfile";
import { PageSelectGender } from "src/Pages/SelectGender";
import { Chat } from "src/Pages/Chat";
import { PageVerifyPassport } from "src/Pages/VerifyPassport";

export const PATH_HOME = "/";
export const PATH_LOGIN = "/login";
export const PATH_SIGN_UP = "/sign-up";
export const PATH_VERIFY_PASSPORT = "/verify-passport";
export const PATH_UPDATE_PROFILE = "/update-profile";
export const PATH_CREATE_PASSWORD = "/password/reset";
export const PATH_SPLASH = "/splash";
export const PATH_FORGOT_PASSWORD = "/forgot-password";
export const PATH_SELECT_GENDER = "/select-gender";

export const PATH_MESSAGE = "/messages";
export const PATH_FAVORITE = "/favorites";
export const PATH_DETAIL_USER = "/detail-favorites";
export const PATH_SETTING = "/setting";
export const PATH_FAQ = "/faqs";
export const PATH_TERM = "/terms";
export const PATH_PRIVACY = "/privacy";
export const PATH_WITHDRAWAL = "/withdrawal";
export const PATH_USER_BLOCK = "/user/blocks";
export const PATH_BLOG = "/blogs";
export const PATH_BLOG_CATEGORY = "/blogs/category";
export const PATH_BLOG_USER = "/blogs/user-blog";
export const PATH_CREATE_ARTICLE = "/blogs/new-article";
export const PATH_ARTICLE_DETAIL = "/blogs/article";
export const PATH_PRICING = "/pricing";

export const PATH_EDIT_PROFILE = "/edit-profile";
export const PATH_CHANGE_PASSWORD = "/change-password";
export const PATH_CHAT = "/chat";

export const PATH_404 = "*";

export const routes: Array<IRoute> = [
    { path: PATH_HOME, component: PageHome, exact: true },
    { path: PATH_LOGIN, component: PageLogin, exact: true, layout: LayoutAuth },
    { path: PATH_SIGN_UP, component: PageSignUp, exact: true, layout: LayoutAuth },
    { path: PATH_VERIFY_PASSPORT, component: PageVerifyPassport, exact: true, layout: LayoutAuth },
    { path: PATH_UPDATE_PROFILE, component: PageUpdateProfile, exact: true, layout: LayoutAuth },
    {
        path: `${PATH_CREATE_PASSWORD}/:token`,
        component: PageCreateNewPassword,
        exact: true,
        layout: LayoutAuth,
    },
    { path: PATH_FORGOT_PASSWORD, component: PageForgotPassword, exact: true, layout: LayoutAuth },

    { path: PATH_SPLASH, component: PageSplash, exact: true },
    { path: PATH_PRICING, component: PagePricing, exact: true },
    { path: PATH_MESSAGE, component: ListChat, exact: true },
    { path: PATH_FAVORITE, component: ListFavorites, exact: true },
    { path: `${PATH_DETAIL_USER}/:id`, component: DetailFavorites, exact: true },
    { path: PATH_SELECT_GENDER, component: PageSelectGender, exact: true },
    { path: PATH_BLOG, component: PageListBlog, exact: true },
    { path: `${PATH_BLOG_CATEGORY}/:category`, component: PageListBlogCategory, exact: true },
    { path: `${PATH_BLOG_USER}/:userId`, component: PageListUserBlog, exact: true },
    { path: PATH_CREATE_ARTICLE, component: PageCreateArticle, exact: true },
    { path: `${PATH_CREATE_ARTICLE}/:id`, component: PageCreateArticle, exact: true },
    { path: `${PATH_ARTICLE_DETAIL}/:id`, component: PageArticleDetail, exact: true },
    { path: PATH_EDIT_PROFILE, component: EditProfile, exact: true },
    { path: PATH_CHANGE_PASSWORD, component: ChangePassWord, exact: true },

    { path: PATH_SETTING, component: PageSetting, exact: true },
    { path: PATH_FAQ, component: PageFAQ, exact: true },
    { path: PATH_TERM, component: PageTerm, exact: true },
    { path: PATH_PRIVACY, component: PagePrivacy, exact: true },
    { path: PATH_USER_BLOCK, component: PageListUserBlock, exact: true },
    { path: PATH_WITHDRAWAL, component: PageWithdrawal, exact: true },
    { path: `${PATH_CHAT}/:param`, component: Chat, exact: true },
    { path: `${PATH_CHAT}/:idTopic/:id`, component: Chat, exact: true },

    { component: PageError404, exact: false, layout: LayoutError },
];

export const normalRoutes = [
    PATH_HOME,
    PATH_BLOG,
    PATH_CREATE_ARTICLE,
    PATH_BLOG_CATEGORY,
    PATH_BLOG_USER,
    PATH_ARTICLE_DETAIL,
    PATH_SETTING,
    PATH_FAQ,
    PATH_TERM,
    PATH_PRIVACY,
    PATH_USER_BLOCK,
    PATH_WITHDRAWAL,
    PATH_UPDATE_PROFILE,
    PATH_EDIT_PROFILE,
    PATH_CHANGE_PASSWORD,
    PATH_CHAT,
    PATH_MESSAGE,
    PATH_DETAIL_USER,
    PATH_FAVORITE,
];

export const authRoutes = [PATH_SPLASH, PATH_LOGIN, PATH_SIGN_UP, PATH_FORGOT_PASSWORD, PATH_CREATE_PASSWORD, PATH_SELECT_GENDER, PATH_PRICING, PATH_VERIFY_PASSPORT];
