import { IconArrowRight, ComponentImage } from "@components";
import { theme } from "@utils";
import React from "react";
import styled from "styled-components";

interface IHeaderChat {
    friend: any;
    onBack: () => void;
    onClickProfile: () => void;
}

export const HeaderChat = (props: IHeaderChat) => {
    const { friend, onBack, onClickProfile } = props;
    return (
        <StyledHeaderChat>
            <div className="content__wrapper">
                <div className="btn-back" onClick={onBack}>
                    <IconArrowRight />
                </div>
                <div onClick={onClickProfile} className="boxUser">
                    <ComponentImage src={friend?.avatar} className="avatar" />
                    <p className="user-name">{friend?.username}</p>
                </div>
            </div>
        </StyledHeaderChat>
    );
};

export const StyledHeaderChat = styled.div`
    background-color: ${theme.colors.primary};
    overflow: hidden;
    .content__wrapper {
        padding: 0 1rem;
        height: 3.375rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .btn-back {
        margin-right: 1rem;
    }

    .boxUser {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .avatar {
        width: 2rem;
        height: 2rem;
        border-radius: 4px;
        margin-right: 0.5rem;
        object-fit: cover;
    }

    .user-name {
        margin: 0;
        font-size: 1rem;
        color: #fff;
        font-weight: 700;
    }

    @media (min-width: ${theme.breakPoints.breakIpadPro}) {
        .content__wrapper {
            max-width: 66.667rem;
            margin: auto;
        }
    }
`;
