import { useTranslation } from "react-i18next";

import { StyledWithdrawal, ShareButton } from "@components";

interface IProps {
    handleGoBack(): void;
    openModal(): void;
}

export const ComponentWithdrawal = (props: IProps) => {
    //page Hooks
    const { t } = useTranslation();

    //props
    const { handleGoBack, openModal } = props;

    const renderRules = (rules: string): React.ReactNode => {
        const arrayRule = rules.split("\n");
        return (
            <>
                {arrayRule.map((rule: string, index: number) => (
                    <div key={index} className="rule">
                        {rule}
                    </div>
                ))}
            </>
        );
    };

    return (
        <StyledWithdrawal>
            <div className="question">{t("page.deleteAccount.questionDelete")}</div>
            <div className="rules">{renderRules(t("page.deleteAccount.rule"))}</div>
            <div className="button__wrapper">
                <ShareButton
                    className="button_delete"
                    type="primary"
                    size="large"
                    text={t("page.deleteAccount.title")}
                    htmlType="button"
                    onClick={openModal}
                />
            </div>
            <div onClick={handleGoBack} className="button_cancel">
                {t("page.deleteAccount.cancel")}
            </div>
        </StyledWithdrawal>
    );
};
