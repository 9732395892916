import styled from "styled-components";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import { theme } from "@utils";

export const StyledBlogSlideCard = styled(Link)`
    width: 100%;
    padding: 0.625rem;
    overflow: hidden;
    font-family: ${theme.family.fontKozuka};
    background-color: ${theme.colors.white};
    scroll-snap-align: center;
    text-decoration: none;
    display: block;
    border-radius: 0.25rem;
    &:hover {
        opacity: 0.8;
    }
    .blog_title {
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 1rem;
        color: ${theme.colors.highlight};
        margin-bottom: 0.375rem;
    }
    .card_slide_top {
        width: 100%;
        height: 19vh;
        position: relative;
    }
    .card_top_image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        border-radius: 0.25rem;
    }
    .author_name_slide {
        width: 74%;
        background: linear-gradient(90deg, #ffffff 47.66%, rgba(255, 255, 255, 0) 104.27%);
        opacity: 0.7;
        border-radius: 0 0 0.25rem 0;
        padding: 0.375rem 0.813rem;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1rem;
        color: ${theme.colors.textSecondary};
        position: absolute;
        bottom: 0;
        left: 0;
    }
    .card_slide_bottom {
        margin-top: 0.5rem;
        .card_bottom__top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0.875rem;
        }
    }
    .blog_summary {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.375rem;
        height: 4.25rem;
        color: ${theme.colors.text};
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-align: left;
        word-break: break-word;
        white-space: pre-wrap;
        word-wrap: break-word;
    }
    .category_name {
        background-color: ${theme.colors.secondary};
        border-radius: 6.25rem;
        color: ${theme.colors.darkText};
        padding: 0.125rem 1.25rem;
        font-size: 0.563rem;
        line-height: 1rem;
        font-weight: 500;
    }
    .create_at {
        color: ${theme.colors.text};
        font-size: 0.563rem;
        line-height: 1rem;
        font-weight: 500;
    }

    @media (min-width: ${theme.breakPoints.breakMobilePhone}) {
        .card_slide_top {
            height: 25vh;
        }
        .category_name {
            padding: 0.325rem 1.45rem;
            font-size: 0.863rem;
        }
    }
`;

interface IStyledBlogCardProps {
    menu?: string;
}

export const StyledBlogCard = styled(StyledBlogSlideCard)<IStyledBlogCardProps>`
    padding: 1.25rem 0.625rem;
    margin-bottom: 0.625rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .author_name {
        font-weight: 500;
        font-size: 0.563rem;
        line-height: 1rem;
        color: ${theme.colors.textSecondary};
        margin-right: 1rem;
        text-align: left;
        word-break: break-word;
    }
    .blog_title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.5rem;
        line-height: 1.5rem;
        text-align: left;
        max-width: ${(props) => (!(props.menu === "true") ? "100%" : "calc(100% - 3.25rem)")};
        word-break: break-word;
    }
    .blog_summary {
        height: 10vh;
        line-height: calc(10vh / 3);
    }
    .card_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.563rem;
    }
    .card_bottom {
        display: flex;
        .card_bottom_image {
            width: 35.4%;
            min-width: 35.4%;
            height: 10vh;
            object-fit: cover;
            display: block;
            margin-right: 0.813rem;
            border-radius: 0.25rem;
        }
    }

    @media (min-width: ${theme.breakPoints.breakMobilePhone}) {
        .blog_summary {
            height: 16vh;
            line-height: calc(16vh / 6);
            -webkit-line-clamp: 6;
        }
        .card_bottom {
            .card_bottom_image {
                height: 16vh;
            }
        }
        .author_name {
            font-size: 0.875rem;
            line-height: 1rem;
        }
    }
    @media (min-width: ${theme.breakPoints.breakIpadPro}) {
        width: 100%;
        .blog_summary {
            height: 20vh;
            -webkit-line-clamp: 7;
            line-height: calc(20vh / 7);
        }
        .author_name {
            font-size: 1rem;
            line-height: 1.25rem;
        }
        .card_bottom {
            .card_bottom_image {
                height: 20vh;
            }
        }
    }
`;

export const StyledBlogCardWrapper = styled.div`
    position: relative;
    .icon_expand__wrapper {
        z-index: 98;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: flex-end;
        .icon_expand {
            padding: 1.25rem 0.825rem 0.75rem 1rem;
        }
    }
`;

export const useStylesSubMenu = makeStyles({
    "MuiMenuItem-root": {
        paddingLeft: "0.5rem !important",
        paddingRight: "0.5rem !important",
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
        fontFamily: '"KozukaGothicProMedium","KozukaGothicProRegular" !important',
        minHeight: "unset !important",
    },
});
