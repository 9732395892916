import styled from "styled-components";

import { StyledListBlog } from "@components";

import { theme } from "@utils";

export const StyledListBlogCategory = styled(StyledListBlog)`
    width: 100%;
    height: 100%;
    padding-bottom: ${(props) => (props.active_blog ? "8.563rem" : "4.125rem")};
    .content__wrapper {
        padding-right: 1rem;
        margin-top: ${(props) => (props.active_blog ? "8.5rem" : "4.125rem")};
        .empty_list {
            text-align: center;
            font-weight: 500;
            font-size: 1.063rem;
            line-height: 1rem;
            color: ${theme.colors.black};
            margin-top: 1.5rem;
        }
    }
    .list_blog_button__wrapper {
        .category_title {
            margin-top: 1.563rem;
            margin-bottom: 1.438rem;
            font-weight: 700;
            font-size: 1.063rem;
            line-height: 1rem;
            color: #010101;
        }
    }

    @media (min-width: ${theme.breakPoints.breakIpadPro}) {
        max-width: 66.667rem;
        .list_blog_button__wrapper {
            max-width: 66.667rem;
            left: 50%;
            transform: translateX(-50%);
        }
    }
`;
