import styled from "styled-components";
import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
// import { useTranslation } from "react-i18next";

import { ComponentNavigationBar, ComponentTopMenu, ComponentImage, ComponentInfiniteScrollLoader, IconWhiteHeart, ShareButton } from "@components";
import { PATH_DETAIL_USER } from "@configs";
import { Favorite } from "@api";
import { setLoading, selectApp } from "@redux";
import { theme } from "@utils";

export const ListFavorites = () => {
    const user: any = useSelector((state: any) => state.auth.userInfo);
    const history = useHistory();
    const dispatch = useDispatch();
    // const { t } = useTranslation();
    const [active, setActive] = useState<number>(0);
    const [data, setData] = useState<any>([]);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPage, setTotalPage] = useState<number>(1);

    const callApi = async (page: number) => {
        try {
            if (page === 1) {
                dispatch(setLoading(true));
            }
            if (active === 1) {
                const res = await Favorite.listFavorites(user?.id, page);
                setData([...data, ...res?.data?.data?.data]);
                const totalFollowing = res?.data?.data?.totalFollowing;
                const perPage = res?.data?.data?.paging?.per_page;
                setTotalPage(Math.ceil(totalFollowing / perPage));
            } else {
                const res = await Favorite.listFavoriting(user?.id, page);
                setData([...data, ...res?.data?.data?.data]);
                const totalFollowing = res?.data?.data?.totalFollowing;
                const perPage = res?.data?.data?.paging?.per_page;
                setTotalPage(Math.ceil(totalFollowing / perPage));
            }
        } catch (error: any) {
        } finally {
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        callApi(currentPage);
    }, [active, currentPage]);

    const navigateDetail = (id: number) => {
        history.push(`${PATH_DETAIL_USER}/${id}`);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const isLoading = useSelector(selectApp).loading;

    return (
        <StyledContainer>
            <div className="viewContainer">
                <ComponentTopMenu />
                <div className="viewButtonHeader">
                    <div className="viewButton">
                        <ShareButton
                            className={["submit", active === 0 && "activeButton"].join(" ")}
                            type="primary"
                            size="large"
                            htmlType="submit"
                            // disable={isSubmitting}
                            styleContainer={{ width: "49%" }}
                            onClick={() => {
                                if (active === 1) {
                                    setActive(0);
                                    setCurrentPage(1);
                                    setData([]);
                                }
                            }}
                        >
                            <IconWhiteHeart className="icon_heart" />
                            お気に入り
                        </ShareButton>
                        <ShareButton
                            className={["submit", active === 1 && "activeButton"].join(" ")}
                            type="primary"
                            size="large"
                            htmlType="submit"
                            // disable={isSubmitting}
                            styleContainer={{ width: "49%" }}
                            onClick={() => {
                                if (active === 0) {
                                    setActive(1);
                                    setCurrentPage(1);
                                    setData([]);
                                }
                            }}
                        >
                            <IconWhiteHeart className="icon_heart" />
                            おしてくれた人
                        </ShareButton>
                    </div>
                </div>
                <>
                    {data?.length > 0 ? (
                        <div className="viewContent" id="scroll_view">
                            <InfiniteScroll
                                dataLength={data?.length}
                                next={handleNextPage}
                                hasMore={currentPage < totalPage}
                                loader={<ComponentInfiniteScrollLoader className="loader" />}
                                scrollableTarget="scroll_view"
                            >
                                {data?.map((item: any, index: any) => {
                                    return (
                                        <div className="viewitem" onClick={() => navigateDetail(item?.id)} key={index}>
                                            <ComponentImage className="imageItem" src={item?.avatar} alt="Avatar" />
                                            <div className="itemContent">
                                                <h3>{item?.username}</h3>
                                                <p className="txtBottom">
                                                    {item?.age} 年 ・ {item?.profile_text}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </InfiniteScroll>
                        </div>
                    ) : isLoading ? (
                        <h4 className="empty-txt">ローディング中</h4>
                    ) : (
                        <h4 className="empty-txt">リストは空です</h4>
                    )}
                </>
                <ComponentNavigationBar />
            </div>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    background-image: url("/images/splash/choose_gender_background.svg");
    width: 100%;
    height: 100%;
    .viewHeader {
        display: flex;
        padding: 20px;
        background: #3bb3c1;
        width: 100%;
        justify-content: center;
    }
    .viewContainer {
        display: flex;
        height: 100%;
        flex-direction: column;
        align-items: center;
        margin: auto;
    }
    .viewButtonHeader {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 3.375rem;
        padding-top: 1.5rem;
    }
    .viewButton {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 0 0.813rem;
        justify-content: space-between;
        .submit {
            font-weight: 700;
            font-size: 0.875rem;
            line-height: 1.75rem;
            color: #ffffff !important;
            padding: 0.4rem 0 !important;
            border: none;
        }
        .icon_heart {
            margin-right: 0.375rem;
        }
    }
    .viewContent {
        flex: 1;
        overflow: scroll;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 0.813rem;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }
        margin-bottom: 5rem;
    }
    .viewitem {
        margin-top: 16px;
        display: flex;
    }
    .imageItem {
        width: 4.5rem;
        height: 4.5rem;
        border-radius: 0.25rem;
    }
    .itemContent {
        flex: 1;
        display: flex;
        margin-left: 16px;
        flex-direction: column;
        justify-content: space-between;
        border-bottom: 0.3px solid #787878;
        h3 {
            font-weight: 600;
            font-size: 1rem;
            line-height: 1rem;
            color: #000000;
            margin: 0;
            margin-top: 0.313rem;
        }
    }
    .txtBottom {
        margin-bottom: 0.688rem;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1rem;
        color: #787878;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .submit {
        background: #f0d4e2;
        border-radius: 4px;
        &:hover {
            color: white !important;
            opacity: 0.8;
        }
    }
    .activeButton {
        background: #cb92ae;
    }
    .empty-txt {
        margin-top: 50px;
    }

    @media (min-width: ${theme.breakPoints.breakIpadPro}) {
        .viewContainer {
            max-width: 66.667rem;
        }
    }
`;
