import styled from "styled-components";

import { theme } from "@utils";

export const StyledListUserBlog = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 1rem;
    padding-bottom: 0.375rem;
    &::-webkit-scrollbar {
        display: none;
    }
    @media (min-width: ${theme.breakPoints.breakIpadPro}) {
        max-width: 66.667rem;
    }
`;
