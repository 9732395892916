import { useTranslation } from "react-i18next";
import { Formik, FormikHelpers } from "formik";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import { StyledForgotPassword, ShareInput, ShareButton, IconArrowRight } from "@components";
import { IForgotPassword } from "@interfaces";
import { authApi } from "@api";
import { openToast, setLoading } from "@redux";

export const PageForgotPassword = () => {
    //page Variables
    const data: IForgotPassword = { username: "" };

    //page Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSubmit = async (
        values: IForgotPassword,
        { setSubmitting }: FormikHelpers<IForgotPassword>
    ) => {
        try {
            //WHAT: CALL API forgot password
            dispatch(setLoading(true));
            const res = await authApi.forgotPassword(values.username);
            dispatch(
                openToast({
                    message: res.data.data.message,
                    type: "success",
                    autoHideDuration: 2000,
                })
            );
        } catch (error: any) {
            dispatch(
                openToast({
                    message: error.response.data.data.errors.email[0] || error.message,
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
        } finally {
            setSubmitting(false);
            dispatch(setLoading(false));
        }
    };

    const forgotPasswordSchema = Yup.object().shape({
        username: Yup.string()
            .required(t("validation.field_required"))
            .email(t("validation.email_invalid"))
            .max(
                255,
                t("validation.max_length", {
                    returnObjects: true,
                    name: t("page.forgot_password.username_or_email"),
                })
            ),
    });

    const handleGoBack = () => {
        history.goBack();
    };

    return (
        <StyledForgotPassword className="styled">
            <div className="content__wrapper">
                <div className="go_back__btn" onClick={handleGoBack}>
                    <IconArrowRight />
                </div>
                <p className="form__title">{t("page.forgot_password.title")}</p>
                <div className="container">
                    <Formik
                        initialValues={data}
                        validationSchema={forgotPasswordSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                            <form onSubmit={handleSubmit}>
                                <ShareInput
                                    name="username"
                                    style={{ width: "100%" }}
                                    onChange={handleChange}
                                    value={values.username}
                                    onBlur={handleBlur}
                                    label={t("page.forgot_password.username_or_email")}
                                />
                                <ShareButton
                                    className="button_submit"
                                    type="primary"
                                    size="large"
                                    text={t("page.forgot_password.send_email")}
                                    htmlType="submit"
                                    disable={isSubmitting}
                                />
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </StyledForgotPassword>
    );
};
