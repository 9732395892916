import { StyledSetting, ComponentSettingOption } from "@components";
import { ISettingOption } from "@interfaces";

interface IProps {
    settingOptions: Array<ISettingOption>;
    onHandle: (type: string | undefined) => void;
}

export const ComponentSetting = (props: IProps) => {
    //Props
    const { settingOptions, onHandle } = props;

    return (
        <StyledSetting>
            {settingOptions.map((option, index: number) => (
                <ComponentSettingOption key={index} settingOption={option} onHandle={onHandle} />
            ))}
        </StyledSetting>
    );
};
