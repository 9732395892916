import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";

import { EditProfileComponent } from "src/Components/EditProfile";
import { authApi } from "@api";
import { PATH_DETAIL_USER } from "@configs";
import { ComponentTopMenu, IconArrowRight } from "@components";
import { openToast, selectInfoUser, setInfo, setLoading } from "@redux";

export const EditProfile = () => {
    const [imagesDefault, setImgaesDefault] = useState<{ id: number; url: string }[]>([]);
    const [listAvatar, setListAvatar] = useState<any>([]);
    const [idDelete, setIdDelete] = useState<Array<number>>([]);
    const userInfo = useSelector(selectInfoUser);
    const [initialValueForm, setInitialValueForm] = useState<any>({});
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        setImgaesDefault(userInfo?.avatars || []);
    }, [userInfo]);

    useEffect(() => {
        setInitialValueForm({
            location:
                userInfo?.prefecture === -1 || userInfo?.prefecture === 0
                    ? null
                    : userInfo?.prefecture,
            name: userInfo?.username || "",
            profile: userInfo?.profile_text || "",
            age: userInfo?.age === -1 || userInfo?.age === 0 ? null : userInfo?.age,
            height: userInfo?.height === -1 || userInfo?.height === 0 ? null : userInfo?.height,
            appearance:
                userInfo?.appearance === -1 || userInfo?.appearance === 0
                    ? null
                    : userInfo?.appearance,
            mariage: userInfo?.mariage === -1 || userInfo?.mariage === 0 ? null : userInfo?.mariage,
            blood:
                userInfo?.bloodgroup === -1 || userInfo?.bloodgroup === 0
                    ? null
                    : userInfo?.bloodgroup,
            language:
                userInfo?.language === -1 || userInfo?.language === 0 ? null : userInfo?.language,
            job: userInfo?.job === -1 || userInfo?.job === 0 ? null : userInfo?.job,
            salary: userInfo?.salary === -1 || userInfo?.salary === 0 ? null : userInfo?.salary,
            lookingFor:
                userInfo?.looking_for === -1 || userInfo?.looking_for === 0
                    ? null
                    : userInfo?.looking_for,
            hobby: userInfo?.hobby || "",
            willSee:
                userInfo?.hope_meet === -1 || userInfo?.hope_meet === 0
                    ? null
                    : userInfo?.hope_meet,
            firstFee:
                userInfo?.date_cost === -1 || userInfo?.date_cost === 0
                    ? null
                    : userInfo?.date_cost,
            hasChildren:
                userInfo?.with_child === -1 || userInfo?.with_child === 0
                    ? null
                    : userInfo?.with_child,
            readyMarried:
                userInfo?.willing_marriage === -1 || userInfo?.willing_marriage === 0
                    ? null
                    : userInfo?.willing_marriage,
            drink: userInfo?.drinker,
            smoke: userInfo?.smoker,
        });
    }, [userInfo]);

    const profileSchema = Yup.object().shape({
        blood: Yup.string().nullable().required(t("validation.field_required")),
        age: Yup.string().nullable().required(t("validation.field_required")),
        location: Yup.string().nullable().required(t("validation.field_required")),
        profile: Yup.string().nullable().required(t("validation.field_required")),
        height: Yup.string().nullable().required(t("validation.field_required")),
        mariage: Yup.string().nullable().required(t("validation.field_required")),
        appearance: Yup.string().nullable().required(t("validation.field_required")),
        name: Yup.string().nullable().required(t("validation.field_required")),
    });

    const handleImageChange = (event: any) => {
        if (event.target.files) {
            const files = event.target.files;
            setListAvatar([...listAvatar, ...files]);
        }
        event.target.value = [];
    };

    const handleDeleteAvt = (item: any) => {
        const _listAvtUrl = _.filter(listAvatar, (element) => element !== item);
        setListAvatar(_listAvtUrl);
    };

    const handleDeleteImageDefault = (id: number) => {
        setIdDelete([...idDelete, id]);
        const _listAvtUrl = _.filter(imagesDefault, (element) => element.id !== id);
        setImgaesDefault(_listAvtUrl);
    };

    const handleSubmit = async (values: any) => {
        //
        try {
            dispatch(setLoading(true));
            const form = new FormData();

            !!values.age && form.append("age", values?.age);
            !!values.location && form.append("prefecture_id", values.location);
            form.append("sexuality", userInfo?.sexuality);
            !!values.appearance && form.append("appearance", values.appearance);
            form.append("profile_text", values.profile);
            !!values.height && form.append("height", values.height);
            !!values.mariage && form.append("mariage", values.mariage);
            !!values.blood && form.append("bloodgroup", values.blood);
            !!values.language && form.append("language", values.language);
            !!values.job && form.append("job", values.job);
            !!values.salary && form.append("salary", values.salary);
            !!values.lookingFor && form.append("looking_for", values.lookingFor);
            form.append("hobby", values.hobby);
            !!values.drink && form.append("drinker", values.drink);
            !!values.smoke && form.append("smoker", values.smoke);

            form.append("username", values.name);
            !!values.willSee && form.append("hope_meet", values.willSee);
            !!values.firstFee && form.append("date_cost", values.firstFee);
            !!values.hasChildren && form.append("with_child", values.hasChildren);
            !!values.readyMarried && form.append("willing_marriage", values.readyMarried);
            listAvatar.length > 0 &&
                listAvatar.forEach((a: any) => {
                    form.append("avatar[]", a);
                });
            idDelete.length > 0 &&
                idDelete.forEach((a: any) => {
                    form.append("delete_avatar[]", a);
                });

            const res = await authApi.updateProfile(form);
            const { success, data } = res?.data;

            if (success) {
                dispatch(setInfo(data?.profile));
                dispatch(
                    openToast({
                        //@ts-ignore
                        message: data.message,
                        type: "success",
                        autoHideDuration: 1000,
                    })
                );
                setIdDelete([]);
                setListAvatar([]);
            } else {
                dispatch(
                    openToast({
                        message: data.message,
                        type: "error",
                        autoHideDuration: 1000,
                    })
                );
            }
            const res2 = await authApi.updateProfileS2(form);

            if (res2?.data?.success) {
                dispatch(setInfo(res2?.data?.data?.profile));
                dispatch(
                    openToast({
                        //@ts-ignore
                        message: res2?.data?.data?.message,
                        type: "success",
                        autoHideDuration: 1000,
                    })
                );
                setIdDelete([]);
                setListAvatar([]);
            } else {
                dispatch(
                    openToast({
                        message: res2?.data?.data?.message,
                        type: "error",
                        autoHideDuration: 1000,
                    })
                );
            }
        } catch (error: any) {
            dispatch(
                openToast({
                    message: error.message,
                    type: "error",
                    autoHideDuration: 1000,
                })
            );
        } finally {
            dispatch(setLoading(false));
            history.replace(`${PATH_DETAIL_USER}/${userInfo?.id}`);
        }
    };

    const handleGoBack = () => {
        history.goBack();
    };

    return (
        <div
            style={{
                background: "linear-gradient(360deg, #3bb3c1 66.07%, #fac9e1 110.71%)",
                height: "100%",
            }}
        >
            <ComponentTopMenu prefixIcon={<IconArrowRight />} onClickPrefixIcon={handleGoBack} />
            <EditProfileComponent
                initialValue={initialValueForm}
                imagesDefault={imagesDefault}
                listAvatar={listAvatar}
                handleImageChange={handleImageChange}
                handleDeleteAvt={handleDeleteAvt}
                handleDeleteImageDefault={handleDeleteImageDefault}
                onSubmit={handleSubmit}
                profileSchema={profileSchema}
            />
        </div>
    );
};
