import styled, { css } from "styled-components";
import Switch from "@mui/material/Switch";
import { styled as styledMui } from "@mui/material/styles";

import { theme } from "@utils";

export const StyledSetting = styled.div`
    font-family: ${theme.family.fontKozuka};
    width: 100%;
    height: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
        display: none;
    }
    @media (min-width: ${theme.breakPoints.breakIpadPro}) {
        max-width: 66.667rem;
    }
`;

interface IStyledSettingOptionProps {
    hasExpand: boolean;
}

export const StyledSettingOption = styled.div<IStyledSettingOptionProps>`
    .option__wrapper {
        width: 100%;
        font-weight: 500;
        font-size: 0.938rem;
        line-height: 1rem;
        color: ${theme.colors.black};
        padding: 1.375rem 1rem;
        border-bottom: 0.3px solid ${theme.colors.text};
        display: flex;
        text-decoration: none;
        ${(props) => {
            if (props.hasExpand)
                return css`
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;
                `;
            return css`
                flex-direction: column;
            `;
        }}
    }
`;

export const StyledNotificationOption = styled.div`
    .sub_option {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1.5rem;
        .option_title {
            font-weight: 700;
            font-size: 0.75rem;
            line-height: 1.125rem;
            color: #7b7b7b;
        }
    }
`;

export const StyledSwitch = styledMui(Switch)(() => ({
    width: "3.75rem",
    height: "1.875rem",
    padding: 0,
    "& .MuiSwitch-switchBase": {
        top: "50%",
        transform: "translateY(-50%) !important",
        padding: "0.25rem",
        "&.Mui-checked": {
            right: 0,
            left: "unset",
            "& + .MuiSwitch-track": {
                backgroundColor: "#B8E5EA",
            },
            "& .MuiSwitch-thumb": {
                backgroundColor: "#3BB3C1",
            },
        },
    },
    "& .MuiSwitch-track": {
        borderRadius: "12.5rem",
        backgroundColor: "#D5D5D5",
        opacity: "1",
    },
    "& .MuiSwitch-thumb": {
        boxShadow: "none",
        width: "1.375rem",
        height: "1.375rem",
        backgroundColor: "#ABAAAB",
        opacity: "1",
    },
}));
