export const IconSend = () => {
    return (
        <svg
            width="1.5rem"
            height="1.5rem"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.6667 4L11.5 13.1667"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.3746 4.24776C21.4697 3.97582 21.4007 3.6734 21.197 3.46967C20.9933 3.26594 20.6908 3.19693 20.4189 3.29211L3.75224 9.12544C3.46199 9.22703 3.26316 9.4955 3.25062 9.80276C3.23809 10.11 3.41439 10.3938 3.6954 10.5187L10.9318 13.7349L14.148 20.9713C14.2729 21.2523 14.5566 21.4286 14.8639 21.416C15.1712 21.4035 15.4396 21.2047 15.5412 20.9144L21.3746 4.24776Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
