export const IconReport = () => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.89583 0.125C11.3542 0.125 13.4514 0.993056 15.1875 2.72917C16.9236 4.46528 17.7917 6.55556 17.7917 9C17.7917 11.4444 16.9236 13.5347 15.1875 15.2708C13.4514 17.0069 11.3542 17.875 8.89583 17.875C6.4375 17.875 4.34028 17.0069 2.60417 15.2708C0.868056 13.5347 0 11.4444 0 9C0 6.55556 0.868056 4.46528 2.60417 2.72917C4.34028 0.993056 6.4375 0.125 8.89583 0.125ZM1.79167 9C1.79167 10.6389 2.29167 12.0972 3.29167 13.375L13.25 3.375C11.9722 2.375 10.5139 1.875 8.875 1.875C6.93056 1.875 5.26389 2.56944 3.875 3.95833C2.48611 5.34722 1.79167 7.02778 1.79167 9ZM8.875 16.125C10.8472 16.125 12.5278 15.4306 13.9167 14.0417C15.3056 12.6528 16 10.9722 16 9C16 7.36111 15.5 5.90278 14.5 4.625L4.54167 14.625C5.81944 15.625 7.26389 16.125 8.875 16.125Z"
                fill="white"
            />
        </svg>
    );
};
