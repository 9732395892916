import styled from "styled-components";

import { theme } from "@utils";

export const StyledPricing = styled.div`
    width: 100vw;
    height: 100%;
    background-image: url("/images/splash/choose_gender_background.svg");
    background-size: contain;

    @media (min-width: ${theme.breakPoints.breakIpadPro}) {
        display: flex;
        justify-content: center;
    }
`;

export const StyledMalePayment = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
    position: relative;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }

    .header {
        width: 100%;
    }
    .body {
        width: 100%;
    }
    .bottom {
        width: 100%;
    }
    .button {
        width: 100%;
        cursor: pointer;
        &:hover {
            opacity: 0.8;
        }
    }

    @media (min-width: ${theme.breakPoints.breakIpadPro}) {
        width: 900px;
    }
`;

export const StyledFemaleView = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
    position: relative;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
    justify-content: center;
    align-content: center;
    background-color: #d8a5f0;
    padding-top: 2rem;
    .female__background {
        width: 100%;
    }
    .image_wrapper {
        position: relative;
        .button {
            color: ${theme.colors.black};
            font-size: 1.5rem;
            position: fixed;
            bottom: 1.563rem;
            right: 1.25rem;
            background-color: #bfe3fb;
            padding: 0.25rem 0.875rem;
            border-radius: 0.25rem;
            margin-bottom: 0;
            font-family: ${theme.family.fontKozuka};
            cursor: pointer;
            &:hover {
                opacity: 0.8;
            }
        }
    }
    @media (min-width: ${theme.breakPoints.breakIpadPro}) {
        width: 900px;
        .image_wrapper {
            .button {
                position: absolute;
            }
        }
    }
`;

export const StyledModalPaymentMethod = styled.div`
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    .backdrop {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.54);
    }
    .modal {
        z-index: 1000;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: ${theme.colors.white};
        width: 100%;
        text-align: center;
        .payment_method__wrapper {
            display: flex;
            padding: 0.625rem;
            margin-bottom: 0.625rem;
            font-size: 0.875rem;
            justify-content: center;
        }
        .confirm_method {
            background-color: ${theme.colors.secondary};
            color: ${theme.colors.white};
            font-size: 0.75rem;
            display: inline-block;
            padding: 0.938rem;
            border-radius: 0.313rem;
            font-weight: bold;
            cursor: pointer;
            &:hover {
                opacity: 0.8;
            }
            margin-bottom: 1.25rem;
        }
    }
`;

export const StyledItemPaymentMethod = styled.div`
    width: 100%;
    height: 100%;
    border: 1px solid #9e9e9e;
    border-radius: 0.313rem;
    padding: 0.625rem 0.25rem;
    margin-left: 0.625rem;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
    &:first-child {
        margin-left: 0;
    }
    .title_method {
        font-size: 0.875rem;
        font-weight: bold;
        font-family: ${theme.family.fontKozuka};
        margin-bottom: 0.25rem;
    }
    .price_method {
        font-size: 0.75rem;
        color: black;
    }
    &.active {
        border-color: ${theme.colors.secondary};
        .title_method {
            color: ${theme.colors.secondary};
        }
    }
`;
