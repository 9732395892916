import styled from "styled-components";

import { theme } from "@utils";

export const StyledForgotPassword = styled.div`
    background: ${theme.colors.primary};
    width: 100vw;
    height: 100%;
    .content__wrapper {
        display: flex;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        margin: auto;
    }
    .go_back__btn {
        position: absolute;
        top: 1.875rem;
        left: 1rem;
        cursor: pointer;
        padding: 0.5rem;
    }
    .container {
        overflow-y: auto;
        max-width: 560px;
        position: relative;
        padding-top: 1.938rem;
        padding-bottom: 1.688rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        background-color: ${theme.colors.white};
        width: 100%;
    }
    .form__title {
        font-weight: 700;
        font-size: 1.75rem;
        line-height: 3.5rem;
        color: ${theme.colors.white};
        text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.15);
        width: 100%;
        text-align: center;
        font-family: ${theme.family.fontKozuka};
        margin-top: 1rem;
    }
    .button_submit {
        border: none;
        background-color: ${theme.colors.primary};
        margin-top: 1rem;
        font-weight: 500;
        font-size: 0.938rem;
        line-height: 1.875rem;
        font-family: ${theme.family.fontKozuka};
    }
    .button_submit:hover {
        color: ${theme.colors.white} !important;
        opacity: 0.8;
        border-radius: 0.25rem;
    }
`;
