import styled from "styled-components";
import { Link } from "react-router-dom";

import { theme } from "@utils";

export const StyledUserPreviewCard = styled(Link)`
    width: 100%;
    overflow: hidden;
    text-decoration: none;
    position: relative;
    height: 0;
    padding-bottom: 100%;
    .user_avatar {
        position: absolute;
        object-fit: cover;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        &:hover {
            transform: scale(1.1);
        }
    }
    .user_info {
        position: absolute;
        bottom: 0.375rem;
        left: 0.313rem;
        display: flex;
        flex-wrap: wrap;
        padding: 0.25rem 0.5rem;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        max-width: calc(100% - 0.626rem);
    }
    .user_name {
        font-weight: 700;
        font-size: 0.688rem;
        line-height: 1.375rem;
        color: ${theme.colors.white};
        margin-bottom: 0;
        font-family: ${theme.family.fontKozuka};
        margin-right: 0.563rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .prefecture {
        font-size: 0.688rem;
        color: ${theme.colors.white};
        line-height: 1.375rem;
        font-family: ${theme.family.fontKozuka};
        margin-bottom: 0;
    }
    .user_age {
        font-size: 0.688rem;
        color: ${theme.colors.white};
        line-height: 1.375rem;
        font-family: ${theme.family.fontKozuka};
        margin-bottom: 0;
        margin-right: 0.563rem;
    }
`;
