import { useTranslation } from "react-i18next";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { ShareButton, ShareInput, StyledForgotPassword } from "@components";
import { ICreateNewPassword } from "@interfaces";
import { authApi } from "@api";
import { openToast, setLoading } from "@redux";
import { PATH_LOGIN } from "@configs";
import React from "react";

export const PageCreateNewPassword = () => {
    //page Hooks
    const { t } = useTranslation();

    //page Hooks
    const location = useLocation();
    const { pathname, search } = location;
    //What
    //Get token from pathname /password/reset/:token
    const token = pathname.split("/").pop();
    //Get email from search params
    const params = new URLSearchParams(search);
    const email = params.get("email") || "";

    //page variable
    const data: ICreateNewPassword = {
        email: email,
        token,
        password: "",
        confirmPassword: "",
    };

    const createPasswordSchema = Yup.object().shape({
        password: Yup.string().required(t("validation.field_password_required")).min(6, t("validation.min_length_password")).max(32, t("validation.max_length_password")),
        confirmPassword: Yup.string()
            .required(t("validation.field_password_required"))
            .oneOf([Yup.ref("password"), null], t("validation.password_must_match")),
    });

    //page Hooks
    const dispatch = useDispatch();
    const history = useHistory();

    const handleCreateNewPassword = async (values: ICreateNewPassword, { setSubmitting }: FormikHelpers<ICreateNewPassword>) => {
        try {
            //What: Call Api reset password
            dispatch(setLoading(true));
            const res = await authApi.createNewPassword(values);
            dispatch(
                openToast({
                    message: res.data.data.message,
                    type: "success",
                    autoHideDuration: 2000,
                })
            );
            history.push(PATH_LOGIN);
        } catch (error: any) {
            dispatch(
                openToast({
                    message: error.response.data.message || error.message,
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
        } finally {
            setSubmitting(false);
            dispatch(setLoading(false));
        }
    };

    return (
        <StyledForgotPassword>
            <div className="content__wrapper">
                <p className="form__title">{t("page.action.reset_password")}</p>
                <div className="container">
                    <Formik initialValues={data} validationSchema={createPasswordSchema} onSubmit={handleCreateNewPassword}>
                        {({
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => {
                            // const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
                            //     event.target.removeAttribute("readonly");
                            // };
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <input autoComplete="false" name="hidden" type="text" style={{ display: "none" }} />
                                    <ShareInput name="email" style={{ width: "100%" }} value={values.email} label={t("page.auth.email")} disable />
                                    <ShareInput
                                        name="password"
                                        type="password"
                                        style={{ width: "100%" }}
                                        onChange={handleChange}
                                        value={values.password}
                                        onBlur={handleBlur}
                                        label={t("page.password.new_password")}
                                        // readOnly
                                        // onFocus={handleFocus}
                                    />
                                    <ShareInput
                                        name="confirmPassword"
                                        style={{ width: "100%" }}
                                        type="password"
                                        onChange={handleChange}
                                        value={values.confirmPassword}
                                        onBlur={handleBlur}
                                        label={t("page.password.confirm_new_password")}
                                        // readOnly
                                        // onFocus={handleFocus}
                                    />
                                    <ShareButton className="button_submit" type="primary" size="large" text={t("page.action.reset_password")} htmlType="submit" disable={isSubmitting} />
                                </form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </StyledForgotPassword>
    );
};
