import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
    ShareButton,
    StyledVerifyPassport,
    ComponentImage,
    IconCircleCheck,
    IconCircleError,
} from "@components";
import { PATH_UPDATE_PROFILE, VerifyPassportEnum } from "@configs";

interface IVerifyPassportComponentProps {
    toggleLoading?: boolean;
    onChangeImage: (event: React.ChangeEvent<HTMLInputElement>) => void;
    screen: string;
    message: string;
    handleGoBack: () => void;
}

export const VerifyPassportComponent = (props: IVerifyPassportComponentProps) => {
    const { screen, message, handleGoBack, ...rest } = props;
    switch (screen) {
        case VerifyPassportEnum.FORM:
            return <UploadPassportComponent {...rest} />;
        case VerifyPassportEnum.SUCCESS:
            return <UploadSuccessComponent message={message} />;
        case VerifyPassportEnum.ERROR:
            return <UploadErrorComponent handleGoBack={handleGoBack} message={message} />;
        default:
            return <UploadPassportComponent {...rest} />;
    }
};

const UploadPassportComponent = (
    props: Pick<IVerifyPassportComponentProps, "toggleLoading" | "onChangeImage">
) => {
    //page hooks
    const { t } = useTranslation();

    const { toggleLoading, onChangeImage } = props;

    const inputImage = useRef<HTMLInputElement>(null);

    const handleClickUpload = () => {
        if (inputImage.current) inputImage.current.click();
    };

    return (
        <StyledVerifyPassport>
            {toggleLoading && <ModalLoading />}
            <div className="form verify_passport">
                <div className="title">{t("page.verify_passport.title")}</div>
                <div className="sub_title">
                    {t("page.verify_passport.sub_title_1")}
                    <br />
                    {t("page.verify_passport.sub_title_2")}
                </div>
                <div className="list_passport_type">
                    <div className="passport_type">
                        <ComponentImage src="/images/auth/verify_passport_1.svg" />
                        {t("page.verify_passport.passport")}
                    </div>
                    <div className="passport_type">
                        <ComponentImage src="/images/auth/verify_passport_2.svg" />
                        {t("page.verify_passport.license_card")}
                    </div>
                    <div className="passport_type">
                        <ComponentImage src="/images/auth/verify_passport_3.svg" />
                        {t("page.verify_passport.health_card")}
                    </div>
                </div>
                <div className="verify_rule">
                    {t("page.verify_passport.verify_rule_title")}
                    <br />
                    {t("page.verify_passport.verify_rule_1")}
                    <br />
                    {t("page.verify_passport.verify_rule_2")}
                    <br />
                    {t("page.verify_passport.verify_rule_3")}
                </div>
                <div className="verify_privacy">
                    {t("page.verify_passport.verify_privacy_1")}
                    <br />
                    {t("page.verify_passport.verify_privacy_2")}
                    <br />
                    {t("page.verify_passport.verify_privacy_3")}
                    <br />
                    {t("page.verify_passport.verify_privacy_4")}
                    <br />
                    {t("page.verify_passport.verify_privacy_5")}
                </div>
            </div>
            <div className="button_wrapper">
                <input type="file" ref={inputImage} hidden onChange={onChangeImage} />
                <ShareButton
                    type="primary"
                    size="large"
                    text={t("page.verify_passport.upload_id")}
                    htmlType="button"
                    onClick={handleClickUpload}
                />
            </div>
        </StyledVerifyPassport>
    );
};

const UploadSuccessComponent = ({ message }: Pick<IVerifyPassportComponentProps, "message">) => {
    //page hooks
    const { t } = useTranslation();

    const history = useHistory();

    return (
        <StyledVerifyPassport>
            <div className="form verify_passport">
                <div className="title">{t("page.verify_passport.title")}</div>
                <div className="message_group">
                    <IconCircleCheck />
                    <div className="message">{message}</div>
                </div>
            </div>
            <div className="button_wrapper">
                <ShareButton
                    type="primary"
                    size="large"
                    text={t("page.verify_passport.done_verify")}
                    htmlType="button"
                    onClick={() => history.push(PATH_UPDATE_PROFILE)}
                />
            </div>
        </StyledVerifyPassport>
    );
};

const UploadErrorComponent = ({
    message,
    handleGoBack,
}: Pick<IVerifyPassportComponentProps, "message" | "handleGoBack">) => {
    //page hooks
    const { t } = useTranslation();

    return (
        <StyledVerifyPassport>
            <div className="form verify_passport">
                <div className="title">{t("page.verify_passport.title")}</div>
                <div className="message_group">
                    <IconCircleError />
                    <div className="message">{message}</div>
                </div>
            </div>
            <div className="button_wrapper">
                <ShareButton
                    type="primary"
                    size="large"
                    text={t("page.verify_passport.go_back")}
                    htmlType="button"
                    onClick={handleGoBack}
                />
            </div>
        </StyledVerifyPassport>
    );
};

const ModalLoading = () => {
    //page hooks
    const { t } = useTranslation();

    return (
        <div className="backdrop_loading">
            <div className="modal_loading">
                <div className="title">{t("page.verify_passport.uploading")}</div>
                {t("page.verify_passport.please_wait")}
            </div>
        </div>
    );
};
