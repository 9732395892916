export const TRANS_JA_VALIDATION = {
    invalid_type: {
        email: "メールの種類ではありません",
    },
    sign_up_min_length_password: "パスワードは最低でも6文字必要です",
    sign_up_field_required: "この項目は必須です！",
    field_required: "フィールドメールが必要です",
    field_required2: "{{name}}必ず指定してください。",
    email_invalid: "メールアドレスは、有効なメールアドレス形式で指定してください。",
    required: "{{name}} が必要です",
    max_length: "{{name}} は 255 文字を超えてはなりません",
    password_must_match: "パスワードと確認パスワードが一致しません。",
    max_length_password: "パスワードフィールドは32文字未満である必要があります",
    min_length_password: "パスワードは6文字以上である必要があります",
    min: "{{name}} は {{number}} 文字以上である必要があります",
    max: "{{name}} フィールドは {{number}} 文字未満である必要があります",
    field_password_required: "フィールドパスワードが必要です",
};
