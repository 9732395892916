export const IconCircleCheck = () => {
    return (
        <svg width="176" height="175" viewBox="0 0 176 175" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="88.0553" cy="88.0445" rx="50.9875" ry="52.9485" fill="white" />
            <path
                d="M87.9045 16.4062C48.7039 16.4062 16.8108 48.2993 16.8108 87.5C16.8108 126.701 48.7039 158.594 87.9045 158.594C127.105 158.594 158.998 126.701 158.998 87.5C158.998 48.2993 127.105 16.4062 87.9045 16.4062ZM124.904 63.6733L78.9666 118.361C78.4627 118.961 77.8356 119.446 77.128 119.783C76.4203 120.119 75.6486 120.3 74.865 120.312H74.7727C74.0062 120.312 73.2483 120.151 72.5482 119.839C71.848 119.527 71.2214 119.071 70.7087 118.501L51.0212 96.626C50.5212 96.0957 50.1323 95.4708 49.8773 94.788C49.6222 94.1052 49.5063 93.3784 49.5362 92.6501C49.566 91.9219 49.7412 91.207 50.0513 90.5474C50.3615 89.8878 50.8003 89.2969 51.3421 88.8093C51.8839 88.3218 52.5176 87.9475 53.2061 87.7083C53.8946 87.4692 54.624 87.3701 55.3513 87.4169C56.0787 87.4637 56.7893 87.6554 57.4415 87.9808C58.0937 88.3061 58.6743 88.7586 59.1492 89.3115L74.6292 106.511L116.53 56.6392C117.47 55.5525 118.8 54.8794 120.232 54.7653C121.664 54.6511 123.084 55.1052 124.184 56.0293C125.284 56.9534 125.976 58.2733 126.111 59.7036C126.246 61.134 125.812 62.56 124.904 63.6733Z"
                fill="#6EC792"
            />
        </svg>
    );
};
