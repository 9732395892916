import styled from "styled-components";
export const StyledAuth = styled.div<{ notImage?: boolean }>`
    position: relative;
    width: 100vw;
    height: 100%;
    background-color: #3bb3c1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    .bg-image__leftBottom {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 419px;
        height: 321px;
        object-fit: cover;
        @media (max-width: ${(p) => p.theme.breakPoints.breakIpadPro}) {
            width: 300px;
            height: 230px;
        }
    }
    .bg-image__rightBottom {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 483px;
        height: 360px;
        object-fit: cover;
        @media (max-width: ${(p) => p.theme.breakPoints.breakIpadPro}) {
            width: 345px;
            height: 268px;
        }
    }

    @media (max-width: ${(p) => p.theme.breakPoints.breakIpadPro}) {
        background-image: initial;
    }
`;

export const StyledContentAuth = styled.div<{ notImage?: boolean }>`
    background-color: #fff;
    position: relative;
    padding: 20px;
    width: 400px;
    .bg-image__logo {
        position: absolute;
        top: 0;
        left: 35px;
        width: 104px;
        height: 104px;
        transform: translateY(-50%);
        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            width: 84px;
            height: 84px;
        }
    }
    @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        padding: 40px 50px;
    }
    @media (max-width: ${(p) => p.theme.breakPoints.breakMobilePhone}) {
        padding: 20px 30px;
    }
`;
