import styled from "styled-components";

export const StyledCard = styled.div`
    padding: 50px;
    border-radius: 4px;
    background-color: white;
    max-width: 707px;
    h3 {
        font-size: 25px;
        color: black;
        font-weight: 600;
        margin-bottom: 22px;
    }
    .label {
    .fit-content{
        width: fit-content;
        &:hover .line{
                visibility: visible;
            }
    }
    .label{
        overflow: hidden;
        overflow-wrap: break-word;
        margin-bottom: 20px;
        p {
            font-weight: 600;
        }
        .title {
        .line {
                height: 1px;
                width: 90%;
                background-color: ${p=>p.theme.colors.fadedText};
                visibility: hidden;
        }
        .title{
            font-size: 14px;
            color: black;
            margin-bottom: 5px;
        }
        .content {
            font-size: 16px;
            color: ${(p) => p.theme.colors.fadedText};
        }
        .input-change-name{
            &:hover .icon-edit-name{
                visibility: visible;
            }
            .icon-edit-name{
                visibility: hidden;
                cursor: pointer;
                padding: 8px;
            }
            width: fit-content;
            input{
                font-weight: 500;
                padding-left: 0;
                border: none;
                &:focus, :active, :focus-visible{
                border: none!important;
                border-width: 0!important;
                }
            }
        }
        
    }
    .button-field {
        display: flex;
    }
    .current-tags {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const StyledCardMarChant = styled.div`
    padding: 40px 50px;
    width: 650px;
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 32px;
    @media (max-width: ${(p) => p.theme.breakPoints.breakMobilePhone}) {
        width: 340px;
    }
    @media (max-width: ${(p) => p.theme.breakPoints.breakMobile}) {
        width: 310px;
    }
    @media (max-width: ${(p) => p.theme.breakPoints.breakSmall}) {
        width: 250px;
    }
    .title {
        font-weight: bold;
        font-size: 25px;
        line-height: 1.5em;
        color: #000000;
        margin-bottom: 32px;
        @media (max-width: ${(p) => p.theme.breakPoints.breakMobile}) {
            font-size: 20px;
        }
    }
    .avatar {
        margin-bottom: 42px;
        position: relative;
    }
    .avatar .box_title {
        margin: 55px 0 20px 0px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5em;
        @media (max-width: ${(p) => p.theme.breakPoints.breakMobile}) {
            font-size: 14px;
        }
    }

    .avatar .box_title span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 1.5em;
        position: relative;
        top: -1px;
        display: inline-block;
        margin-left: 4px;
    }

    .avatar .camera {
        width: 33px;
        height: 33px;
        background-color: #000000;
        border-radius: 50%;
        opacity: 0.29;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 110px;
        bottom: 0;
        transform: translate(-50%, 50%);
        cursor: pointer;
    }
`;
