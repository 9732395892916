import styled from "styled-components";

import { theme } from "@utils";

export const StyledButton = styled.div<{ size: string }>`
    text-align: center;
    .ant-btn {
        width: ${(p) => (p.size === "large" ? "100%" : p.size === "small" ? "60%" : "")};
        color: #fff;
        padding: 0.75rem 0;
        font-size: 1rem;
        height: ${(p) => p.theme.heights.input};
        background-color: #f22f46;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (min-width: ${theme.breakPoints.breakMobilePhone}) and (max-width: ${theme.breakPoints.breakIpadPro}) {
            padding: 1rem 0;
            font-size: 1.2rem;
        }
    }
    .ant-btn-primary {
        border-color: #d9d9d9 !important;
        box-shadow: none !important;
    }
`;

export const StyleButtonSub = styled.button<{
    size: "default" | "small" | "large" | undefined;
    typeBtn: "default" | "sub" | "textMain" | "disable" | undefined;
}>`
    padding: 16px 32px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    margin-top: 8px;
    min-width: 140px;
    ${(p) => {
        if (p.typeBtn === "default") {
            return `
            background-color:${p.theme.colors.subColor};
            color:#ffffff;
            border:none;
            `;
        }
        if (p.typeBtn === "sub") {
            return `
             color: #283441;
             background-color:#fff;
             border: 0.5px solid #707070;
            `;
        }
        if (p.typeBtn === "disable") {
            return `
            color: #646464;
            background: #F7F7F8;
            border: 0.5px solid #A5A5A5;
            `;
        }
        if (p.typeBtn === "textMain") {
            return `
             color: ${p.theme.colors.subColor};
             background-color:#fff;
             border: 0.5px solid ${p.theme.colors.subColor};
            `;
        }
    }}
    ${(p) => {
        if (p.size === "large") {
            return `
            width:100%;
            `;
        }
    }}
`;
